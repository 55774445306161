import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../shared/models/user.model';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {Router} from '@angular/router';
import {TitleService} from '../../../shared/services/common/title.service';
import {FormFlagsComponent} from '../form-flags/form-flags.component';
import {MatDialog} from '@angular/material/dialog';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {HttpFlagService} from '../../../shared/services/http/http-flag.service';
import {HttpUserService} from '../../../shared/services/http/http-user.service';
import {EditUserService} from '../../../shared/services/common/edit-user.service';
import {Notification} from '../../../shared/models/notification.model';
import {MatMenuTrigger} from '@angular/material/menu';
import {TextTransformService} from '../../../shared/helpers/texttransform.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // Active user
  currentUser: User;
  exit = false;
  links: any[];
  title: string;
  url: string;
  unread_nots: number;
  hasNotifications: boolean;
  pollingStarted = false;
  private onDestroy$ = new Subject<boolean>();

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private httpFlagSvc: HttpFlagService,
    private httpUser: HttpUserService,
    private titleSvc: TitleService,
    private textSvc: TextTransformService,
    private editUser: EditUserService,
    private snackSvc: SnackbarService,
    private authSvc: AuthenticationService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        if (this.currentUser && this.currentUser.role >= User.ef) {
          this.startPolling();
        }
        this.links = [
          {label: 'Admin', icon: 'star', url: 'admin', hide: this.currentUser?.role < User.admin},
          {label: 'Profile', icon: 'account_circle', url: 'user/info', hide: this.currentUser?.role < User.ef},
          {label: 'Tours', icon: 'inbox', url: 'tours', hide: this.currentUser?.role < User.ef},
          {label: 'Orders', icon: 'receipt', url: 'orders', hide: this.currentUser?.role < User.ef},
          {label: 'space', hide: this.currentUser?.role < User.ef},
          {label: 'TD Optionals', icon: 'local_activity', url: 'optionals', hide: this.currentUser?.role < User.ef},
          {label: 'City Free Time', icon: 'explore', url: 'freetime', hide: this.currentUser?.role < User.ef},
          {label: 'space', hide: this.currentUser?.role < User.ef},
          {label: 'TD Crosspaths', icon: 'share_location', url: 'crosspaths', hide: this.currentUser?.role < User.ef},
          {label: 'Incidents', icon: 'campaign', url: 'incidents', hide: this.currentUser?.role < User.ef},
          {label: 'space', hide: this.currentUser?.role < User.ef},

          {label: 'Home', icon: 'home', url: 'home', hide: this.currentUser?.role > User.pax_admin},
          {label: 'space', hide: this.currentUser?.role > User.pax_admin},
          {label: 'TD Optionals', icon: 'local_activity', url: 'optional_activity', hide: this.currentUser?.role > User.pax_admin},
          {label: 'TD Appreciation', icon: 'savings', url: 'appreciation', hide: this.currentUser?.role > User.pax_admin},
          {label: 'space', hide: this.currentUser?.role > User.pax_admin},
          {label: 'Upgrade app', icon: 'workspace_premium', url: 'upgrade', hide: this.currentUser?.role > User.pax_admin},
          {label: 'Donate', icon: 'volunteer_activism', url: 'donate', hide: this.currentUser?.role > User.pax_admin},
          {label: 'space', hide: this.currentUser?.role > User.pax_admin},

          {label: 'Support', icon: 'support', url: 'support', hide: this.currentUser?.role < User.ef},
          {label: 'Logout', icon: 'logout', url: 'logout'},
        ];
      });
  }

  ngOnInit() {
    this.unread_nots = 0;
    this.url = window.location.href;
    this.titleSvc.currentTitle
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(title => this.title = title);
  }

  showAll() {
    this.menuTrigger.closeMenu();
  }

  makeDateAgo() {
    this.currentUser.notifications = this.currentUser.notifications.map(notification => {
      return {
        ...notification,
        created_ago: this.textSvc.timeAgo(notification.created_at)
      };
    });
    this.authSvc.updateUser(this.currentUser);
  }

  async startPolling() {
    if (this.pollingStarted) {
      return;
    }
    this.pollingStarted = true;
    if (!this.currentUser.notifications) {
      this.currentUser.notifications = [];
    }
    const fetchNotifications = async () => {
      try {
        const res = await this.httpUser.fetchNotifications(this.currentUser.id);
        // console.log(res);
        if (res.status < 400) {
          this.unread_nots = this.currentUser.notifications.filter(n => !n.is_read && !n.deleting).length;
          // removes notifications from currentUser that are not in res
          this.currentUser.notifications = this.currentUser.notifications.filter(cuNotification =>
            res.results.notifications.some(resNotification => resNotification.id === cuNotification.id)
          );
          res.results.notifications.forEach(resNotification => {
            const existingNotification = this.currentUser.notifications.find(cuNotification => cuNotification.id === resNotification.id);
            if (existingNotification) {
              if (!existingNotification.marked) {
                Object.assign(existingNotification, resNotification);
              }
            } else {
              this.currentUser.notifications.push(resNotification);
            }
          });
          this.currentUser.notifications.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          this.makeDateAgo();
          this.authSvc.updateUser(this.currentUser);
          this.unread_nots = this.currentUser.notifications?.filter(n => !n.is_read && !n.deleting).length;
          this.hasNotifications = this.currentUser.notifications?.filter(n => !n.deleted).length > 0;
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
      setTimeout(fetchNotifications, 10000);
    };
    await fetchNotifications();
  }

  handleLinkClick(url: string): void {
    this.closeMenu();
    if (this.isFullUrl(url)) {
      window.open(url, '_blank');
    } else {
      this.router.navigate([url]);
    }
  }

  isFullUrl(url: string): boolean {
    return /^https?:\/\//i.test(url);
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  async toggleRead(notification: Notification) {
    if (notification.is_read) {
      return;
    }
    notification.is_read = !notification.is_read;
    notification.marked = true;
    this.unread_nots = this.currentUser.notifications?.filter(n => !n.is_read).length;
    const res = await this.httpUser.toggleRead(this.currentUser.id, notification.is_read, notification.id);
    console.log(res);
    if (res.status < 400) {
      // console.log('ok');
    } else {
      // console.log('fail');
    }
  }

  async markAllAsRead() {
    if (this.unread_nots <= 0) {
      return;
    }
    this.currentUser.notifications.map(n => {
      // n.marked = true;
      n.is_read = true;
    });
    this.authSvc.updateUser(this.currentUser);
    this.unread_nots = 0;
    const res = await this.httpUser.markAllAsRead(this.currentUser.id);
    // console.log(res);
    if (res.status < 400) {
      // console.log('ok');
    } else {
      // console.log('fail');
    }
  }

  async deleteNotification(notification: Notification) {
    notification.deleting = true;
    notification.marked = true;
    this.unread_nots = this.currentUser.notifications?.filter(n => !n.is_read && !n.deleting).length;
    await new Promise(resolve => setTimeout(resolve, 150));
    notification.deleted = true;
    this.hasNotifications = this.currentUser.notifications?.filter(n => !n.deleted).length > 0;
    const res = await this.httpUser.deleteNot(this.currentUser.id, notification.id);
    // console.log(res);
    if (res.status < 400) {
      // console.log('ok');
    } else {
      // console.log('fail');
    }
  }

  changeUrl(url) {
    if (url === 'logout') {
      this.url = '/login';
    }
  }

  onGoHome() {
    if (this.currentUser) {
      this.router.navigate(['tours']);
    } else {
      this.router.navigate(['/']);
    }
  }

  setFlag() {
    if (this.currentUser.id) {
      const flag_url = this.router.url;
      const dialogRef = this.dialog.open(FormFlagsComponent, {
        autoFocus: true,
        data: {'user_role': this.currentUser.role, 'no_hide': false, 'url': flag_url.replace(/\//, '')}
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result['reason'] !== 'close') {
          if (result['reason'] === 'hide') {
            this.currentUser.user_settings.flag_icon = false;
            this.editUser.onUserSettsChange('flag_icon');
            return;
          } else {
            const params = {
              'user_id': this.currentUser.id,
              'flag_url': flag_url,
              'problem': result['problem'],
              'solution': result['solution'],
            };
            this.httpFlagSvc.createFlag(params)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                res => {
                  console.log(res);
                  if (res.status < 400) {
                    const flag = res.results.flag;
                    // console.log(flag);
                    this.snackSvc.openSnackBar('Thank you!');
                  } else {
                    this.snackSvc.resultsElse(res);
                  }
                },
                error => {
                  console.log(error);
                  this.snackSvc.openSnackBar('ERROR. Creating flag');
                });
          }
        }
      });
    } else {
      this.snackSvc.openSnackBar('Only members can report');
    }
  }
}
