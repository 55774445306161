import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {takeUntil} from 'rxjs/operators';
import {HttpRoomService} from '../../../../shared/services/http/http-room.service';
import {Subject} from 'rxjs';

export interface DialogDataAddRoomie {
  tour_pax: TourPax[];
  selected: TourPax;
  preferences: any;
  matches: any;
  antimatches: any;
}

@Component({
  selector: 'app-add-roomie',
  templateUrl: './add-roomie.component.html',
  styleUrls: ['./add-roomie.component.scss']
})
export class AddRoomieComponent implements OnInit, OnDestroy {
  loading = false;
  reason: string;
  tour_pax: TourPax[] = [];
  group: TourPax[] = [];
  choice = 1;
  reciprocal = false;

  private onDestroy$ = new Subject<boolean>();
  @Output() updateInfo = new EventEmitter();

  constructor(
    private snackSvc: SnackbarService,
    private httpRoom: HttpRoomService,
    public dialogRef: MatDialogRef<AddRoomieComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddRoomie) {
  }

  ngOnInit(): void {
    this.setValues();
    if (this.data.selected) {
      this.togglePax(this.data.selected);
    } else {
      this.launchUpgPrefs();
    }
  }

  setValues() {
    this.tour_pax = this.data.tour_pax.filter(tp => tp.room !== 'Si');
    this.choice = 1;
    this.reciprocal = false;
    this.tour_pax.map(tpp => tpp.dis = false);
  }

  togglePax(tp) {
    if (!this.group.includes(tp)) {
      this.group.push(tp);
    } else {
      const idx = this.group.findIndex(t => +t.id === +tp.id);
      this.group.splice(idx, 1);
    }
    if (!this.tour_pax.includes(tp)) {
      this.tour_pax.push(tp);
    } else {
      const idx = this.tour_pax.findIndex(t => +t.id === +tp.id);
      this.tour_pax.splice(idx, 1);
    }
    this.tour_pax.sort(function (a, b) {
      return a.user.name.localeCompare(b.user.name);
    });
    this.reciprocal = this.group.length >= 3;
    if (this.group.length === 0) {
      this.tour_pax.map(tpp => {
        tpp.dis = false;
      });
    }
    if (this.group.length === 1) {
      const tp1 = this.group[0];
      this.tour_pax.map(tpp => {
        tpp.dis =
          (tp1.room === 'Si') ||
          ((tpp.room !== tp1.room) ||
            ((tpp.room === tp1.room && tpp.room === 'St' && tpp.user.gender !== tp1.user.gender)));
      });
    }
    if (this.group.length === 2) {
      if (this.group.every(g => g.room === this.group[0].room && (g.room === 'Tw' || g.room === 'Db'))) {
        this.reciprocal = true;
      }
    }
  }

  validateInput() {
    // Check if all same room type
    const cond1 = this.group.every(tp => tp.room === this.group[0].room);
    // Check if all same gender, only if ST
    const cond2 = this.group.every(tp => {
      if (tp.room === 'St' && cond1) {
        return tp.user?.gender === this.group[0].user?.gender;
      } else {
        return true;
      }
    });
    // Check if choice
    const cond3 = this.choice === 1 || this.choice === 0;

    if (!cond1) {
      return 'Not same room types';
    } else if (!cond2) {
      return 'Not same gender';
    } else if (!cond3) {
      return 'Select like/dislike';
    }
    return null;
  }

  submit(): void {
    if (this.group.length < 2) {
      return;
    }
    const wrong = this.validateInput();
    if (wrong) {
      this.snackSvc.openSnackBar(wrong);
      return;
    }
    this.createPref();
  }

  createPref() {
    this.loading = true;
    const data = {
      'tp_ids': this.group.map(({id}) => id).join(','),
      'choice': this.choice ? 'Y' : 'N',
      'origin': 'TD'
    };
    if (this.reciprocal) {
      data['reciprocal'] = this.reciprocal;
    }
    // console.log(data);
    this.httpRoom.createRooming(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.matches = res.results.matches;
            this.data.antimatches = res.results.antimatches;
            this.data.preferences = res.results.preferences;
            this.setValues();
            this.group = [];
            this.updateInfo.emit(this.data);
            this.launchUpgPrefs();
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar('Not valid: ' + res.message);
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error creating preference');
        });
  }

  launchUpgPrefs() {
    const more_prefs = this.tour_pax.filter(tp => {
      if ((tp.room !== 'Si' && tp.room !== 'St') &&
        this.data.matches.findIndex(m => m.includes(tp.user.name)) < 0) {
        return true;
      }
    });
    if (more_prefs.length > 0) {
      const filt = more_prefs.filter(tp => tp.room === more_prefs[0].room);
      if (filt.length > 1) {
        this.togglePax(filt[0]);
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  close(reason) {
    this.reason = reason;
    this.dialogRef.close(reason);
  }
}
