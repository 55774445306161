import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../shared/services/session/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {OutlookService} from '../../../shared/services/session/outlook.service';
import {SnackbarService} from '../../../shared/services/common/snackbar.service';
import {TitleService} from '../../../shared/services/common/title.service';
import {GoogleService} from '../../../shared/services/session/google.service';
import {AppleService} from '../../../shared/services/session/apple.service';
import {HttpSessionService} from '../../../shared/services/http/http-session.service';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  email: string;
  password: string;
  errorMessage: string;

  returnUrl: string;
  ios: boolean;
  loading: boolean;
  loadingApple: boolean;
  loadingGoogle: boolean;
  development: boolean;
  reset: boolean;
  showEmail = false;
  forgotPwd = false;
  showQuick = true;

  sessionId: string;
  refName: string;
  code: string;
  state: string;
  url: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private snackSvc: SnackbarService,
    public platform: Platform,
    private router: Router,
    private titleSvc: TitleService,
    private httpSession: HttpSessionService,
    private authSvc: AuthenticationService,
    private outlookSvc: OutlookService,
    private appleService: AppleService,
    private googleService: GoogleService,
  ) {
    if (this.route.snapshot.routeConfig.path === 'logout') {
      this.logout();
    }
    const exit = this.route.snapshot.queryParams['e'] || null;
    if (exit) {
      this.logout(true);
    }
    // redirect to main if already logged in
    if (this.authSvc.currentUserValue) {
      this.router.navigate(['tours']);
    }
    this.titleSvc.setTitle('Planafy');
  }

  ngOnInit() {
    this.ios = this.authSvc.checkOS(navigator);
    this.loading = false;
    this.loadingGoogle = false;
    this.loadingApple = false;
    this.development = this.route.snapshot.routeConfig.path === 'login/pax';
    this.reset = this.route.snapshot.routeConfig.path === 'login/reset';
    this.url = window.location.href.split('password')[0];
    if (this.reset) {
      localStorage.removeItem('toursUser');
      localStorage.removeItem('gssTypes');
      localStorage.removeItem('roomPreferences');
      localStorage.removeItem('showGenderInfo');
      localStorage.removeItem('day_view');
      localStorage.removeItem('dayview_role');
      localStorage.removeItem('cities');
      localStorage.removeItem('returnUrl');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('companies');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('compTypes');
      localStorage.removeItem('questTypes');
      localStorage.removeItem('session_id');
      sessionStorage.removeItem('activeTour');
      localStorage.removeItem('hiddenIncidents');
      localStorage.removeItem('toursPax');
      localStorage.removeItem('TDIcomps');
      localStorage.removeItem('referrer_id');
      localStorage.removeItem('folder_id');
      localStorage.removeItem('sunTimes');
      this.logout();
      return;
    }
    // get return url from route parameters
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    localStorage.setItem('returnUrl', JSON.stringify(this.returnUrl));
    const invite = atob(this.route.snapshot.queryParams['invite'] || null);
    this.refName = invite ? invite.split('&')[0] : null;
    const refId = invite ? invite.split('&')[1] : null;
    if (!refId) {
      this.refName = null;
    } else {
      localStorage.setItem('referrer_id', refId);
    }
  }

  submitLogin() {
    if (this.loadingApple || this.loadingGoogle || this.loading) {
      return;
    }
    this.loading = true;
    const data = {email: this.email, password: this.password};
    // console.log(data);
    this.authSvc.login(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status === 200) {
            // If TD (beta@pln)
            if (res.results.role >= 4) {
              if (this.code && this.state && this.sessionId) {
                const params = {token: this.returnUrl.split('=')[1]};
                this.router.navigate([this.returnUrl.split('?')[0], params]);
              } else {
                this.router.navigate(['tours']);
              }
            } else { // IF PAX
              if (this.returnUrl) {
                const queryString = this.returnUrl.split('?')[1];
                const params = new URLSearchParams(queryString);
                const queryParams = {
                  a: params.get('a'),
                  c: params.get('c')
                };
                this.router.navigate([this.returnUrl.split('?')[0]], {queryParams});
              } else {
                this.router.navigate(['home']);
              }
            }
          } else {
            if (res.results) {
              const err = res.results[Object.keys(res.results)[0]].toString();
              this.snackSvc.openSnackBar(err);
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.errorMessage = error;
        });
  }

  getOutlookURL() {
    if (this.loadingApple || this.loadingGoogle || this.loading) {
      console.log('once');
      return;
    }
    this.loading = true;
    this.outlookSvc.outlookGetUrl(window.location.origin)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.sessionId = res.results.session_id;
            localStorage.setItem('session_id', this.sessionId);
            window.location.href = res.results.url;
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          } else {
            console.log(res.message);
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error. Contact support');
        });
  }

  getGoogleURL() {
    if (this.loadingApple || this.loadingGoogle || this.loading) {
      console.log('once');
      return;
    }
    this.loadingGoogle = true;
    this.googleService.googleGetUrl(window.location.origin)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.sessionId = res.results.session_id;
            localStorage.setItem('session_id', this.sessionId);
            window.location.href = res.results.url;
            setTimeout(() => {
              this.loadingGoogle = false;
            }, 2000);
          } else {
            console.log(res.message);
          }
        },
        error => {
          this.loadingGoogle = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
        });
  }

  getAppleURL() {
    if (this.loadingApple || this.loadingGoogle || this.loading) {
      console.log('once');
      return;
    }
    this.loadingApple = true;
    this.appleService.appleGetUrl(window.location.origin)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.sessionId = res.results.session_id;
            localStorage.setItem('session_id', this.sessionId);
            window.location.href = res.results.url;
            setTimeout(() => {
              this.loadingApple = false;
            }, 2000);
          } else {
            console.log(res.message);
          }
        },
        error => {
          this.loadingApple = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error. Contact support');
        });
  }

  logout(pax?) {
    this.titleSvc.setTitle('Planafy');
    this.authSvc.logout();
    let path = ['login'];
    if (pax) {
      path = ['login', 'pax'];
    }
    this.router.navigate(path);
  }

  onGoToPrivacy() {
    this.router.navigate(['legal', 'privacy-policy'], {queryParams: {returnUrl: this.router.url}});
  }

  onGoToLogin(mode?) {
    this.loading = false;
    this.loadingGoogle = false;
    this.loadingApple = false;
    if (mode === 'pax') {
      this.router.navigate(['login', 'pax']);
    } else {
      this.router.navigate(['login']);
    }
  }

  onSubmit() {
    this.errorMessage = null;
    if (this.development && !this.email) {
      this.errorMessage = 'Please fill valid email';
      this.snackSvc.openSnackBar(this.errorMessage);
      return;
    }
    if (this.development && !this.password) {
      this.errorMessage = 'Please fill password';
      this.snackSvc.openSnackBar(this.errorMessage);
      return;
    }
    if (this.development && this.email === 'bot@planafy.com') {
      this.errorMessage = 'Bots not allowed here';
      this.snackSvc.openSnackBar(this.errorMessage);
      return;
    }
    if (this.forgotPwd) {
      return;
    }
    if (this.development) {
      this.submitLogin();
    } else {
      this.getOutlookURL();
    }
  }

  onShowEmail() {
    if (this.loading || this.loadingGoogle || this.loadingApple) {
      return;
    }
    this.showEmail = true;
    this.showQuick = false;
    this.loading = false;
    this.loadingGoogle = false;
    this.loadingApple = false;
  }

  onShowQuick() {
    if (this.loading || this.loadingGoogle || this.loadingApple) {
      return;
    }
    this.showQuick = true;
    this.showEmail = false;
    this.loading = false;
    this.loadingGoogle = false;
    this.loadingApple = false;
    this.forgotPwd = false;
  }

  async sendTokenAsync() {
    this.loading = true;
    const res = await this.httpSession.requestPasswordToken({email: this.email, url: this.url});
    console.log(res);
    if (res.status === 200) {
      this.loading = false;
      this.snackSvc.openSnackBar('Recovery email sent');
    } else {
      if (res.results) {
        this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
      } else {
        this.snackSvc.openSnackBar(res.message.toString());
      }
      this.loading = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
