import {Tour} from './tour.model';
import {Orders} from './orders.model';
import {Day} from './day.model';
import {Event} from './event.model';
import {Components} from './components.model';
import {Itinerary} from './itinerary.model';
import {TourPax} from './tour-pax.model';
import {TourOptional} from './tour-optional.model';
import {TourGroup} from './tour-group.model';

export class Activetour {
  tour: Tour;
  itinerary: Itinerary[];
  components: Components[];
  days: Day[];
  events: Event[];
  orders: Orders[];
  tour_pax: TourPax[];
  optionals: TourOptional[];
  groups: TourGroup[];
  message_list: any;
  flights_fetched?: boolean;

  icons = {
    ico_pax: 'groups',
    ico_setts: 'settings',
    ico_error: 'error',
    ico_hotel: 'hotel',
    ico_guss: 'directions_walk',
    ico_heads: 'headset_mic',
    ico_trfr: 'directions_bus_filled',
    ico_rest: 'restaurant',
    ico_act: 'photo_camera',
    ico_free: 'explore',
    ico_efopt: 'directions_walk',
    ico_tdopt: 'local_activity',
    ico_orien: 'map',
    ico_taxi: 'local_taxi',
    ico_bus: 'directions_bus_filled',
    ico_flight: 'flight',
    ico_boat: 'directions_boat',
    ico_train: 'directions_railway_filled',
    ico_arr: 'directions_bus_filled',
    ico_dep: 'directions_bus_filled',
    ico_gener: 'priority_high',
    ico_priva: 'lock',
    ico_incid: 'campaign',
    ico_other: 'star',
    ico_boaton: 'directions_boat',
    ico_safe: 'check_box',
    ico_bday: 'cake',
    ico_tips: 'savings',
  };

  constructor() {
    this.tour = new Tour();
    this.components = [];
    this.message_list = {};
    this.message_list.n_convos = [];
    this.message_list.n_new_convos = [];
    this.message_list.orphan_convos = [];
    this.events = [];
    this.itinerary = [];
    this.days = [];
    this.orders = [];
    this.optionals = [];
    this.groups = [];
    this.flights_fetched = false;
  }
}
