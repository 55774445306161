import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpComponentService {
  private endpoints: {
    crudComponent: string,
    searchComponent: string,
    allComponents: string,
    allComponentsDel: string,
    componentsTour: string,
    componentsTourDel: string,
    createEFUBComponentsTour: string,
    componentsUser: string,
    componentEvent: string,
    forceDeletedComponentsTour: string,
    restoreDeletedComponentsTour: string,
    generateMail: string,
    uploadComponentImage: string,
    getSimilarComponents: string,
    updateCompEvent: string,
    publishFreeTimes: string,
    publishEverything: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      crudComponent: environment.apiUrl + 'component/',
      searchComponent: environment.apiUrl + 'search_components/',
      allComponents: environment.apiUrl + 'components/',
      allComponentsDel: environment.apiUrl + 'components_deleted/',
      componentsTour: environment.apiUrl + 'components_tour/',
      componentsTourDel: environment.apiUrl + 'components_tour_deleted/',
      createEFUBComponentsTour: environment.apiUrl + 'create_efub_components_tour/',
      componentsUser: environment.apiUrl + 'components_user/',
      componentEvent: environment.apiUrl + 'component_event/',
      forceDeletedComponentsTour: environment.apiUrl + 'force_deleted_component_tour/',
      restoreDeletedComponentsTour: environment.apiUrl + 'restore_deleted_component_tour/',
      generateMail: environment.apiUrl + 'generate_mail/',
      uploadComponentImage: environment.apiUrl + 'upload_component_image/',
      getSimilarComponents: environment.apiUrl + 'get_similar_components/',
      updateCompEvent: environment.apiUrl + 'update_component_event/',
      publishFreeTimes: environment.apiUrl + 'event_publish_freetime/',
      publishEverything: environment.apiUrl + 'event_publish_everything/',
    };
  }

  /* gets a component by it's id */
  getComponent(comp_id: number, req_id: number, deleted?: boolean): Observable<any> {
    let params = new HttpParams()
      .set('component_id', comp_id.toString())
      .set('req_id', req_id.toString());
    if (deleted) {
      params = params.set('deleted', 'true');
    }
    return this.http.get<any>(this.endpoints.crudComponent, {params: params});
  }

  /* searches a component by it's id */
  searchComponent(data): Observable<any> {
    return this.http.post<any>(this.endpoints.searchComponent, data);
  }

  /* Created all components for a tour */
  createEFUBComponentsTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.createEFUBComponentsTour + tour_id);
  }

  /* gets all components */
  getAllComponents(): Observable<any> {
    return this.http.get<any>(this.endpoints.allComponents);
  }

  /* gets all deleted components */
  getAllDeletedComponents() {
    return this.http.get<any>(this.endpoints.allComponentsDel);
  }

  /* Update Component */
  updateComponent(comp_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudComponent + comp_id, paramsToUpdate);
  }

  /* Delete component */
  deleteComponent(comp_id: number, req_id: number, force?: string) {
    let params = new HttpParams()
      .set('component_id', comp_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudComponent, {params: params});
  }

  /* Restore deleted comps from tour */
  restoreDeletedComponents(tour_id: number) {
    return this.http.get<any>(this.endpoints.restoreDeletedComponentsTour + tour_id);
  }

  /* Empty comp trash can from tour */
  forceDeletedComponents(tour_id: number) {
    return this.http.delete<any>(this.endpoints.forceDeletedComponentsTour + tour_id);
  }

  /* Creates a new component  */
  createComponent(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudComponent, data);
  }

  /* Creates a new component with its own event */
  createComponentEvent(data): Observable<any> {
    return this.http.post<any>(this.endpoints.componentEvent, data);
  }

  /* Creates a new component with its own event ASYNC */
  async createComponentEventAsyn(data: any) {
    return await this.http.post<any>(this.endpoints.componentEvent, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* gets all components from a tour */
  getComponentsTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.componentsTour + tour_id);
  }

  /* gets all DELETED components from a tour */
  getComponentsDeletedTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.componentsTourDel + tour_id);
  }

  /* gets all components from a user */
  getComponentsUser(user_id: number) {
    return this.http.get<any>(this.endpoints.componentsUser + user_id);
  }

  /* gets preview text */
  getPreviewComponent(component_id: number, req_id: number) {
    return this.http.get<any>(this.endpoints.generateMail + component_id + '/' + req_id + '/1');
  }

  // Copy from other component to current
  getSimilarComponents(data): Observable<any> {
    return this.http.post<any>(this.endpoints.getSimilarComponents, data);
  }

  // Copy from other component to current
  updateComponentEvent(comp_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.updateCompEvent + comp_id, paramsToUpdate);
  }

  // Send Component Image Logo
  sendComponentImage(data): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadComponentImage, data);
  }

  // publishFreeTimes
  publishFreeTimes(data): Observable<any> {
    return this.http.post<any>(this.endpoints.publishFreeTimes, data);
  }

  // publish everything
  publishEverything(data): Observable<any> {
    return this.http.post<any>(this.endpoints.publishEverything, data);
  }
}
