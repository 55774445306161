import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Flag} from '../../../../shared/models/flag.model';
import {Subject} from 'rxjs';
import {HttpFlagService} from '../../../../shared/services/http/http-flag.service';
import {Router} from '@angular/router';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-adm-flags',
  templateUrl: './adm-flags.component.html',
  styleUrls: ['./adm-flags.component.scss']
})
export class AdmFlagsComponent implements OnInit, OnDestroy {
  currentUser: User;
  flags: Flag[] = [];
  users: User[] = [];
  loading = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpFlagSvc: HttpFlagService,
    private router: Router,
    private httpUser: HttpUserService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
    this.getAllFlags();
  }

  printInfo() {
    console.log(this.flags);
  }

  getAllFlags() {
    this.loading = true;
    this.httpFlagSvc.getAllflags()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            // Insert all cities
            this.flags = res.results.flags;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting flags');
        });
  }

  deleteFlag(flag_id) {
    const delFlag = this.flags.find(item => '' + item.id === '' + flag_id);
    const itemIndex = this.flags.findIndex(item => '' + item.id === '' + flag_id);
    this.flags.splice(itemIndex, 1);
    this.httpFlagSvc.deleteFlag(this.currentUser.id, flag_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // const itemIndex = this.flags.findIndex(item => '' + item.id === '' + flag_id);
            // this.flags.splice(itemIndex, 1);
          } else {
            this.flags.push(delFlag);
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.flags.push(delFlag);
          this.snackSvc.openSnackBar('Error deleting flag');
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
