import {Component, OnDestroy, OnInit} from '@angular/core';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {HttpFlightService} from '../../../../../shared/services/http/http-flight.service';
import {TourFlight, TourPaxFlight} from '../../../../../shared/models/tour-pax-flight.model';
import {FlagService} from '../../../../../shared/services/common/flag.service';
import {MatDialog} from '@angular/material/dialog';
import {AddFlightComponent} from '../../../../components/tour/add-flight/add-flight.component';

@Component({
  selector: 'app-tour-flights',
  templateUrl: './tour-flights.component.html',
  styleUrls: ['./tour-flights.component.scss']
})
export class TourFlightsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  arrivals: TourFlight[] = [];
  departures: TourFlight[] = [];
  private pollingActive = true;

  direction = 'arrivals';
  loadingFlights = false;
  loadingPDF = false;
  returnUrl: string;
  activeTabIndex = 0;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private activeTourSvc: ActivetourService,
    private titleSvc: TitleService,
    private httpFlight: HttpFlightService,
    private flagService: FlagService,
    private snackSvc: SnackbarService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
      });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    const toursUser = this.activeTourSvc.getToursUser();
    const tour_prodid = this.route.snapshot.params['tour-prodid'];
    if (this.activeTour.itinerary.length <= 0) {
      const tour = toursUser.find(x => '' + x.prodid === '' + tour_prodid);
      if (!toursUser || !tour) {
        this.snackSvc.openSnackBar('Error. Invalid tour');
        this.router.navigate(['tours']);
        return;
      }
    }
    if (this.activeTour.flights_fetched) {
      this.startPolling();
    } else {
      this.getTourFlights();
    }
  }

  async startPolling() {
    const fetchFlights = async () => {
      if (!this.pollingActive) {
        return;
      }
      await this.getTourFlights();
      setTimeout(fetchFlights, 10000);
    };
    await fetchFlights();
  }

  async getTourFlights() {
    this.loadingFlights = true;
    this.httpFlight.getFlightsTour(this.activeTour.tour.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.arrivals = res.results.arrivals;
            this.arrivals.forEach(flight => {
              if (flight.tourpax_flights) {
                flight.tourpax_flights.sort((a, b) => a.tp_name.localeCompare(b.tp_name));
              }
              if (flight.flight_track?.arrival?.scheduled) {
                const today = new Date().setHours(0, 0, 0, 0);
                const flightScheduled = new Date(flight.flight_track.arrival.scheduled).setHours(0, 0, 0, 0);
                this.pollingActive = flightScheduled === today;
              }
            });
            this.departures = res.results.departures;
            this.departures.forEach(flight => {
              if (flight.tourpax_flights) {
                flight.tourpax_flights.sort((a, b) => a.tp_name.localeCompare(b.tp_name));
              }
            });
            this.activeTour.flights_fetched = this.arrivals.length > 0 || this.departures.length > 0;
            this.activeTourSvc.setActiveTour(this.activeTour);
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingFlights = false;
        },
        error => {
          console.log(error);
          this.loadingFlights = false;
        });
  }

  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
    if (this.activeTabIndex === 0) {
      this.direction = 'arrivals';
    } else if (this.activeTabIndex === 1) {
      this.direction = 'departures';
    }
  }

  uploadFlightsPDF(file) {
    this.loadingPDF = true;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('tour_id', '' + this.activeTour.tour.id);
    // console.log('formData', formData);
    this.httpFlight.uploadFlights(formData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status < 400) {
          this.getTourFlights();
        } else {
          let error;
          if (res.results) {
            error = res.results[Object.keys(res.results)[0]].toString();
          } else {
            error = res.message.toString();
          }
          this.snackSvc.openSnackBar(error);
        }
        this.loadingPDF = false;
      }, error => {
        this.loadingPDF = false;
        console.log(error);
        this.snackSvc.openSnackBar('Error. Try again');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error uploadFlightsPDF()', JSON.stringify(error));
      });
  }

  onAddFlight() {
    const dialogRef = this.dialog.open(AddFlightComponent, {
      autoFocus: true,
      data: {
        tour_id: this.activeTour.tour.id,
        city_id: this.activeTour.itinerary[0].city_id,
        date: this.activeTour.days[1].date,
        direction: this.direction,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] === 'ok') {
        if (this.direction === 'arrivals') {
          this.arrivals = [result['tour_flight'], ...this.arrivals];
        } else {
          this.departures = [result['tour_flight'], ...this.departures];
        }
      }
    });
  }

  updTF(e) {
    this.onUpdateTPflight(e.tpf.id, e.tf);
  }

  onAddTPflight(flight: TourFlight) {
    const idx = flight.tourpax_flights.findIndex(tpf => !tpf.id);
    if (idx < 0) {
      return;
    }
    const data = {
      'tour_flight_id': flight.tourpax_flights[idx].tour_flight_id,
      'tourpax_id': flight.tourpax_flights[idx].tour_pax_id,
      'tp_name': flight.tourpax_flights[idx].tp_name,
    };
    // console.log(data);
    this.httpFlight.addPaxToFlight(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            flight.tourpax_flights = flight.tourpax_flights.map(tp =>
              tp.tp_name === res.results.tourpax_flight.tp_name && !tp.id
                ? res.results.tourpax_flight
                : tp
            );
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
        });
  }

  async onUpdateTPflight(tpf_id, tf) {
    const data = {
      'tour_flight_id': tf.id,
    };
    const res = await this.httpFlight.updateTourPaxFlight(tpf_id, data);
    console.log(res);
    if (res.status < 400) {
      const tp_f = res.results.tourpax_flight;
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  async onUpdateFlightPoint(flight: TourFlight) {
    let which;
    if (flight.direction === 'arrivals') {
      which = flight.flight_track.arrival;
    } else {
      which = flight.flight_track.departure;
    }
    const {id, ...data} = which;
    // console.log(data);
    const res = await this.httpFlight.updateFlightPoint(which.id, data);
    console.log(res);
    if (res.status < 400) {
      const fp = res.results.flight_point;
      flight.editTime = false;
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  async onUpdateFlightTrack(flight: TourFlight) {
    const data = {
      'flight_status': flight.flight_track.flight_status,
    };
    const res = await this.httpFlight.updateFlightTrack(flight.flight_track_id, data);
    console.log(res);
    if (res.status < 400) {
      const ft = res.results.flight_track;
      flight.editTime = false;
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  async onDeleteTourPaxFlight(tourpax_flight: TourPaxFlight) {
    const res = await this.httpFlight.deleteTourPaxFlight(tourpax_flight.id);
    console.log(res);
    if (res.status < 400) {
      // ok
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  async onDeleteTourFlight(tf: TourFlight) {
    let idx;
    const res = await this.httpFlight.deleteTourFlight(tf.id);
    console.log(res);
    if (res.status < 400) {
      if (tf.direction === 'arrivals') {
        idx = this.arrivals.findIndex(f => f.id === tf.id);
        this.arrivals.splice(idx, 1);
      } else {
        idx = this.departures.findIndex(f => f.id === tf.id);
        this.departures.splice(idx, 1);
      }
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  goBack() {
    if (this.returnUrl && this.returnUrl === window.location.pathname) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'hotels'], {queryParams: {returnUrl: window.location.pathname}});
    } else if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  ngOnDestroy() {
    this.pollingActive = false;
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
