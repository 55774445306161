import {Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {HttpItineraryService} from '../../../../../shared/services/http/http-itinerary.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {HttpComponentService} from '../../../../../shared/services/http/http-component.service';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {User} from '../../../../../shared/models/user.model';
import {DatePipe} from '@angular/common';
import {Observable, Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {environment} from '../../../../../../environments/environment';
import {OutlookService} from '../../../../../shared/services/session/outlook.service';
import {HttpOrdersService} from '../../../../../shared/services/http/http-orders.service';
import {TextTransformService} from '../../../../../shared/helpers/texttransform.service';
import {City} from '../../../../../shared/models/city.model';
import {Comptype} from '../../../../../shared/models/comptype.model';
import {MatDialog} from '@angular/material/dialog';
import {HttpUserService} from '../../../../../shared/services/http/http-user.service';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {TourService} from '../../../../../shared/services/common/tour.service';
import {Tour} from '../../../../../shared/models/tour.model';
import {Event} from '../../../../../shared/models/event.model';

@Component({
  selector: 'app-itinerary-view',
  templateUrl: './itinerary-view.component.html',
  styleUrls: ['./itinerary-view.component.scss']
})
export class ItineraryViewComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  toursUser: Tour[];
  tourEvents: Event[];
  tourEventsFilt: Event[];
  cities: City[] = [];
  compTypes: Comptype[] = [];
  compTypesFilt: Comptype[] = [];

  baseUrlLogo: string;
  returnUrl: string;
  role: string;
  isMobile: boolean;
  beta: boolean;
  progressOn: boolean;
  loaded: boolean;
  day_view: boolean;
  show_ids: boolean;

  searchItems: any[] = [];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('');

  @ViewChildren('day') searchElementDaysRefs: QueryList<ElementRef>;
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private titleSvc: TitleService,
    private versioning: VersioningService,
    private textTransform: TextTransformService,
    private outlookSvc: OutlookService,
    private httpUser: HttpUserService,
    private httpTour: HttpTourService,
    private httpOrder: HttpOrdersService,
    private httpItin: HttpItineraryService,
    private httpComp: HttpComponentService,
    private tourSvc: TourService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
      });
    this.cities = JSON.parse(localStorage.getItem('cities'));
    this.compTypes = JSON.parse(localStorage.getItem('compTypes'));
  }

  ngOnInit() {
    this.isMobile = this.authSvc.isMobileDevice();
    this.loaded = false;
    this.beta = this.currentUser.email === 'beta@planafy.com';
    this.baseUrlLogo = this.activeTour?.tour?.cover?.includes('https')
      ? this.activeTour?.tour?.cover
      : environment.baseUrl + this.activeTour?.tour?.cover;
    const prodid = this.route.snapshot.params['tour-prodid'];
    this.toursUser = this.activeTourSvc.getToursUser();
    // If user has no tours or from tours none is the prodid
    if (!this.toursUser || !this.toursUser.find(obj => '' + obj.prodid === '' + prodid)) {
      this.router.navigate(['tours']);
      this.snackSvc.openSnackBar('Error. Not your tour?');
      return;
    }
    if (!this.activeTour) {
      const tour = this.toursUser.find(obj => '' + obj.prodid === '' + prodid);
      const activeTour = new Activetour();
      activeTour.tour = tour;
      this.activeTourSvc.setActiveTour(activeTour);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    const sessionId = this.route.snapshot.queryParams['session_id'] ? this.route.snapshot.queryParams['session_id'] : null;
    if (sessionId) {
      if (sessionId !== 'error') {
        this.verifySession(sessionId, prodid);
      } else {
        const snackbarRef = this.snackSvc.openSnackBar('Error in payment', 'Orders');
        snackbarRef.afterDismissed()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(reason => {
            if (reason.dismissedByAction) {
              this.router.navigate(['orders']);
            }
          });
        return;
      }
    }
    this.day_view = JSON.parse(localStorage.getItem('day_view'));
    this.show_ids = JSON.parse(localStorage.getItem('show_ids'));
    if (this.day_view === null) {
      this.day_view = true;
      localStorage.setItem('day_view', JSON.stringify(this.day_view));
    }
    this.role = JSON.parse(localStorage.getItem('dayview_role')) || null;
    this.getTourData(this.activeTour.tour.id);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  changeDayView() {
    this.day_view = !this.day_view;
    localStorage.setItem('day_view', JSON.stringify(this.day_view));
  }

  filterBy(role: string, skip?: boolean) {
    // Click on same filter, removes
    if (!skip && role && this.role === role) {
      this.filterBy(null);
      return;
    }
    this.role = role;
    localStorage.setItem('dayview_role', JSON.stringify(this.role));
    if (role === 'state') {
      this.tourEventsFilt = this.tourEvents.filter(ev => !ev.state);
    } else if (role?.includes('type')) {
      this.tourEventsFilt = this.tourEvents.filter(ev => +ev.comp_type_id === +role.replace('type', ''));
    } else if (role?.includes('visib')) {
      const vis = role.replace('visib', '');
      this.tourEventsFilt = this.tourEvents.filter(ev => +ev.visible === +vis && ev.component.comp_type.type !== 23);
    } else if (role?.includes('status')) {
      const status = role.replace('status', '');
      if (status === '0') {
        this.tourEventsFilt = this.tourEvents.filter(ev => !ev.component.confirmed_at && ev.component.state !== 'PEND' && ev.component.state !== 'SCHE');
      } else if (status === '1') {
        this.tourEventsFilt = this.tourEvents.filter(ev => !ev.component.confirmed_at && (ev.component.state === 'PEND' || ev.component.state === 'SCHE'));
      } else if (status === '2') {
        this.tourEventsFilt = this.tourEvents.filter(ev => ev.component.confirmed_at);
      }
    } else if (role?.includes('form')) {
      this.tourEventsFilt = this.tourEvents.filter(ev => ev.component.form);
    } else if (role?.includes('time')) {
      const time = role.replace('time', '');
      if (time === '0') {
        this.tourEventsFilt = this.tourEvents.filter(ev => !ev.start_time);
      } else if (time === '1') {
        this.tourEventsFilt = this.tourEvents.filter(ev => ev.start_time && !ev.show_times);
      } else if (time === '2') {
        this.tourEventsFilt = this.tourEvents.filter(ev => ev.start_time && ev.show_times);
      }
    } else {
      this.tourEventsFilt = this.tourEvents;
    }
  }

  viewTour() {
    console.log(this.activeTour);
    if (this.currentUser.role === User.admin) {
      this.show_ids = !this.show_ids;
      localStorage.setItem('show_ids', JSON.stringify(this.show_ids));
    }
  }

  onClickDay(day) {
    this.router.navigate(['day', day.id], {relativeTo: this.route});
  }

  reorderEvts() {
    setTimeout(() => {
      this.tourEvents.sort(function (a: any, b: any) {
        return +(a.component.start_date > b.component.start_date) || -(a.component.start_date < b.component.start_date);
      });
    }, 10);
  }

  updComp(comp) {
    const idx = this.tourEvents.findIndex(e => +e.component.id === +comp.id);
    if (idx > 0) {
      this.tourEvents[idx].component = comp;
    }
    if (this.role) {
      this.filterBy(this.role, true);
    }
  }

  updEvent(event) {
    const idx = this.tourEvents.findIndex(e => +e.id === +event.id);
    if (idx > 0) {
      this.tourEvents[idx].visible = event.visible;
    }
    if (this.role) {
      this.filterBy(this.role, true);
    }
  }

  addDupeEvent(result) {
    const orig_idx = this.tourEvents.findIndex(ev => +ev.id === result.events[0].id);
    this.tourEvents.splice(orig_idx + 1, 0, result.events[0]);
    this.filterBy(this.role);
  }

  onDelComp(comp_id) {
    this.tourEvents = this.tourEvents.filter(ev => +ev.component_id !== +comp_id);
    this.activeTour.events = this.activeTour.events.filter(ev => +ev.component_id !== +comp_id);
    this.activeTourSvc.setActiveTour(this.activeTour);
    this.filterBy(this.role);
  }

  verifySession(sessionId, prodid) {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': sessionId,
    };
    this.httpOrder.verifyStripeSession(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const verified = res.results.verified;
            this.router.navigate(['tour', prodid]);
            if (verified) {
              this.snackSvc.openSnackBar('Payment successful!');
            } else {
              this.snackSvc.openSnackBar('Payment ok but not verified. Contact support');
            }
          } else {
            this.snackSvc.openSnackBar('ERROR. Verifying payment');
          }
          this.progressOn = false;
        },
        error => {
          this.progressOn = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Verifying payment');
        });
  }

  /*
  /* HTTP REQUESTS
  *******************/
  async getTourData(id) {
    this.progressOn = true;
    const res = await this.httpTour.getTourAllAsync(id, this.currentUser.id);
    console.log(res);
    if (res.status < 400) {
      this.versioning.check(res.results.version);
      this.activeTour.tour = res.results.tour;
      this.baseUrlLogo = this.activeTour?.tour?.cover?.includes('https')
        ? this.activeTour?.tour?.cover
        : environment.baseUrl + this.activeTour?.tour?.cover;
      this.activeTour.days = res.results.days.map((day, index) => ({
        ...day,
        forecast: this.activeTour.days[index]?.forecast || null,
      }));
      this.activeTour.itinerary = res.results.itinerary;
      this.activeTour.components = res.results.components;
      this.activeTour.events = res.results.events;
      this.activeTour.days.map(d => d.forecast = res.results.days.find(day => +day.id === +d.id).forecast);
      this.tourEvents = this.tourEventsFilt = res.results.events.map(event => {
        const component = res.results.components.find(comp => +comp.id === +event.component_id);
        return {
          ...event,
          component: component
        };
      }).sort(function (a, b) {
        return a.component.start_date.localeCompare(b.component.start_date) || a.day.date.localeCompare(b.day.date);
      });
      const ids = this.activeTour ? this.activeTour.components.map(c => c.comp_type_id).filter((value, index, self) => {
        return self.indexOf(value) === index;
      }).sort((a, b) => a > b ? 1 : a === b ? 0 : -1) : [];
      this.compTypesFilt = ids.map(i => this.compTypes.find(ct => +ct.id === +i));
      if (this.role) {
        this.filterBy(this.role);
      }
      this.activeTour.orders = res.results.orders;
      this.activeTour.optionals = res.results.optionals;
      this.activeTour.tour_pax = res.results.tour_pax;
      this.activeTour.days.sort((a, b) => a['date'] > b['date'] ? 1 : a['date'] === b['date'] ? 0 : -1);
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.activeTour.days.map(day => {
        day.cs_pending = this.activeTour.events.filter(ev =>
          ('' + ev.day_id === '' + day.id) && (((ev.sent_at || ev.state === 'PEND') && !ev.confirmed_at))).length;
        day.cs_untouch = this.activeTour.events.filter(ev =>
          '' + ev.day_id === '' + day.id && !ev.sent_at && !ev.confirmed_at && !ev.state).length;
      });
      // User currency
      this.currentUser.user_settings.currency = res.results.currency;
      this.authSvc.updateUser(this.currentUser);
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl], {queryParams: {ref: true}});
        return;
      }
      // Search items
      this.activeTour.components.map(c => {
        c.city = this.cities.find(ci => ci.id === c.city_id);
        c.events = this.activeTour.events.filter(ev => ev.component_id === c.id);
        c.comp_type = this.compTypes.find(ct => ct.id === c.comp_type_id);
      });
      this.searchItems = [...this.activeTour.components, ...res.results.components_deleted, ...this.activeTour.days];
      if (!localStorage.getItem('folder_id')) {
        this.tourSvc.onCreateTourFolder(false);
      }
      this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
      const tour_messages_dates = [];
      if (!environment.production) {
        console.log(this.activeTour.message_list);
      }
      if (this.activeTour.message_list && Array.isArray(this.activeTour.message_list.n_new_convos)) {
        for (const conversationId of this.activeTour.message_list.n_new_convos) {
          const component = this.activeTour.components.find(c => '' + c.conversation_id === '' + conversationId);
          // If subject was changed, new conversationId (not registered in component)
          if (component) {
            const event = this.activeTour.events.filter(e => '' + e.component_id === '' + component.id).reduce(function (prev, curr) {
              return prev.day_id < curr.day_id ? prev : curr;
            });
            const day = this.activeTour.days.find(d => '' + d.id === '' + event.day_id);
            tour_messages_dates.push({'conv': conversationId, 'day_id': day.id});
          } else {
            const orphan = this.activeTour.message_list?.orphan_convos.find(it => '' + it.convo === '' + conversationId);
            if (orphan) {
              tour_messages_dates.push({'conv': orphan.convo, 'day_id': orphan.day_id});
            }
          }
        }
      }
      this.activeTour.days.map(day => {
        const comps = tour_messages_dates.filter(tmd => {
          return '' + day.id === '' + tmd.day_id;
        });
        day.new_messages = comps.length;
      });
      this.activeTourSvc.setActiveTour(this.activeTour);
    } else {
      this.snackSvc.resultsElse(res);
      this.router.navigate(['tours']);
    }
    this.loaded = true;
    this.progressOn = false;
  }

  goTo(txt) {
    this.router.navigate([txt], {relativeTo: this.route});
  }

  findEventsBy(id) {
    return this.activeTour.events.filter(function (event) {
      if ('' + event.day_id === '' + id) {
        return event;
      }
    });
  }

  isToday(date) {
    const day = new Date(date + 'T11:59:59.000Z').toDateString();
    const today = new Date().toDateString();
    return day === today;
  }

  todayInTour() {
    const tour = this.activeTour.tour;
    // const start_day = new Date(tour.tour_starts + 'T11:59:59.000Z').toDateString();
    const start_day = this.textTransform.addDays(new Date(tour.tour_starts), 0);
    const end_day = this.textTransform.addDays(new Date(tour.tour_starts), tour.ndays - 1);
    // const end_day = new Date(date + 'T11:59:59.000Z').toDateString();
    // const today = new Date().toDateString();
    const today = this.textTransform.addDays(new Date(), 0);
    return start_day <= today && end_day >= today;
  }

  gotoComp(item) {
    if (item.deleted_at) {
      this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/settings'], {queryParams: {del: '1'}});
      return;
    }
    if (item.events) {
      this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + item.events[0].day_id + '/component/' + item.id]);
    } else {
      this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + item.id]);
    }
  }

  scrollToToday() {
    const today = new Date().toISOString().substring(0, 10);
    const idx = this.searchElementDaysRefs.toArray().findIndex(it => '' + it.nativeElement.id === '' + today);
    if (idx >= 0) {
      // setTimeout(() => {
      this.searchElementDaysRefs.toArray()[idx].nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      // }, 1);
    }
  }

  // SEARCH BARS
  private _filter(value: any): string[] {
    let filterValue;
    if (typeof value === 'object' && !Array.isArray(value)) {
      filterValue = value?.name?.toLowerCase();
    } else {
      filterValue = value?.toLowerCase();
    }
    return this.searchItems.filter(option =>
      option.name?.toLowerCase().includes(filterValue) ||
      option.supplier?.toLowerCase().includes(filterValue) ||
      option.confirm_notes?.toLowerCase().includes(filterValue) ||
      option.events?.some(ev => ev.name?.toLowerCase().includes(filterValue)) ||
      option.events?.some(ev => ev.public_notes?.toLowerCase().includes(filterValue))
    );
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
