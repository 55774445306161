import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpRoomService} from '../../../../shared/services/http/http-room.service';
import {Subject} from 'rxjs';

export interface DialogDataAddUpg {
  tour_pax: TourPax[];
  cia_id: number;
}

@Component({
  selector: 'app-add-upg-room',
  templateUrl: './add-upg-room.component.html',
  styleUrls: ['./add-upg-room.component.scss']
})
export class AddUpgRoomComponent implements OnInit, OnDestroy {
  loading = false;
  et = false;
  reason: string;
  type: string;
  group: TourPax[] = [];

  @Output() updateGroup = new EventEmitter();
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private snackSvc: SnackbarService,
    private httpRoom: HttpRoomService,
    public dialogRef: MatDialogRef<AddUpgRoomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddUpg) {
    this.et = this.data.cia_id === 3;
  }

  ngOnInit(): void {
    this.data.tour_pax = this.data.tour_pax.filter(tp => tp.room === 'St');
  }

  togglePax(tp) {
    if (!this.group.includes(tp)) {
      this.group.push(tp);
    } else {
      const idx = this.group.findIndex(t => +t.id === +tp.id);
      this.group.splice(idx, 1);
    }
    switch (this.group.length) {
      case 1:
        this.type = 'Si';
        break;
      case 2:
        this.type = 'Tw';
        break;
      case 3:
        this.type = 'Tr';
        break;
      case 4:
        this.type = 'Qd';
        break;
      case 5:
        this.type = 'Fv';
        break;
      case 6:
        this.type = 'Sx';
        break;
      case 7:
        this.type = 'Sv';
        break;
      case 8:
        this.type = 'Ei';
        break;
      case 9:
        this.type = 'Ni';
        break;
      case 10:
        this.type = 'Te';
        break;
      default:
        this.type = null;
        break;
    }
  }

  toggleDbTw() {
    if (this.group.length !== 2) {
      return;
    }
    this.type = this.type === 'Db' ? 'Tw' : 'Db';
  }

  submit(): void {
    if (!this.et && this.group.length > 2) {
      this.snackSvc.openSnackBar('Tw/Db size limit');
      return;
    } else if (this.group.length > 4) {
      this.snackSvc.openSnackBar('Quad size limit');
      return;
    }
    this.roomTogether();
  }

  roomTogether() {
    this.loading = true;
    const data = {
      'tp_ids': this.group.map(({id}) => id).join(','),
      'type': this.type,
      'origin': 'TD'
    };
    // console.log(data);
    this.httpRoom.roomTogether(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.group.map(g => {
              const idx = this.data.tour_pax.findIndex(tp => +tp.id === +g.id);
              if (idx >= 0) {
                this.data.tour_pax[idx].room = this.type;
              }
            });
            this.data.tour_pax = this.data.tour_pax.filter(tp => tp.room === 'St');
            this.type = null;
            this.group = [];
            this.updateGroup.emit();
          } else {
            let err;
            if (res.results) {
              err = res.results[Object.keys(res.results)[0]].toString();
              this.snackSvc.openSnackBar(err);
            } else {
              err = res.message;
              console.log(err);
            }
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error grouping pax');
        });
  }

  close(reason) {
    this.reason = reason;
    this.dialogRef.close(reason);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
