import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Template} from '../models/template.model';

@Injectable({
  providedIn: 'root'
})
export class TextTransformService {

  constructor(
    private datePipe: DatePipe,
  ) {
  }

  static modifyTemplates(comp: any, tour: any, user: any, temp: Template): Template {
    const updatedTemplate = {...temp};
    Object.keys(updatedTemplate).forEach((key) => {
      if (typeof updatedTemplate[key] === 'string') {
        if (key !== 'subject' || (key === 'subject' && !comp.requests.includes('{sub}'))) {
          updatedTemplate[key] = updatedTemplate[key]
            .replace(/{{user}}/g, user.name)
            .replace(/{{phone}}/g, user.phone)
            .replace(/{{company}}/g, tour.company.name)
            .replace(/{{branch}}/g, tour.company.full_name)
            .replace(/{{prodid}}/g, comp.itinerary.tour.prodid)
            .replace(/{{code}}/g, comp.itinerary.tour.code)
            .replace(/{{date}}/g, new Date(comp.start_date).toLocaleDateString('en-GB', {day: '2-digit', month: 'short', year: '2-digit'}))
            .replace(/{{city}}/g, comp.city.name)
            .replace(/{{component}}/g, comp.name)
            .replace(/{{br}}/g, '<br>');
        }
      }
    });
    return updatedTemplate;
  }

  static replaceBRs2NL(template: Template): Template {
    const re = /{{br}}/gm;
    template.greeting = template.greeting?.replace(re, '\n');
    template.intro = template.intro?.replace(re, '\n');
    template.hotel_room = template.hotel_room?.replace(re, '\n');
    template.hotel_bfast = template.hotel_bfast?.replace(re, '\n');
    template.hotel_tdnight = template.hotel_tdnight?.replace(re, '\n');
    template.hotel_driver = template.hotel_driver?.replace(re, '\n');
    template.rest = (template.rest) ? template.rest?.replace(re, '\n') : null;
    template.guss = (template.guss) ? template.guss?.replace(re, '\n') : null;
    template.vox = (template.vox) ? template.vox?.replace(re, '\n') : null;
    template.trsfrs = (template.trsfrs) ? template.trsfrs?.replace(re, '\n') : null;
    template.driver_info = (template.driver_info) ? template.driver_info?.replace(re, '\n') : null;
    template.lated = template.lated?.replace(re, '\n');
    template.caxed = template.caxed?.replace(re, '\n');
    template.farewell = template.farewell?.replace(re, '\n');
    template.signature = template.signature?.replace(re, '\n');
    template.subject = template.subject?.replace(re, '\n');
    return template;
  }

  static replaceNLs2BR(template: Template): Template {
    const re = /\n/g;
    template.greeting = template.greeting.replace(re, '{{br}}');
    template.intro = template.intro.replace(re, '{{br}}');
    template.hotel_room = template.hotel_room.replace(re, '{{br}}');
    template.hotel_bfast = template.hotel_bfast.replace(re, '{{br}}');
    template.hotel_driver = template.hotel_driver.replace(re, '{{br}}');
    template.rest = (template.rest) ? template.rest.replace(re, '{{br}}') : null;
    template.guss = (template.guss) ? template.guss.replace(re, '{{br}}') : null;
    template.vox = (template.vox) ? template.vox.replace(re, '{{br}}') : null;
    template.trsfrs = (template.trsfrs) ? template.trsfrs.replace(re, '{{br}}') : null;
    template.driver_info = (template.driver_info) ? template.driver_info.replace(re, '{{br}}') : null;
    template.lated = template.lated.replace(re, '{{br}}');
    template.caxed = template.caxed.replace(re, '{{br}}');
    template.farewell = template.farewell.replace(re, '{{br}}');
    template.signature = template.signature.replace(re, '{{br}}');
    return template;
  }

  titleCase(str) {
    // If airport dont title case
    if (str.search('irport') === -1) {
      return str.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
        return match.toUpperCase();
      });
    } else {
      return str;
    }
  }

  timeAgo(date: string): string {
    const now = new Date();
    const diff = now.getTime() - new Date(date).getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
    if (seconds < 60) {
      return `${seconds} sec${seconds === 1 ? '' : 's'} ago`;
    }
    if (minutes < 60) {
      return `${minutes} min${minutes === 1 ? '' : 's'} ago`;
    }
    if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }
    if (days < 7) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    }
    if (weeks < 4) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    }
    if (months < 12) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    }
    return `${years} year${years === 1 ? '' : 's'} ago`;
  }


  upperFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  urlize(txt) {
    return txt
      .replace(/\s+/g, '-')
      .toLowerCase();
  }

  dehyphenize(txt) {
    return txt
      .toLowerCase()
      .replace(/-/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{4}\-\d{2}\-\d{2}$/.test(dateString)) {
      return false;
    }
    // Parse the date parts to integers
    const parts = dateString.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);
    // Check the ranges of month and year
    if (year < 100 || year > 9000 || month === 0 || month > 12) {
      return false;
    }
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }
    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  dateDiffInDays(a, b) {
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor(Math.abs(utc2 - utc1) / (1000 * 60 * 60 * 24));
  }

  betDays(start, end, needle): boolean {
    return new Date(start) <= new Date(needle) && new Date(end) >= new Date(needle);
  }

  betDaysNe(start, end, needle): boolean {
    return new Date(start) <= new Date(needle) && new Date(end) > new Date(needle);
  }

  addDays(date: Date, days: number): string {
    date.setDate(date.getDate() + days);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
}
