import {TourPax} from './tour-pax.model';
import {Tour} from './tour.model';

export class Airport {
  id: number;
  name: string;
  city_id: number;
  timezone: string;
  iata: string;
  icao: string;
}

export class Airline {
  id: number;
  name: string;
  iata: string;
  icao: string;
}

export class FlightNumber {
  id: number;
  number: string;
  iata: string;
  icao: string;
  airline_id: number;
  airline?: Airline;
}

export class FlightInfo {
  id: number;
  flight: string;
  flight_date: string;
  flight_number_id: number;
  flight_track_id: number;
  flight_number?: FlightNumber;
  flight_track?: FlightTrack;
}

export class FlightTrack {
  id: number;
  flight_status: string;
  can_refresh: boolean;
  arrival_id: number;
  departure_id: number;
  flight_live_id: number;
  arrival?: FlightPoint;
  departure?: FlightPoint;
  flight_live?: FlightLive;
}

export class FlightPoint {
  id: number;
  airport_id: number;
  terminal: string;
  gate: string;
  baggage: string;
  delay: number;
  scheduled: string;
  estimated: string;
  actual: string;
  updated: string;
  airport?: Airport;
}

export class FlightLive {
  id: number;
  updated: string;
  updated_at: string;
  latitude: string;
  longitude: string;
  direction: string;
  is_ground: boolean;
}


export class TourFlight {
  id: number;
  tour_id: number;
  flight_track_id: number;
  direction: string;
  actual?: string;
  estimated?: string;
  estimated_short?: string;
  scheduled?: string;
  status_class?: string;
  delay_txt?: string;
  new_tp_name?: string;
  is_delayed?: boolean;
  is_caxed?: boolean;
  has_landed?: boolean;
  is_active?: boolean;
  showUpdatedAt?: boolean;
  highlightUnlock?: boolean;
  locked?: boolean;
  deleting?: boolean;
  editTime?: boolean;
  addTP?: boolean;
  tour?: Tour;
  flight_track?: FlightTrack;
  tourpax_flights?: TourPaxFlight[];
  updated_at: string;
}

export class TourPaxFlight {
  id: number;
  tour_pax_id: number;
  tour_flight_id: number;
  tp_name: string;
  tour_pax?: TourPax;
  tour_flight?: TourFlight;
  updated_at: string;
  canDrag: boolean;
}

export class FlightCall {
  id: number;
  api_name: string;
  month: string;
  year: string;
  count: number;
}
