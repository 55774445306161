import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {TitleService} from '../../../../../../shared/services/common/title.service';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {Subject} from 'rxjs';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {User} from '../../../../../../shared/models/user.model';
import {NewtourService} from '../../services/newtour.service';

@Component({
  selector: 'app-main-new-tour',
  templateUrl: './main-new-tour.component.html',
  styleUrls: ['./main-new-tour.component.scss']
})
export class MainNewTourComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;
  manually: boolean;
  res: any;

  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;
  sa = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private activeTourSvc: ActivetourService,
    private titleSvc: TitleService,
    private authSvc: AuthenticationService,
    private newTourSvc: NewtourService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    // Subscribe to the manually$ observable to get the updated value
    this.newTourSvc.manually$.subscribe((value) => {
      this.manually = value;
    });
    this.titleSvc.setTitle('New tour');
  }

  ngOnInit(): void {
    this.activeTour = new Activetour();
    this.activeTour.tour.company_id = null;
    this.activeTour.tour.company = null;
    this.activeTour.tour.logo = null;
    this.newTourSvc.setManually(false);
    this.activeTourSvc.setActiveTour(this.activeTour);
  }

  updCia() {
    if (this.activeTour?.tour.company_id) {
      this.ub = this.activeTour.tour.company_id === 2;
      this.et = this.activeTour.tour.company_id === 3;
      this.ad = this.activeTour.tour.company_id === 4;
      this.gy = this.activeTour.tour.company_id === 5;
      this.ga = this.activeTour.tour.company_id === 6;
      this.sa = this.activeTour.tour.company_id === 7;
      if (this.ub || this.et || this.ad || this.gy || this.ga || this.sa) {
        this.newTourSvc.setManually(false);
      } else {
        this.newTourSvc.setManually(true);
      }
    } else {
      this.ub = false;
      this.gy = false;
      this.ga = false;
      this.et = false;
      this.ad = false;
      this.sa = false;
      this.newTourSvc.setManually(false);
    }
    this.activeTour.tour.name = null;
    this.activeTour.tour.prodid = null;
    this.activeTour.tour.code = null;
    this.activeTourSvc.setActiveTour(this.activeTour);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
