import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TourFlight, TourPaxFlight} from '../../../../../../shared/models/tour-pax-flight.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {takeUntil} from 'rxjs/operators';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-view-flights',
  templateUrl: './view-flights.component.html',
  styleUrls: ['./view-flights.component.scss']
})
export class ViewFlightsComponent implements OnInit, OnChanges {
  activeTour: Activetour;
  file: any;
  ub = false;
  et = false;
  ad = false;
  gy = false;
  ga = false;
  sa = false;
  flightStatuses = ['scheduled', 'active', 'landed', 'cancelled', 'incident', 'diverted'];

  private onDestroy$ = new Subject<boolean>();

  @Input() public loadingPDF: boolean;
  @Input() public loadingFlights: boolean;
  @Input() public direction: string;
  @Input() public flights: TourFlight[] = [];
  @Output() uploadPDF = new EventEmitter();
  @Output() updateFlightPoint = new EventEmitter();
  @Output() updateFlightTrack = new EventEmitter();
  @Output() addTPflight = new EventEmitter();
  @Output() delTPflight = new EventEmitter();
  @Output() emitDelTFlight = new EventEmitter();
  @Output() emitUpdTFlight = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService
  ) {
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
  }

  ngOnInit(): void {
    this.ub = this.activeTour.tour.company_id === 2;
    this.et = this.activeTour.tour.company_id === 3;
    this.ad = this.activeTour.tour.company_id === 4;
    this.gy = this.activeTour.tour.company_id === 5;
    this.ga = this.activeTour.tour.company_id === 6;
    this.sa = this.activeTour.tour.company_id === 7;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['flights']) {
      this.processFlights();
      this.orderTpax();
    }
  }

  processFlights() {
    this.flights.map(f => {
      f.showUpdatedAt = false;
      f.highlightUnlock = false;
      f.locked = true;
      f.deleting = false;
      f.editTime = false;
      f.addTP = false;
      f.is_caxed = false;
      f.is_delayed = false;
      f.has_landed = false;
      f.is_active = false;
      f.new_tp_name = null;
      f.tourpax_flights.map(tpf => tpf.canDrag = false);
      f.status_class = '';
      if (f.flight_track.flight_status === 'active') {
        f.is_active = true;
        f.status_class = 'st_active';
      }
      if (f.flight_track.flight_status === 'cancelled') {
        f.is_caxed = true;
        f.status_class = 'st_delay';
      }
      if (f.flight_track.flight_status === 'incident' || f.flight_track.flight_status === 'diverted') {
        f.status_class = 'st_incid';
      }
      if (f.direction === 'arrivals') {
        f.estimated = f.flight_track.arrival.estimated;
        f.scheduled = f.flight_track.arrival.scheduled;
        f.updated_at = f.flight_track.arrival.updated;
        if (f.estimated) {
          f.estimated_short = new Date(f.estimated).toTimeString().slice(0, 5);
          if (new Date(f.estimated).getTime() !== new Date(f.scheduled).getTime()) {
            f.is_delayed = true;
            if (f.flight_track.flight_status === 'active') {
              f.has_landed = false;
              f.status_class += ' st_delay';
            }
          }
        }
        if (f.flight_track.flight_status === 'landed') {
          f.has_landed = true;
          f.is_active = false;
          f.status_class = 'st_land';
          if (f.flight_track.arrival.actual) {
            f.actual = f.flight_track.arrival.actual;
          }
        }
        if (f.flight_track.departure.delay > 14) {
          const hours = Math.floor(+f.flight_track.departure.delay / 60);
          const minutes = +f.flight_track.departure.delay % 60;
          f.delay_txt = hours
            ? `Left ${hours} h${hours > 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''} late`
            : `Left ${minutes} min${minutes !== 1 ? 's' : ''} late`;
        } else {
          f.delay_txt = null;
        }
      } else if (f.direction === 'departures') {
        f.estimated = f.flight_track.departure.estimated;
        f.scheduled = f.flight_track.departure.scheduled;
        f.updated_at = f.flight_track.departure.updated;
        if (f.estimated) {
          f.estimated_short = new Date(f.estimated).toTimeString().slice(0, 5);
          if (new Date(f.estimated).getTime() !== new Date(f.scheduled).getTime()) {
            f.is_delayed = true;
            if (f.flight_track.flight_status === 'active') {
              f.has_landed = false;
              f.status_class += ' st_delay';
            }
          }
        }
        if (f.flight_track.flight_status === 'landed') {
          f.has_landed = true;
          f.is_active = false;
          f.status_class = 'st_land';
          if (f.flight_track.departure.actual) {
            f.actual = f.flight_track.departure.actual;
          }
        }
        if (f.flight_track.departure.delay > 14) {
          const hours = Math.floor(+f.flight_track.departure.delay / 60);
          const minutes = +f.flight_track.departure.delay % 60;
          f.delay_txt = hours
            ? `Leaving ${hours} h${hours > 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''} late`
            : `Leaving ${minutes} min${minutes !== 1 ? 's' : ''} late`;
        } else {
          f.delay_txt = null;
        }
      }
      if (f.flight_track.flight_live) {
        f.updated_at = f.flight_track.flight_live.updated;
      }
      f.status_class = f.status_class.trim();
    });
    this.flights.sort((a, b) => {
      const timeA = a.flight_track.arrival.actual || a.flight_track.arrival.estimated;
      const timeB = b.flight_track.arrival.actual || b.flight_track.arrival.estimated;
      const timestampA = new Date(timeA).getTime();
      const timestampB = new Date(timeB).getTime();
      return timestampA - timestampB;
    });
  }

  orderTpax() {
    this.flights.map(f => {
      if (f.tourpax_flights) {
        f.tourpax_flights.sort((a, b) => a.tp_name.localeCompare(b.tp_name));
      }
    });
  }

  onAddTourPax(flight: TourFlight) {
    if (flight.deleting) {
      return;
    }
    flight.addTP = !flight.addTP;
  }

  addTourPax(flight: TourFlight) {
    const tourpax_flight = new TourPaxFlight();
    tourpax_flight.tp_name = flight.new_tp_name;
    tourpax_flight.tour_flight = flight;
    tourpax_flight.tour_flight_id = flight.id;
    flight.locked = true;
    flight.tourpax_flights.map(tpf => tpf.canDrag = false);
    flight.tourpax_flights.push(tourpax_flight);
    this.addTPflight.emit(flight);
    this.orderTpax();
    flight.addTP = false;
    flight.new_tp_name = null;
  }

  delTourPax(flight: TourFlight, tourpax_flight: TourPaxFlight) {
    flight.tourpax_flights = flight.tourpax_flights.filter(tp => tp.id !== tourpax_flight.id);
    this.delTPflight.emit(tourpax_flight);
  }

  // ACTIONS

  toggleLock(flight: TourFlight) {
    if (flight.deleting) {
      return;
    }
    flight.locked = !flight.locked;
    flight.editTime = !flight.locked;
    if (!flight.locked) {
      flight.highlightUnlock = false;
    } else {
      flight.addTP = false;
    }
    this.flights.map(f => {
      // f.editTime = !f.locked;
      f.tourpax_flights.map(tpf => tpf.canDrag = !f.locked);
    });
  }

  onFlightStatusClick(flight: TourFlight) {
    if (flight.showUpdatedAt) {
      return;
    }
    flight.showUpdatedAt = true;
    setTimeout(() => {
      flight.showUpdatedAt = false;
    }, 2000);
  }

  onEditTime(flight: TourFlight) {
    if (flight.highlightUnlock) {
      return;
    }
    if (flight.locked) {
      flight.highlightUnlock = true;
      setTimeout(() => {
        flight.highlightUnlock = false;
      }, 1500);
    } else {
      flight.editTime = true;
    }
  }

  changeTime(event, flight: TourFlight, type) {
    const inputValue = event.target.value;
    flight[type] = inputValue;
    flight.estimated = flight.flight_track.arrival.estimated.split(' ')[0] + ' ' + inputValue + ':00';
    if (flight.direction === 'arrivals') {
      flight.flight_track.arrival.estimated = flight.flight_track.arrival.estimated.split(' ')[0] + ' ' + inputValue + ':00';
    } else {
      flight.flight_track.departure.estimated = flight.flight_track.departure.estimated.split(' ')[0] + ' ' + inputValue + ':00';
    }
    this.onSaveTime(flight);
  }

  // HTTP REQS

  onDeleteTourFlight(flight: TourFlight) {
    if (flight.deleting) {
      return;
    }
    if (flight.tourpax_flights.length > 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Delete flight? Has travelers', 'OK');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            setTimeout(() => {
              flight.deleting = true;
            }, 1);
            this.emitDelTFlight.emit(flight);
          }
        });
    } else {
      setTimeout(() => {
        flight.deleting = true;
      }, 1);
      this.emitDelTFlight.emit(flight);
    }
  }

  onSaveTime(flight: TourFlight) {
    flight.editTime = false;
    this.updateFlightPoint.emit(flight);
    this.processFlights();
  }

  // FLIGHTS

  onEditFlightTrack(flight: TourFlight, status: string) {
    if (flight.deleting) {
      return;
    }
    flight.flight_track.flight_status = status;
    this.updateFlightTrack.emit(flight);
    this.processFlights();
  }

  // Upload PDF

  onFileDropped($event) {
    this.file = null;
    this.prepareFilesList($event);
  }

  fileBrowseHandler(file) {
    this.file = null;
    this.prepareFilesList(file);
  }

  prepareFilesList(file) {
    if (this.loadingPDF) {
      return;
    }
    if (!file[0].type.includes('pdf')) {
      this.snackSvc.openSnackBar('File must be a pdf');
      return;
    }
    this.file = file[0];
    this.uploadPDF.emit(this.file);
  }

  // DND

  onTP(flight: TourFlight) {
    if (flight.highlightUnlock) {
      return;
    }
    if (flight.locked) {
      flight.highlightUnlock = true;
      setTimeout(() => {
        flight.highlightUnlock = false;
      }, 1500);
    }
  }

  dropItem(event: CdkDragDrop<string[]>) {
    const droppedItem = event.item.data;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      const prev = this.flights.find(f => +f.id === +event.previousContainer.id);
      const next = this.flights.find(f => +f.id === +event.container.id);
      if (next) {
        this.emitUpdTFlight.emit({
          'tpf': droppedItem,
          'tf': next
        });
      }
    }
    this.flights.forEach(flight => {
      flight.tourpax_flights.map(tpf => tpf.canDrag = !flight.locked);
      if (flight.tourpax_flights) {
        flight.tourpax_flights.sort((a, b) => a.tp_name.localeCompare(b.tp_name));
      }
    });
  }

  getConnectedList(): any[] {
    return this.flights.map(x => `${x.id}`);
  }
}
