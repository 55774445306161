import {TourPax} from './tour-pax.model';
import {Tour} from './tour.model';

export class TourGroup {
  id: number;
  tour_id: number;
  name: string;
  gl_id: number;
  color: string;
  tour?: Tour;
  gl?: TourPax;
}

export class TourpaxGroup {
  id: number;
  tour_group_id: number;
  tour_pax_id: number;
  tour_group?: TourGroup;
  tour_pax?: TourPax;
}
