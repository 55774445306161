import {Injectable} from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import {saveAs} from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  blob: Blob;

  constructor() {
  }

  findLongestItem(data, i) {
    let longestNameLength = 0;
    let longestName = '';
    data.forEach(row => {
      const name = row[i];
      if (name && name.length > longestNameLength) {
        longestNameLength = name.length;
        longestName = name;
      }
    });
    return longestName;
  }

  downloadExcel(headers: any[], data: any[], fileName: string): any {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(headers.includes('Timestamp') ? 'Responses' : 'Rooming');
    // Add headers
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell(cell => {
      cell.font = {bold: true};
    });
    // Add data
    data.forEach((row) => {
      worksheet.addRow(row);
    });
    // Freeze first row
    worksheet.views = [
      {state: 'frozen', xSplit: 0, ySplit: 1}
    ];
    // Autofilter
    worksheet.autoFilter = {
      from: 'A1',
      to: {
        row: data.length + 1,
        column: headers.length
      }
    };
    // All columns according to max size
    for (let i = 0; i < headers.length; i++) {
      worksheet.getColumn(i + 1).width = this.findLongestItem([headers, ...data], i).length + 2;
    }
    // FORM excel
    if (headers.includes('Timestamp')) {
      // Timestamp column
      worksheet.getColumn(1).numFmt = 'dd/mm/yyyy h:mm:ss';
    }
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      saveAs(blob, fileName);
    });
  }

  addEmptyRow(worksheet, totalColumns) {
    const empty3 = worksheet.addRow(new Array(totalColumns).fill(null));
    for (let colIdx = 1; colIdx <= totalColumns; colIdx++) {
      const cell = empty3.getCell(colIdx);
      cell.border = {
        top: {style: 'thin', color: {argb: 'FFFFFF'}},
        bottom: {style: 'thin', color: {argb: 'FFFFFF'}},
        left: {style: 'thin', color: {argb: 'FFFFFF'}},
        right: {style: 'thin', color: {argb: 'FFFFFF'}}
      };
    }
  }

  downloadItinerary(headers: any, data: any, fileName: string): any {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Itinerary');

    const [firstRow, dayHeadersRow, datesRow, citiesRow, ...eventsData] = data.data;
    // const hotelsData = data.hotels;

    const totalDays = firstRow[0];
    const totalColumns = totalDays + 2;

    // MAIN HEADER
    const formattedTourDate = new Date(firstRow[2]).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    worksheet.addRow([
      firstRow[1] +
      '  |  ' + formattedTourDate +
      '  -  ' + totalDays + ' days'
    ]);
    // Logo
    const logoImage = firstRow[3];
    const imageId = workbook.addImage({
      base64: logoImage,
      extension: 'png',
    });
    worksheet.addImage(imageId, {
      tl: {col: 0.333, row: 0.75},
      ext: {width: 225, height: 37},
    });

    // Style
    worksheet.getRow(1).height = 44;
    for (let col = 1; col <= totalColumns - 1; col++) {
      const cell = worksheet.getRow(1).getCell(col);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FBDC70'},
      };
      cell.font = {name: 'Aptos', bold: true, size: 15};
      cell.alignment = {horizontal: 'center', vertical: 'middle'};
    }
    worksheet.mergeCells(1, 1, 1, (totalColumns - 1));

    // DAY HEADERS
    const dateHeader = datesRow.map((date: string) => {
      if (date) {
        const parsedDate = new Date(date);
        let formattedDate = parsedDate.toLocaleDateString('en-US', {month: 'short', day: 'numeric'})
          .replace(',', '')
          .replace(' ', '-');
        if (parsedDate.getMonth() === 0 && parsedDate.getDate() === 1) {
          formattedDate += ' 🎉';
        }
        return formattedDate;
      }
    });
    const ndayRow = worksheet.addRow(dayHeadersRow);
    worksheet.getRow(ndayRow.number).font = {name: 'Aptos', size: 10, bold: true};
    worksheet.getRow(ndayRow.number).height = 20;
    worksheet.getRow(ndayRow.number).alignment = {vertical: 'middle', horizontal: 'center'};
    const dateRow = worksheet.addRow(dateHeader);
    worksheet.getRow(dateRow.number).font = {name: 'Aptos', size: 10, bold: true};
    worksheet.getRow(dateRow.number).height = 20;
    worksheet.getRow(dateRow.number).alignment = {vertical: 'middle', horizontal: 'center'};
    const cityRow = worksheet.addRow(citiesRow);
    worksheet.getRow(cityRow.number).font = {name: 'Aptos', size: 10, bold: true};
    worksheet.getRow(cityRow.number).height = 20;
    worksheet.getRow(cityRow.number).alignment = {vertical: 'middle', horizontal: 'center'};
    const overNight = cityRow.getCell(2);
    overNight.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'FFFFFF'}
    };

    // Add event data rows
    eventsData.forEach((eventRow) => {
      if (!Array.isArray(eventRow)) {
        return;
      }
      const row = worksheet.addRow(
        eventRow.map((event: any) => (event && typeof event === 'object' ? event.name : ''))
      );
      row.eachCell((cell, colNum) => {
        const event = eventRow[colNum - 1];
        if (event && typeof event === 'object' && event.color) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: event.color},
          };
          cell.font = {name: 'Aptos', bold: true, size: 8};
          cell.alignment = {vertical: 'middle', horizontal: 'center'};
        }
      });
      row.height = 30;
    });
    // Merge cells
    for (let col = 2; col <= totalColumns; col++) {
      let startRow: number | null = null;
      let previousValue: any = null;
      for (let row = 4; row <= worksheet.rowCount; row++) {
        const cellValue = worksheet.getRow(row).getCell(col).value;
        if (cellValue === previousValue && cellValue !== null && cellValue !== '') {
          if (startRow === null) {
            startRow = row - 1;
          }
        } else {
          if (startRow !== null) {
            worksheet.mergeCells(startRow, col, row - 1, col);
          }
          startRow = null;
        }
        previousValue = cellValue;
      }
      if (startRow !== null) {
        worksheet.mergeCells(startRow, col, worksheet.rowCount, col);
      }
    }

    // Columns width
    worksheet.columns.forEach((column) => {
      column.width = 80 / 6;
    });

    // GLOBAL FORMAT
    worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
      row.eachCell({includeEmpty: true}, (cell, colIndex) => {
        if (!cell.border) {
          cell.border = {
            left: {style: 'thin'},
            right: {style: 'thin'},
            top: {style: 'thin'},
            bottom: {style: 'thin'}
          };
        }
        // First few rows
        if (rowNumber <= cityRow.number) {
          // Last few cells
          if (colIndex === totalColumns) {
            cell.border.top = {style: 'thin', color: {argb: 'FFFFFF'}};
            cell.border.bottom = {style: 'thin', color: {argb: 'FFFFFF'}};
            cell.border.left = {style: 'thin', color: {argb: '000000'}};
            cell.border.right = {style: 'thin', color: {argb: 'FFFFFF'}};
          }
        } else {
          cell.border.left = {style: 'thin', color: {argb: '000000'}};
          cell.border.right = {style: 'thin', color: {argb: '000000'}};
          if (colIndex === 1) {
            cell.font = {name: 'Aptos', size: 10, bold: true};
            cell.alignment = {vertical: 'middle', horizontal: 'center'};
            cell.numFmt = 'hh:mm AM/PM';
            cell.border.top = {style: 'thin', color: {argb: '000000'}};
            cell.border.bottom = {style: 'thin', color: {argb: '000000'}};
          } else if (colIndex > 1 && colIndex < totalColumns) {
            cell.font = {name: 'Aptos', size: 8};
            cell.alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            cell.border.top = {style: 'thin', color: {argb: 'FFFFFF'}};
            cell.border.bottom = {style: 'thin', color: {argb: 'FFFFFF'}};
          } else if (colIndex === totalColumns) {
            cell.border.top = {style: 'thin', color: {argb: 'FFFFFF'}};
            cell.border.bottom = {style: 'thin', color: {argb: 'FFFFFF'}};
            cell.border.left = {style: 'thin', color: {argb: 'FFFFFF'}};
            cell.border.right = {style: 'thin', color: {argb: 'FFFFFF'}};
          }
        }
      });
    });

    // Last little touches
    // [2, 3, 4].forEach(rowNumber => {
    //   const cell = worksheet.getCell('A' + rowNumber);
    //   cell.border = {
    //     top: {style: 'thin', color: {argb: 'FFFFFF'}},
    //     left: {style: 'thin', color: {argb: 'FFFFFF'}},
    //     bottom: {style: 'thin', color: {argb: 'FFFFFF'}},
    //     right: {style: 'thin', color: {argb: 'FFFFFF'}}
    //   };
    // });

    // Hotel info
    // this.addEmptyRow(worksheet, totalColumns + 2);
    // this.addEmptyRow(worksheet, totalColumns + 2);
    // const hotelHeader = worksheet.addRow(['', 'HOTELS INFO']);
    // worksheet.getRow(hotelHeader.number).font = {name: 'Aptos', size: 12, bold: true};
    // worksheet.getRow(hotelHeader.number).height = 30;
    // worksheet.getRow(hotelHeader.number).alignment = {vertical: 'middle', horizontal: 'left'};
    // worksheet.getRow(hotelHeader.number).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: {argb: 'CCCCFF'}
    // };
    // worksheet.mergeCells(hotelHeader.number, 2, hotelHeader.number, totalColumns + 2);
    // this.addEmptyRow(worksheet, totalColumns + 2);
    // hotelsData.forEach((hotel) => {
    //   const row1 = worksheet.addRow(['', 'Check-in', hotel.check_in, hotel.name]);
    //   const row2 = worksheet.addRow(['', 'Check-out', hotel.check_out, hotel.address]);
    //   [row1, row2].forEach(row => {
    //     row.height = 17;
    //     for (let colNumber = 1; colNumber <= totalColumns + 2; colNumber++) {
    //       const cell = row.getCell(colNumber);
    //       if (colNumber <= 7) {
    //         if (colNumber <= 2) {
    //           cell.font = {name: 'Aptos', size: 10, bold: true};
    //           cell.alignment = {vertical: 'middle', horizontal: 'center'};
    //         } else {
    //           cell.font = {name: 'Aptos', size: 10};
    //         }
    //         cell.fill = {
    //           type: 'pattern',
    //           pattern: 'solid',
    //           fgColor: {argb: 'EAEAFF'}
    //         };
    //       } else {
    //         cell.border = {
    //           top: {style: 'thin', color: {argb: 'FFFFFF'}},
    //           bottom: {style: 'thin', color: {argb: 'FFFFFF'}},
    //           left: {style: 'thin', color: {argb: 'FFFFFF'}},
    //           right: {style: 'thin', color: {argb: 'FFFFFF'}}
    //         };
    //       }
    //     }
    //   });
    //   this.addEmptyRow(worksheet, totalColumns + 2);
    // });
    // this.addEmptyRow(worksheet, totalColumns + 2);

    // Prepare legend
    const beforeLegendColumn = worksheet.getColumn(totalColumns);
    const afterLegendColumn = worksheet.getColumn(totalColumns + 1);
    [beforeLegendColumn, afterLegendColumn].forEach(col => {
      col.width = 60 / 6;
      col.eachCell((cell, rowNumber) => {
        if (rowNumber > 23) {
          return;
        }
        cell.border = {
          top: {style: 'thin', color: {argb: 'FFFFFF'}},
          bottom: {style: 'thin', color: {argb: 'FFFFFF'}},
          left: {style: 'thin', color: {argb: 'FFFFFF'}},
          right: {style: 'thin', color: {argb: 'FFFFFF'}}
        };
      });
    });

    // Add legend
    const legendData = [
      // {rowOffset: 0, value: 'Legend', color: 'FFFFFF'},
      {rowOffset: 1, value: 'Group activity', color: '9999FF'}, // Ube
      {rowOffset: 2, value: 'Optional', color: 'FBDC70'}, // Butter
      {rowOffset: 3, value: 'Special', color: '8ADDCD'} // Mojito
    ];
    const legendColumn = worksheet.getColumn(1);
    // legendColumn.width = 80 / 6;
    legendColumn.eachCell((cell, rowNumber) => {
      if (rowNumber > 4) {
        return;
      }
      // cell.border = {
      //   top: {style: 'thin', color: {argb: 'FFFFFF'}},
      //   bottom: {style: 'thin', color: {argb: 'FFFFFF'}},
      //   left: {style: 'thin', color: {argb: 'FFFFFF'}},
      //   right: {style: 'thin', color: {argb: 'FFFFFF'}}
      // };
      const legendItem = legendData.find(item => rowNumber === 1 + item.rowOffset);
      if (legendItem) {
        if (legendItem.rowOffset === 0) {
          cell.font = {name: 'Aptos', size: 10, bold: true};
        } else {
          cell.font = {name: 'Aptos', size: 10};
        }
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
        cell.value = legendItem.value;
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: legendItem.color}
        };
        // cell.border = {
        //   top: {style: 'thin', color: {argb: '000000'}},
        //   bottom: {style: 'thin', color: {argb: '000000'}},
        //   left: {style: 'thin', color: {argb: '000000'}},
        //   right: {style: 'thin', color: {argb: '000000'}}
        // };
      }
    });

    // Freeze first column
    worksheet.views = [{state: 'frozen', xSplit: 1, ySplit: 4}];

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      saveAs(blob, fileName);
    });
  }

}
