import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Optional} from '../../models/optional.model';

@Injectable({providedIn: 'root'})
export class HttpOptionalService {

  private endpoints: {
    allOptionals: string,
    crudOptional: string,
    uploadOptImage: string,
    optionalCity: string,
    optionalPDF: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allOptionals: environment.apiUrl + 'optionals/',
      crudOptional: environment.apiUrl + 'optional/',
      uploadOptImage: environment.apiUrl + 'upload_optional_image/',
      optionalCity: environment.apiUrl + 'optional_city/',
      optionalPDF: environment.apiUrl + 'optionals/pdf/',
    };
  }

  // VERIFY INPUT
  public static validateInputOptional(opt: Optional) {
    if (!opt.name) {
      return 'Name needed';
    }
    if (!opt.city_id) {
      return 'City needed';
    }
    if (!opt.companies) {
      return 'Companies needed';
    }
    const re_only_nums = /^[\d.,\-]*$/;
    if (opt.price && re_only_nums.test(opt.price)) {
      return 'Price currency needed';
    }
    if (opt.cost && re_only_nums.test(opt.cost)) {
      return 'Cost currency needed';
    }
    const re = /^[\d,]*$/;
    if (opt.access && !re.test(opt.access)) {
      return 'Wrong access';
    }
    const re2 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/;
    if (opt.email && !re2.test(opt.email)) {
      return 'Email must be valid';
    }
    if (opt.map) {
      if (opt.map.substring(0, 8) !== 'https://' && opt.map.substring(0, 7) !== 'http://') {
        return 'Map must be a valid link';
      }
    }
    if (opt.website) {
      if (opt.website.substring(0, 8) !== 'https://' && opt.website.substring(0, 7) !== 'http://') {
        return 'Website must be a valid link';
      }
    }
    return false;
  }

  // OPTIONALS

  /* gets all optionals */
  getAllOptionals(user_id?: number, short?: boolean): Observable<any> {
    let params = new HttpParams();
    if (user_id) {
      params = params.set('user_id', user_id);
    }
    if (short) {
      params = params.set('short', 'ok');
    }
    return this.http.get<any>(this.endpoints.allOptionals, {params: params});
  }

  /* Creates a optional */
  createOptional(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudOptional, data);
  }

  /* Updates a optional */
  updateOptional(rest_id: number, data): Observable<any> {
    return this.http.put<any>(this.endpoints.crudOptional + rest_id, data);
  }

  /* Uploads imae for FT*/
  uploadOptImage(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadOptImage, data);
  }

  /* Gets all optionals from one city */
  getOptionalsCity(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.optionalCity, data);
  }

  /* Delete optional */
  deleteOptional(data): Observable<any> {
    return this.http.delete<any>(this.endpoints.crudOptional, {params: data});
  }

  /* Download optionals PDF */
  downloadTDOptsPDF(): Observable<Blob> {
    return this.http.get(this.endpoints.optionalPDF, {responseType: 'blob'});
  }
}
