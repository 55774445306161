import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HttpTourService {
  private endpoints: {
    crudTour: string,
    tourSetts: string,
    tourAll: string,
    tourPax: string,
    tour_Paxs: string,
    mergeTP: string,
    tours_Pax: string,
    toursUser: string,
    dayWeather: string,
    allTours: string,
    resetCover: string,
    sendCover: string,
    resetLogo: string,
    sendLogo: string,
    sendGroupPic: string,
    regenerateTour: string,
    duplicateTour: string,
    exportTour: string,
    fixTitle: string,
    duplicateItinerary: string,
    tourAccesses: string,
    tourAccess: string,
    uploadPAX: string,
    uploadTDI: string,
    crudTourGroup: string,
    crudTourGroups: string,
    crudTourpaxGroup: string,
    crudTourpaxGroups: string,
    tourExcel: string,
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      crudTour: environment.apiUrl + 'tour/',
      tourSetts: environment.apiUrl + 'tour_settings/',
      tourAll: environment.apiUrl + 'tour_all/',
      toursUser: environment.apiUrl + 'tours_user/',
      dayWeather: environment.apiUrl + 'day_weather/',
      allTours: environment.apiUrl + 'tours/',
      sendCover: environment.apiUrl + 'upload_tour_cover/',
      resetCover: environment.apiUrl + 'reset_tour_cover/',
      sendLogo: environment.apiUrl + 'upload_company_logo/',
      resetLogo: environment.apiUrl + 'reset_company_logo/',
      sendGroupPic: environment.apiUrl + 'upload_group_picture/',
      regenerateTour: environment.apiUrl + 'regenerate_tour/',
      duplicateTour: environment.apiUrl + 'duplicate_tour/',
      exportTour: environment.apiUrl + 'export_tour/',
      fixTitle: environment.apiUrl + 'tour_title/',
      duplicateItinerary: environment.apiUrl + 'duplicate_itinerary/',
      tourPax: environment.apiUrl + 'tourpax/',
      tour_Paxs: environment.apiUrl + 'tour_paxs/',
      tours_Pax: environment.apiUrl + 'tours_pax/',
      mergeTP: environment.apiUrl + 'merge_tp/',
      tourAccesses: environment.apiUrl + 'tour_accesses/',
      tourAccess: environment.apiUrl + 'tour_access/',
      uploadPAX: environment.apiUrl + 'upload_room/',
      uploadTDI: environment.apiUrl + 'upload_tdi/',
      crudTourGroup: environment.apiUrl + 'tour_group/',
      crudTourpaxGroup: environment.apiUrl + 'tourpax_group/',
      crudTourGroups: environment.apiUrl + 'tour_groups/',
      crudTourpaxGroups: environment.apiUrl + 'tourpax_groups/',
      tourExcel: environment.apiUrl + 'tour/excel/',
    };
  }

  // TOUR

  /* Creates a new tour with code and prod id */
  createTour(data): Observable<any> {
    return this.http.post<any>(this.endpoints.crudTour, data);
  }

  /* Gets a tour with tour_id */
  getTour(tour_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.crudTour, {params: params});
  }

  /* Gets a tour with tour_id */
  async getTourAsync(tour_id: number, req_id: number) {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    return await this.http.get<any>(this.endpoints.crudTour, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  }

  /* Gets all tours */
  getAllTours(): Observable<any> {
    const params = new HttpParams()
      .set('trashed', 'ok');
    return this.http.get<any>(this.endpoints.allTours, {params: params});
  }

  /* Gets a tour with tour_id */
  getTourAll(tour_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.tourAll, {params: params});
  }

  /* Gets async tour with tour_id */
  async getTourAllAsync(tour_id: number, req_id: number) {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    return await this.http.get<any>(this.endpoints.tourAll, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Gets async tour weather with tour_id */
  // async getTourWeatherData(tour_id: number) {
  //   const params = new HttpParams()
  //     .set('tour_id', tour_id.toString());
  //   return await this.http.get<any>(this.endpoints.tourWeather, {params: params}).toPromise()
  //     .then(res => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // }

  /* Gets async day weather with day_id */
  async getDayWeatherData(tour_id: number) {
    const params = new HttpParams()
      .set('day_id', tour_id.toString());
    return await this.http.get<any>(this.endpoints.dayWeather, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Gets all tour pax with tour_id */
  getTourPax(tour_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    return this.http.get<any>(this.endpoints.tour_Paxs, {params: params});
  }

  /* Gets all tours from pax with tour_id */
  getToursPax(user_id: number, tips?: string): Observable<any> {
    let params = new HttpParams()
      .set('user_id', user_id.toString());
    if (tips === 'true') {
      params = params.set('tips', 'true');
    }
    return this.http.get<any>(this.endpoints.tours_Pax, {params: params});
  }

  /* Update tour */
  updateTour(tour_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.crudTour + tour_id, paramsToUpdate);
  }

  /* Update tour async */
  async updateTourAsync(tour_id: number, paramsToUpdate: any) {
    return await this.http.put<any>(this.endpoints.crudTour + tour_id, paramsToUpdate).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Update user */
  updateTourSettings(tour_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put(this.endpoints.tourSetts + tour_id, paramsToUpdate);
  }

  /* Deletes a tour with tour_id */
  deleteTour(tour_id: number, req_id: number, force?: string): Observable<any> {
    let params = new HttpParams()
      .set('tour_id', tour_id.toString())
      .set('req_id', req_id.toString());
    if (force === 'true') {
      params = params.set('force', 'true');
    }
    return this.http.delete<any>(this.endpoints.crudTour, {params: params});
  }

  /* Deletes a tourpax with tp_id */
  deleteTourPax(tp_id: number, req_id: number): Observable<any> {
    const params = new HttpParams()
      .set('tp_id', tp_id.toString())
      .set('req_id', req_id.toString());
    return this.http.delete<any>(this.endpoints.tourPax, {params: params});
  }

  /* Create tour PAX (Join tour) */
  createTourPax(data): Observable<any> {
    return this.http.post<any>(this.endpoints.tourPax, data);
  }

  /* Update tour PAX */
  updateTourPax(tp_id: number, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.tourPax + tp_id, paramsToUpdate);
  }

  /* Merge two tour PAX */
  mergeTwoTourPax(data): Observable<any> {
    return this.http.post<any>(this.endpoints.mergeTP, data);
  }

  /* Gets all tours from one user */
  getToursUser(user_id: number, req_id: number, copycomps?: boolean): Observable<any> {
    let params = new HttpParams()
      .set('user_id', user_id.toString())
      .set('req_id', req_id.toString());
    if (copycomps) {
      params = params.set('copycomps', '1');
    }
    return this.http.get<any>(this.endpoints.toursUser, {params: params});
  }

  // Send Tour Cover
  sendCover(data): Observable<any> {
    return this.http.post<any>(this.endpoints.sendCover, data);
  }

  // Send Company Logo
  sendLogo(data): Observable<any> {
    return this.http.post<any>(this.endpoints.sendLogo, data);
  }

  // Send Company Logo
  sendGroupPic(data): Observable<any> {
    return this.http.post<any>(this.endpoints.sendGroupPic, data);
  }

  // Duplicate tour
  duplicateTour(data): Observable<any> {
    return this.http.post<any>(this.endpoints.duplicateTour, data);
  }

  // Export tour
  exportTour(tour_id: number): Observable<Blob> {
    return this.http.get(this.endpoints.exportTour + tour_id, {responseType: 'blob'});
  }

  // fixTitle
  fixTitle(tour_id: number): Observable<any> {
    return this.http.get(this.endpoints.fixTitle + tour_id);
  }

  // Duplicate itinerary
  duplicateItinerary(data): Observable<any> {
    return this.http.post<any>(this.endpoints.duplicateItinerary, data);
  }

  /* Regenerate tour: days, comps and events */
  regenerateTour(tour_id: number, req_id: number, force_reg: boolean, force_get: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.set('tour_id', tour_id.toString());
    if (req_id) {
      params = params.set('req_id', req_id.toString());
    }
    if (force_reg) {
      params = params.set('force_reg', force_reg.toString());
    }
    if (force_get) {
      params = params.set('force_get', force_get.toString());
    }
    // console.log(params);
    return this.http.get<any>(this.endpoints.regenerateTour, {params: params});
  }

  // CREATE ACCESS TO EMAIL
  createAccess(data): Observable<any> {
    return this.http.post<any>(this.endpoints.tourAccess, data);
  }

  // UPDATE ACCESS TO EMAIL
  updateAccess(ta_id: string, paramsToUpdate: any): Observable<any> {
    return this.http.put<any>(this.endpoints.tourAccess + ta_id, paramsToUpdate);
  }

  // DELETE ACCESS TO EMAIL
  deleteAccess(ta_id): Observable<any> {
    const params = new HttpParams().set('ta_id', ta_id.toString());
    return this.http.delete<any>(this.endpoints.tourAccess, {params: params});
  }

  // UPLOAD TOUR PAX
  uploadPAX(data): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadPAX, data);
  }

  // Upload TDI
  uploadTDI(data): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadTDI, data);
  }

  /* Download Rooming Excel */
  async downloadTourExcel(tour_id: number) {
    return await this.http.get<any>(this.endpoints.tourExcel + tour_id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  // TOUR GROUP

  /* Create tour group */
  async createTourGroup(data) {
    return await this.http.post<any>(this.endpoints.crudTourGroup, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Get all tour groups */
  async getTourGroups() {
    return await this.http.get<any>(this.endpoints.crudTourGroups).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Get a specific tour group */
  async getTourGroup(id) {
    return await this.http.get<any>(this.endpoints.crudTourGroup + id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Update a specific tour group */
  async updateTourGroup(id, data) {
    return await this.http.put<any>(this.endpoints.crudTourGroup + id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Delete a specific tour group */
  async deleteTourGroup(id) {
    return await this.http.delete<any>(this.endpoints.crudTourGroup + id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Create tour pax group */
  async createTourPaxGroup(data) {
    return await this.http.post<any>(this.endpoints.crudTourpaxGroup, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Get all tour pax groups */
  async getTourPaxGroups() {
    return await this.http.get<any>(this.endpoints.crudTourpaxGroups).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Get a specific tour pax group */
  async getTourPaxGroup(id) {
    return await this.http.get<any>(this.endpoints.crudTourpaxGroup + id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Update a specific tour pax group */
  async updateTourPaxGroup(id, data) {
    return await this.http.put<any>(this.endpoints.crudTourpaxGroup + id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Delete a specific tour pax group */
  async deleteTourPaxGroup(id) {
    return await this.http.delete<any>(this.endpoints.crudTourpaxGroup + id).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

}
