import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {environment} from '../../../../../environments/environment';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {Tour} from '../../../../shared/models/tour.model';
import {HttpSessionService} from '../../../../shared/services/http/http-session.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {AddVoucherComponent} from '../add-voucher/add-voucher.component';
import {OutlookService} from '../../../../shared/services/session/outlook.service';
import {HttpTourOptionalService} from '../../../../shared/services/http/http-tour-optional.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  loading = false;
  setts_loading = false;
  inviteLink: string;
  tour_id: number;
  tour_opt_id: number;
  email: string;
  td: string;
  tokenResetPwd: string;
  password: string;
  sessionId: string;
  show_join_ago = true;
  show_join_ago_txt: string;
  show_last_ago = true;
  show_last_ago_txt: string;
  days_by_year: any;
  interval: any;
  outlookMail: any;

  showOutlook = false;

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    // console.log(event.key);
    event.preventDefault();
    if (event.key === 'Enter') {
      this.submit();
    }
  }

  constructor(public dialogRef: MatDialogRef<EditUserComponent>,
              private httpUser: HttpUserService,
              private httpTour: HttpTourService,
              private httpOrders: HttpOrdersService,
              private httpTOSvc: HttpTourOptionalService,
              public dialog: MatDialog,
              private outlookSvc: OutlookService,
              private clipboard: Clipboard,
              private httpSession: HttpSessionService,
              private snackSvc: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public data: { user: User, referred_by: string, reason: string }) {
    this.email = this.data.user.email;
    const invite = btoa(this.data.user.given_name + '&' + this.data.user.id);
    this.inviteLink = environment.selfUrl + 'login?invite=' + invite.replace(/=/g, '');
    this.countTourDaysByYear(this.data.user.tours);
  }

  ngOnInit(): void {
    this.calcTime();
    this.interval = setInterval(() => {
      this.calcTime();
    }, 1000);
  }

  getUser() {
    this.loading = true;
    this.httpUser.getUser(this.data.user.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.user = res.results.user;
            this.countTourDaysByYear(this.data.user.tours);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  countTourDaysByYear(tours: Tour[]) {
    const daysByYear = {};
    tours.forEach(tour => {
      if (tour?.tour_settings?.paid_at) {
        const year = new Date(tour.tour_starts).getFullYear();
        const days = tour.ndays;
        if (!daysByYear[year]) {
          daysByYear[year] = 0;
        }
        daysByYear[year] += days;
      }
    });
    this.days_by_year = daysByYear;
  }

  recentOn(tours: Tour[]): boolean {
    const min = 15;
    if (tours) {
      return tours.filter(t => new Date(t.updated_at).getTime() + min * 60 * 1000 >= new Date().getTime()).length > 0;
    } else {
      return false;
    }
  }

  showJoinTime() {
    this.show_join_ago = false;
    setTimeout(() => {
      this.show_join_ago = true;
    }, 5000);
  }

  showLastTime() {
    this.show_last_ago = false;
    setTimeout(() => {
      this.show_last_ago = true;
    }, 5000);
  }

  calcTime() {
    let time_amount;
    let time_units;
    let startDate;
    if ((this.data.user.role >= User.ef && this.data.user.tours?.length > 0)) {
      startDate = new Date(this.olderTour(this.data.user.tours, 't'));
    } else if (this.data.user.role <= User.pax_admin && this.data.user.tour_pax?.length > 0) {
      startDate = new Date(this.olderTour(this.data.user.tour_pax, 'tp'));
    } else {
      startDate = new Date(this.data.user.updated_at);
    }
    const endDate = new Date();
    let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_last_ago_txt = time_amount + ' ' + time_units + ' ago';
    const startDate2 = new Date(this.data.user.created_at);
    const endDate2 = new Date();
    seconds = (endDate2.getTime() - startDate2.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_join_ago_txt = time_amount + ' ' + time_units + ' ago';
  }

  olderTour(tours: any[], type: string): string {
    if (type === 't') {
      return Math.max.apply(null, tours?.map(function (e) {
        return new Date(e.updated_at);
      }));
    } else {
      return Math.max.apply(null, tours?.map(function (e) {
        return new Date(e.tour.updated_at);
      }));
    }
  }

  prU() {
    console.log(this.data.user);
  }

  submit(): void {
    if (!this.data.user.email) {
      this.snackSvc.openSnackBar('Invalid email');
      return;
    }
    if (this.data.user.role === null || this.data.user.role === undefined) {
      this.snackSvc.openSnackBar('Invalid role');
      return;
    }
    if (!this.data.user.user_settings.secs_undo) {
      this.snackSvc.openSnackBar('Invalid secs');
      return;
    }
    const valid = /^(?:2[0-3]|[01][0-9]):(00|15|30|45)$/.test(this.data.user.user_settings.safety_check);
    if (!this.data.user.user_settings.safety_check || !valid) {
      this.snackSvc.openSnackBar('Invalid safety check: 00/15/30/45');
      return;
    }
    this.onUserChange();
  }

  onUserChange() {
    this.setts_loading = true;
    const data = {
      'name': this.data.user.name,
      'given_name': this.data.user.given_name,
      'surname': this.data.user.surname,
      'phone': this.data.user.phone,
      'outlook_id': this.data.user.outlook_id,
      'google_id': this.data.user.google_id,
      'apple_id': this.data.user.apple_id,
      'gender': this.data.user.gender,
      'role': this.data.user.role,
    };
    if (this.email !== this.data.user.email) {
      data['email'] = this.data.user.email;
    }
    // console.log(data);
    this.httpUser.updateUser(this.data.user.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.setts_loading = false;
          if (res.status < 400) {
            const old_setts = this.data.user.user_settings;
            this.data.user = res.results.user;
            this.data.user.user_settings = old_setts;
            this.onUserSettsChange('all');
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating profile');
        });
  }

  onUserSettsChange(type) {
    let data = {};
    if (type === 'all') {
      data = this.data.user.user_settings;
    } else {
      data[type] = !this.data.user.user_settings[type];
    }
    this.setts_loading = true;
    // console.log(data);
    this.httpUser.updateUserSettings(this.data.user.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.setts_loading = false;
          if (res.status < 400) {
            if (type !== 'all') {
              this.data.user.user_settings[type] = res.results.user_settings[type];
            } else {
              this.data.user.user_settings = res.results.user_settings;
              this.close('save');
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating settings');
        });
  }

  createVoucher(user: User) {
    const dialogRef = this.dialog.open(AddVoucherComponent, {
      autoFocus: true,
      data: {user: user}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        user.voucher.push(result['voucher']);
      }
    });
  }

  deleteVoucher(user: User, voucher_id) {
    // Show snackbar to undo delete
    const snackbarRef = this.snackSvc.openSnackBar('Delete voucher?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.loading = true;
          this.httpOrders.deleteVoucher(voucher_id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              this.loading = false;
              console.log(res);
              if (res.status < 400) {
                const idx = user.voucher.findIndex(v => '' + v.id === '' + voucher_id);
                user.voucher.splice(idx, 1);
              } else {
                this.snackSvc.resultsElse(res);
              }
            }, error => {
              this.loading = false;
              console.log(error);
            });
        }
      });
  }

  onAddToTour(user) {
    if (!this.tour_id) {
      this.snackSvc.openSnackBar('Write a tour id');
      return;
    }
    this.setts_loading = true;
    const data = {
      'tour_id': this.tour_id,
      'user_id': user.id,
    };
    // console.log(data);
    this.httpTour.createTourPax(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Add tourpax to data
            this.data.user.tour_pax.push(res.results.tour_pax);
            this.tour_id = null;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
              console.log(res.message.toString());
            }
          }
          this.setts_loading = false;
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error adding user to tour');
        });
  }

  onSignUp(user) {
    if (!this.tour_opt_id) {
      this.snackSvc.openSnackBar('Set tour opt id');
      return;
    }
    this.loading = true;
    const params = {
      'user_id': user.id,
      'tour_optional_id': this.tour_opt_id,
      'url': environment.selfUrl,
    };
    // console.log(params);
    this.httpTOSvc.createTourOptPax(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status < 400) {
            this.snackSvc.openSnackBar('Pax signed up to opt');
          } else {
            if (res.status === 405) {
              this.snackSvc.openSnackBar('Not allowed. Contact Tour leader');
            } else {
              this.snackSvc.openSnackBar('ERROR. Signing up');
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Signing up');
          this.loading = false;
        });
  }

  onRemoveFromTour(tp) {
    this.setts_loading = true;
    this.httpTour.deleteTourPax(tp.id, 2)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            // Remove tourpax to data
            const idx = this.data.user.tour_pax.findIndex(tpp => tpp.id === tp.id);
            this.data.user.tour_pax.splice(idx, 1);
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              this.snackSvc.openSnackBar(res.message.toString());
              console.log(res.message.toString());
            }
          }
          this.setts_loading = false;
        },
        error => {
          this.setts_loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error removing user from tour');
        });
  }

  async sendTokenAsync(silent?: boolean) {
    let params;
    params = {email: this.data.user.email, url: environment.selfUrl};
    if (silent) {
      params['silent'] = true;
    }
    const res = await this.httpSession.requestPasswordToken(params);
    console.log(res);
    if (res.status < 400) {
      this.tokenResetPwd = res.results;
      if (!silent) {
        this.snackSvc.openSnackBar(res.message);
      }
    } else {
      this.snackSvc.openSnackBar('Error');
    }
  }

  generatePassword() {
    this.password = Array(10).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$').map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }).join('');
    this.clipboard.copy(this.password);
  }

  async asyncReqPwd() {
    if (!this.password) {
      this.snackSvc.openSnackBar('No password');
      return;
    }
    if (!this.tokenResetPwd) {
      this.snackSvc.openSnackBar('No token, set new one');
      return;
    }
    const res = await this.httpSession.requestPasswordChange(
      {
        email: this.data.user.email,
        password: this.password,
        password_confirmation: this.password,
        token: this.tokenResetPwd
      });
    console.log(res);
    if (res.status < 400) {
      this.snackSvc.openSnackBar('Password reset');
      this.tokenResetPwd = null;
      this.password = null;
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  onImpContacts() {
    if (!this.sessionId) {
      this.snackSvc.openSnackBar('Need session ID');
      return;
    }
    const snackbarRef = this.snackSvc.openSnackBar('Force import contacts?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.impConts();
        }
      });
  }

  async impConts() {
    const data = {
      session_id: this.sessionId,
    };
    const res = await this.outlookSvc.importContacts(data);
    console.log(res);
    if (res.status < 400) {
      this.snackSvc.openSnackBar(res.message);
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  onDelContacts() {
    if (!this.sessionId) {
      this.snackSvc.openSnackBar('Need session ID');
      return;
    }
    const snackbarRef = this.snackSvc.openSnackBar('Delete contacts!?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.delConts();
        }
      });
  }

  async delConts() {
    const data = {
      session_id: this.sessionId,
    };
    const res = await this.outlookSvc.deleteContacts(data);
    console.log(res);
    if (res.status < 400) {
      this.snackSvc.openSnackBar(res.message);
    } else {
      this.snackSvc.resultsElse(res);
    }
  }

  whoAmi() {
    if (!this.sessionId) {
      this.snackSvc.openSnackBar('Need session ID');
      return;
    }
    const data = {
      'session_id': this.sessionId,
    };
    this.outlookSvc.whoAmI(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.td = res.results['displayName'];
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Who am I');
        });
  }

  onOutlookMail() {
    if (!this.sessionId) {
      this.snackSvc.openSnackBar('Need session ID');
      return;
    }
    const data = {
      'session_id': this.sessionId,
    };
    this.outlookSvc.outlookMail(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.outlookMail = res.results;
            console.log(this.outlookMail);
            this.snackSvc.openSnackBar('Mail received');
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Get Mail');
        });
  }

  async onEFfolder(user) {
    if (!this.sessionId) {
      this.snackSvc.openSnackBar('Need session ID');
      return;
    }
    const data = {
      'user_id': user.id,
      'session_id': this.sessionId,
      'destination': 'inbox',
    };
    if (!environment.production) {
      data['test'] = true;
    }
    // console.log(data);
    const res = await this.outlookSvc.createEFFolder(data);
    console.log(res);
    if (res.results) {
      this.snackSvc.openSnackBar(res.message);
    }
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
