import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {User} from '../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../shared/services/common/title.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpTourOptionalService} from '../../../../shared/services/http/http-tour-optional.service';
import {environment} from '../../../../../environments/environment';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {loadStripe} from '@stripe/stripe-js';
import {Orders} from '../../../../shared/models/orders.model';
import {Location} from '@angular/common';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {Activetour} from '../../../../shared/models/activetour.model';
import {TourPax} from '../../../../shared/models/tour-pax.model';

@Component({
  selector: 'app-tour-opt-pax',
  templateUrl: './tour-opt-pax.component.html',
  styleUrls: ['./tour-opt-pax.component.scss']
})
export class TourOptPaxComponent implements OnInit, OnDestroy {
  currentUser: User;
  activeTour: Activetour;
  tourPax: TourPax[];
  tourOptional: any;
  tourOptionals: any[];
  order: Orders;
  orders: Orders[] = [];

  tid: string;
  tour_id: string;
  toId: string;
  topId: string;
  payment_intent: any;
  stripePromise: any = null;
  stripe: any = null;
  empty: boolean;

  baseUrlCover: string;
  price: number;
  ext_price: number;
  stripe_fee = 0;
  pln_fee = 0;
  loading: boolean;
  loadingOpts: boolean;
  pushed_pay: boolean;
  payment_loading: boolean;
  samePrice = 0;

  fix_pln: number;
  per_pln: number;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleSvc: TitleService,
    private location: Location,
    private flagService: FlagService,
    private snackSvc: SnackbarService,
    private httpTOSvc: HttpTourOptionalService,
    private httpOrder: HttpOrdersService,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
    this.titleSvc.setTitle('Tour Optionals');
  }

  ngOnInit(): void {
    if (this.currentUser.role > User.pax_admin) {
      this.snackSvc.openSnackBar('Access here as PAX, not TD');
      this.router.navigate(['tours']);
      return;
    }
    this.tid = this.route.snapshot.params['tour_id'] || null;
    this.topId = this.route.snapshot.params['top_id'] || null;
    this.toId = this.route.snapshot.params['to_id'] || null;
    if (this.tid) {
      this.tour_id = atob(this.tid);
    } else {
      if (this.activeTour?.tour) {
        this.tour_id = '' + this.activeTour.tour.id;
      }
    }
    if ((!this.tid && !this.topId && !this.toId) && !this.activeTour) {
      this.snackSvc.openSnackBar('Please select a tour');
      this.router.navigate(['home'], {queryParams: {t: 'o'}});
      return;
    }
    const sessionId = this.route.snapshot.queryParams['session_id'] || null;
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'session_id': null,
        'ref': null,
      },
      queryParamsHandling: 'merge'
    });
    this.baseUrlCover = environment.baseUrl;
    this.payment_loading = false;
    if (sessionId && sessionId !== 'error') {
      this.verifySession(sessionId);
    }
    if (sessionId && sessionId === 'error') {
      this.snackSvc.openSnackBar('Payment not completed');
    }
    this.reload();
  }

  reload() {
    if (this.toId) {
      this.getTourOpt(this.toId);
    } else if (this.topId) {
      this.getTourOptPax(this.topId);
    } else {
      this.getTourOptsForPax();
    }
  }

  printIt() {
    console.log(this.tourOptional);
    console.log(this.tourOptionals);
  }

  goPay() {
    this.createCheckout();
  }

  fixPrice() {
    if (!this.tourOptional.price || (this.tourOptional.pay_online && !this.tourOptional.currency)) {
      this.price = null;
      this.ext_price = null;
      return;
    }
    if (!this.tourOptional.currency) {
      this.tourOptional.currency = this.tourOptional.price.replace(/[\d.-]/g, '');
    }
    this.price = +this.tourOptional.price.replace(',', '.').replace(/[^\d.-]/g, '');
    this.fix_pln = 1.2 + (this.samePrice / 100);
    if (this.price >= 100) {
      this.per_pln = 1.0455;
    } else {
      this.per_pln = 1.0566;
    }
    this.calcPrice();
  }

  calcPrice() {
    this.ext_price = this.roundToTwo(this.fix_pln + (this.price * this.per_pln));
    this.stripe_fee = this.roundToTwo(0.3 + (this.price * 0.034));
    this.pln_fee = this.roundToTwo(this.ext_price - this.price - this.stripe_fee);
    // const td_gets = this.ext_price - this.stripe_fee - this.pln_fee;
    // console.log('\n\n**************** NEW CALC ********************');
    // console.log('og', this.price);
    // console.log('pr', this.ext_price);
    // console.log('st', this.stripe_fee, '%', this.roundToTwo(this.stripe_fee * 100 / this.ext_price));
    // console.log('pl', this.pln_fee, '%', this.roundToTwo(this.pln_fee * 100 / this.ext_price));
    // console.log('td', td_gets, '%', this.roundToTwo(td_gets * 100 / this.price));
  }

  roundToTwo(num) {
    return +(Math.round(Number(num + 'e+2')) + 'e-2');
  }

  getTourOptsForPax() {
    this.loadingOpts = true;
    this.httpTOSvc.getTourPax(this.currentUser.id, +this.tour_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tourPax = res.results.tour_pax;
            if (this.tourPax.length < 1) {
              this.snackSvc.openSnackBar('Please select a tour');
              this.router.navigate(['home'], {queryParams: {t: 'o'}});
              return;
            }
            this.tourOptionals = res.results.tour_optionals.sort(
              (a, b) => a.component['start_date'] > b.component['start_date'] ? 1 : a.component['start_date'] === b.component['start_date'] ? 0 : -1);
          } else {
            this.snackSvc.openSnackBar('Error. Getting optionals');
          }
          this.loadingOpts = false;
        },
        error => {
          console.log(error);
          this.loadingOpts = false;
          this.snackSvc.openSnackBar('ERROR. Getting optionals');
        });
  }

  getTourOpt(to_id) {
    this.loading = true;
    this.httpTOSvc.getTourOpt(to_id, this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            if (res.results.signed_up) {
              this.router.navigate(['optional_activity/top/' + res.results.signed_up.id]);
              return;
            }
            this.tourOptional = res.results.to;
            this.fixPrice();
            this.titleSvc.setTitle(this.tourOptional.to_name);
          } else {
            this.router.navigate(['optional_activity']);
            this.snackSvc.openSnackBar('ERROR. Tour optional not found');
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Getting information');
        });
  }

  getTourOptPax(top_id) {
    this.loading = true;
    this.httpTOSvc.getTourOptionalPax(top_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tourOptional = res.results.top;
            this.samePrice = res.results.same_price_fee;
            if (this.tourOptional.user_id !== this.currentUser.id) {
              this.router.navigate(['optional_activity']);
              this.snackSvc.openSnackBar('ERROR. Not your Optional');
              return;
            }
            this.fixPrice();
            this.titleSvc.setTitle(this.tourOptional.to_name);
            this.getTourOptPay(top_id);
          } else {
            this.snackSvc.openSnackBar('Error. User not signed up');
            this.router.navigate(['optional_activity']);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('ERROR. Getting information');
        });
  }

  getTourOptPay(top_id) {
    this.payment_loading = true;
    this.httpTOSvc.getTourOptionalPay(top_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.order = res.results.order;
            this.payment_intent = res.results.payment_intent;
            this.titleSvc.setTitle(this.tourOptional.to_name);
            this.fixPrice();
            if (this.tourOptional.user_id !== this.currentUser.id) {
              this.router.navigate(['optional_activity']);
              this.snackSvc.openSnackBar('ERROR. Not your Optional');
              return;
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.payment_loading = false;
        },
        error => {
          console.log(error);
          this.payment_loading = false;
          this.snackSvc.openSnackBar('ERROR. Getting information');
        });
  }

  verifySession(sessionId) {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': sessionId,
    };
    this.httpOrder.verifyStripeSession(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const verified = res.results.verified;
            if (verified) {
              this.snackSvc.openSnackBar('Payment successful!');
            } else {
              this.snackSvc.openSnackBar('Payment ok but not verified. Contact support');
            }
          } else {
            this.snackSvc.openSnackBar('ERROR. Verifying payment');
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Verifying payment');
        });
  }

  /*
/* STRIPE
*******************/

  createCheckout() {
    if (!this.ext_price || !this.price) {
      this.snackSvc.openSnackBar('Error(3) Contact your Tour Leader');
      return;
    }
    this.pushed_pay = true;
    const data = {
      'user_id': this.currentUser.id,
      'top_id': this.topId,
      'amount': this.roundToTwo(this.ext_price * 100),
      'fee': this.roundToTwo((this.stripe_fee + this.pln_fee) * 100),
      'currency_code': this.tourOptional.currency ? this.tourOptional.currency : 'EUR',
      'success_url': window.location.origin + '/optional_activity/top/' + this.topId,
      'cancel_url': window.location.origin + '/optional_activity/top/' + this.topId,
      'returnUrl': null,
    };
    // console.log(data);
    this.httpOrder.setStripeTopExpCheckout(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            window.open(res.results.checkout_session.url, '_self');
          } else {
            this.pushed_pay = false;
            this.snackSvc.openSnackBar('Error(6). Contact your Tour Director');
          }
        },
        error => {
          this.pushed_pay = false;
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, '' + error, '');
          this.snackSvc.openSnackBar('Error(7). Contact your Tour Director');
        });
  }

  resumeSession(order: Orders) {
    if (!this.tourOptional.paid_at) {
      this.stripe.redirectToCheckout({sessionId: order.stripe.session_id})
        .then(ref => {
          console.log(ref);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.snackSvc.openSnackBar('Optional already paid');
    }
  }

  onGoToTourOpt(top) {
    this.router.navigate(['optional_activity/to/' + top.tour_optional_id]);
  }

  onSignUp(to) {
    this.loading = true;
    const params = {
      'tour_pax_id': to.tp_id,
      'tour_optional_id': to.to_id,
      'url': environment.selfUrl,
      'app': true,
    };
    // console.log(params);
    this.httpTOSvc.createTourOptPax(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loading = false;
          if (res.status < 400) {
            this.router.navigate(['optional_activity/top/' + res.results.top.id]);
          } else {
            if (res.status === 405) {
              this.snackSvc.openSnackBar('Not allowed. Contact Tour leader');
            } else {
              this.flagService.setFlag(this.currentUser.id, this.router.url, 'ERROR. Signing up. ' + JSON.stringify(to), JSON.stringify(res));
              this.snackSvc.openSnackBar('ERROR. Signing up');
            }
          }
        },
        error => {
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, 'ERROR. Signing up. ' + JSON.stringify(to), JSON.stringify(error));
          this.snackSvc.openSnackBar('ERROR. Signing up');
          this.loading = false;
        });
  }

  onSignOut(top) {
    if (!this.tourOptional.paid_at) {
      this.httpTOSvc.deleteTourOptPax(top.tp_id, top.to_id, 1)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              this.router.navigate(['optional_activity']);
            } else {
              if (res.status === 405) {
                this.snackSvc.openSnackBar('Not allowed. Contact Tour leader');
              } else {
                this.snackSvc.openSnackBar('ERROR. Signing out');
              }
            }
          },
          error => {
            console.log(error);
          });
    } else {
      this.snackSvc.openSnackBar('Already paid. Contact Tour Leader');
    }
  }

  onLogout() {
    this.titleSvc.setTitle('Planafy');
    this.authSvc.logout();
    this.router.navigate(['login/pax']);
  }

  onGoHome() {
    this.router.navigate(['home']);
  }

  onGoBack() {
    this.titleSvc.setTitle('Tour Optionals');
    this.router.navigate(['optional_activity']);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
