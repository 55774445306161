import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {User} from '../../../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {NewtourService} from '../../services/newtour.service';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpTourService} from '../../../../../../shared/services/http/http-tour.service';

@Component({
  selector: 'app-manually-create',
  templateUrl: './manually-create.component.html',
  styleUrls: ['./manually-create.component.scss']
})
export class ManuallyCreateComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;

  loading = false;
  code_holder = 'XXXB';
  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;
  sa = false;

  returnUrl: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private newTourSvc: NewtourService,
    private snackSvc: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private httpTour: HttpTourService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.ub = this.activeTour.tour.company_id === 2;
        this.et = this.activeTour.tour.company_id === 3;
        this.ad = this.activeTour.tour.company_id === 4;
        this.gy = this.activeTour.tour.company_id === 5;
        this.ga = this.activeTour.tour.company_id === 6;
        this.sa = this.activeTour.tour.company_id === 7;
      });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    if (this.ub) {
      this.code_holder = 'XXXB';
    } else if (this.et) {
      this.code_holder = 'XXX';
    } else if (this.ad) {
      this.code_holder = 'XXXD';
    } else if (this.ga) {
      this.code_holder = 'XXXG';
    } else if (this.sa) {
      this.code_holder = 'XXXA';
    } else {
      this.code_holder = 'XXXX';
    }
  }

  setUpload() {
    this.newTourSvc.setManually(false);
  }

  codeUpperCase() {
    this.activeTour.tour.code = this.activeTour.tour.code.toUpperCase();
  }

  onOK() {
    if (!this.activeTour.tour.prodid) {
      this.snackSvc.openSnackBar('Prod id needed');
      return;
    }
    if (!this.activeTour.tour.code) {
      this.snackSvc.openSnackBar('Code needed');
      return;
    }
    if (!this.activeTour.tour.name) {
      this.snackSvc.openSnackBar('Tour name needed');
      return;
    }
    this.loading = true;
    const params = {
      'user_id': this.currentUser.id,
      'company_id': this.activeTour.tour.company_id,
      'prodid': this.activeTour.tour.prodid,
      'code': this.activeTour.tour.code,
      'name': this.activeTour.tour.name,
      'price_pday': 400,
    };
    // console.log(params);
    this.httpTour.createTour(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.activeTour.tour = res.results.tour;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.activeTourSvc.addTour2User(res.results.tour);
            this.router.navigate(['pax', this.activeTour.tour.prodid]);
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
