import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../../shared/services/common/title.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {Activetour} from '../../../../shared/models/activetour.model';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {environment} from '../../../../../environments/environment';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-home-pax',
  templateUrl: './home-pax.component.html',
  styleUrls: ['./home-pax.component.scss']
})
export class HomePaxComponent implements OnInit, OnDestroy {
  currentUser: User;
  activeTour: Activetour;
  tour_pax: TourPax;
  tours_pax: TourPax[] = [];

  loadingTours = false;
  baseUrlLogo: string;
  where: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activeTourSvc: ActivetourService,
    private authSvc: AuthenticationService,
    private httpTour: HttpTourService,
    private titleSvc: TitleService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.titleSvc.setTitle('Planafy');
  }

  ngOnInit(): void {
    if (this.currentUser.role > User.pax_admin) {
      this.snackSvc.openSnackBar('Access here as PAX, not TD');
      this.router.navigate(['tours']);
      return;
    }
    // If TD logs in as PAX
    this.activeTourSvc.setActiveTour(null);
    if (this.currentUser && this.currentUser.role >= User.ef) {
      this.snackSvc.openSnackBar('Login as TD');
      this.authSvc.logout();
      window.location.href = environment.selfUrl + '/login';
      return;
    }
    this.baseUrlLogo = environment.baseUrl;
    this.where = this.route.snapshot.queryParams['t'] || null;
    this.getToursPax();
  }

  getToursPax() {
    this.loadingTours = true;
    this.httpTour.getToursPax(this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.tours_pax = res.results.tours;
            this.tours_pax.map(tu => {
              tu.tour.cover = tu.tour.cover.includes('https') ? tu.tour.cover : environment.baseUrl + tu.tour.cover;
              tu.tour.logo = environment.baseUrl + tu.tour.logo;
            });
            this.activeTourSvc.setToursPax(this.tours_pax);
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTours = false;
        },
        error => {
          console.log(error);
          this.loadingTours = false;
        });
  }

  onClickTour(tour_pax: TourPax) {
    this.tour_pax = tour_pax;
    const tour = this.tours_pax.find(tp => '' + tp.id === '' + tour_pax.id).tour;
    this.activeTour = new Activetour();
    if (tour) {
      this.activeTour.tour = tour;
    }
    this.activeTourSvc.setActiveTour(this.activeTour);
    const tourIdBtoa = btoa('' + tour_pax.tour.id).replace(/=/g, '');
    if (this.where === 'a') {
      this.router.navigate(['appreciation', tourIdBtoa]);
    } else {
      this.router.navigate(['optional_activity', tourIdBtoa]);
    }
  }

  onLogout() {
    this.titleSvc.setTitle('Planafy');
    this.authSvc.logout();
    this.router.navigate(['login/pax']);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
