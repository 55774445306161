import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpOptionalService} from '../../../../shared/services/http/http-optional.service';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../../../shared/models/company.model';
import {takeUntil} from 'rxjs/operators';
import {Tour} from '../../../../shared/models/tour.model';
import {Router} from '@angular/router';
import {Optional} from '../../../../shared/models/optional.model';

export interface AddOptData {
  req_id: number;
  city_name: string;
  tour: Tour;
  city_id: number;
  reason: string;
  optional: Optional;
  suggOpts: any;
}

@Component({
  selector: 'app-add-optional',
  templateUrl: './add-optional.component.html',
  styleUrls: ['./add-optional.component.scss']
})
export class AddOptionalComponent implements OnInit, OnDestroy {
  loading = false;
  companies: Company[];
  optionals: Optional[] = [];
  cities: any[] = [];
  searchText: string;

  show_cia = false;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httpOptSvc: HttpOptionalService,
    private httpCitySvc: HttpCityService,
    private snackSvc: SnackbarService,
    private router: Router,
    public dialogRef: MatDialogRef<AddOptionalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddOptData) {
  }

  ngOnInit(): void {
    this.companies = JSON.parse(localStorage.getItem('companies'));
    this.companies = this.companies.filter(c => c.id !== 1);
    this.cities = JSON.parse(localStorage.getItem('cities'));
    this.optionals = this.data.suggOpts;
    this.searchText = this.data.city_name;
    this.show_cia = this.data.req_id === 2;
    this.getAllOptionals();
  }

  getAllOptionals() {
    this.loading = true;
    this.httpOptSvc.getAllOptionals(this.data.req_id, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const newOptionals = res.results.optionals;
            this.optionals = [...this.optionals, ...newOptionals].filter(
              (value, index, self) => self.findIndex(v => v.id === value.id) === index
            );
            this.optionals.map(o => {
              o.company_ids = o.companies.replace(/^,*/, '').replace(/,*$/, '').split(',').map(i => +i);
              o.cia = '';
              if (o.company_ids.includes(1)) {
                o.cia += 'PL,';
              }
              if (o.company_ids.includes(2)) {
                o.cia += 'UB,';
              }
              if (o.company_ids.includes(3)) {
                o.cia += 'ET,';
              }
              if (o.company_ids.includes(4)) {
                o.cia += 'ADV,';
              }
              if (o.company_ids.includes(5)) {
                o.cia += 'GY,';
              }
              if (o.company_ids.includes(6)) {
                o.cia += 'GAT,';
              }
              if (o.company_ids.includes(7)) {
                o.cia += 'SA,';
              }
              o.cia = o.cia.replace(/^,*/, '').replace(/,*$/, '');
              if (o.cia === '') {
                o.cia = null;
              }
            });
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  get filteredItems() {
    return this.optionals.filter(item => {
      const searchText = this.searchText.length >= 3;
      const nameMatch = item.name.toLowerCase().includes(this.searchText.toLowerCase());
      const cityMatch = item.city_name.toLowerCase().includes(this.searchText.toLowerCase());
      const supplierMatch = item.supplier
        ? item.supplier.toLowerCase().includes(this.searchText.toLowerCase())
        : false;
      return searchText && (nameMatch || cityMatch || supplierMatch);
    });
  }

  onAddOptional(opt) {
    if (!this.data.tour.tour_settings.paid_at) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to add TD Opts', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.close('tbp');
            this.router.navigate(['summary', this.data.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
          }
        });
      return;
    }
    this.data.optional = opt;
    this.close('ok');
  }

  onCreateNew() {
    this.close('new');
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
