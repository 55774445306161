import {Component, EventEmitter, HostListener, Inject, OnDestroy} from '@angular/core';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {Subject} from 'rxjs';
import {User} from '../../../../shared/models/user.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Clipboard} from '@angular/cdk/clipboard';
import {TourGroup} from '../../../../shared/models/tour-group.model';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {Activetour} from '../../../../shared/models/activetour.model';

export interface DialogDataAddPax {
  user: User;
  activeTour: Activetour;
  room: string;
  et: boolean;
  gl: TourGroup;
  tab: number;
  reason: string;
}

@Component({
  selector: 'app-add-pax',
  templateUrl: './add-pax.component.html',
  styleUrls: ['./add-pax.component.scss']
})
export class AddPaxComponent implements OnDestroy {
  name: string = null;
  email: string = null;
  password: string = null;
  gender: string = null;
  loading = false;
  reason: string;
  snackbarRef: any = null;
  dummy_pax = true;
  ok_copied = false;
  show_mf = false;
  selfUrl: string;
  file: any;
  loadingPDF = false;
  et = false;
  activeTabIndex = 0;
  errTxt: string;

  private onDestroy$ = new Subject<boolean>();
  roomUploaded: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private httpUser: HttpUserService,
    private httpTour: HttpTourService,
    private http: HttpClient,
    private clipboard: Clipboard,
    private snackSvc: SnackbarService,
    public dialogRef: MatDialogRef<AddPaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddPax) {
    this.selfUrl = environment.selfUrl;
    if (this.data.tab) {
      this.activeTabIndex = +this.data.tab;
    }
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Enter') {
      this.submit();
    }
  }

  submit(): void {
    this.errTxt = null;
    if (!this.name) {
      this.errTxt = 'Insert name';
      return;
    }
    if (!this.email) {
      this.errTxt = 'Insert email';
      return;
    }
    if (!this.gender) {
      this.errTxt = 'Choose gender';
      return;
    }
    this.generatePassword();
    this.createPax();
  }

  changeName() {
    if (this.dummy_pax) {
      this.email = this.name.toLowerCase().replace(/ /g, '.').replace(/[^\w.]+/g, '') + '@dummypax.planafy';
    }
  }

  dummyPax() {
    this.dummy_pax = !this.dummy_pax;
    this.password = Array(10).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$').map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }).join('');
    if (this.dummy_pax) {
      this.email = (this.name ? this.name.toLowerCase().replace(/ /g, '.').replace(/[^\w.]+/g, '') : '') + '@dummypax.planafy';
    } else {
      this.email = null;
    }
  }

  selGen(new_gen) {
    this.errTxt = null;
    if (this.gender === new_gen) {
      this.gender = null;
    } else {
      if (new_gen === 'female') {
        this.gender = 'female';
      } else if (new_gen === 'male') {
        this.gender = 'male';
      } else if (new_gen === 'x') {
        this.gender = 'x';
      } else {
        this.gender = null;
      }
    }
  }

  selRoom(new_room) {
    if (this.data.room === new_room) {
      this.data.room = null;
    } else {
      if (new_room === 'St') {
        this.data.room = 'St';
      } else if (new_room === 'Si') {
        this.data.room = 'Si';
      } else if (new_room === 'Tw') {
        this.data.room = 'Tw';
      } else if (new_room === 'Db') {
        this.data.room = 'Db';
      } else {
        this.data.room = null;
      }
    }
  }

  generatePassword() {
    this.password = Array(10).fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@-#$').map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    }).join('');
    this.clipboard.copy(this.password);
    this.notify();
  }

  generateUser(gender) {
    this.loading = true;
    this.http.get('https://randomuser.me/api/?inc=name,email&nat=us&gender=' + gender).subscribe(
      next => {
        this.name = next['results'][0].name.first + ' ' + next['results'][0].name.last;
        this.email = this.dummy_pax ? next['results'][0].email.replace('example', 'dummypax.planafy') : next['results'][0].email;
        this.gender = gender;
        this.generatePassword();
        this.loading = false;
      }, error => {
        this.loading = false;
        console.log(error);
        const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
        let string = '';
        for (let ii = 0; ii < 15; ii++) {
          string += chars[Math.floor(Math.random() * chars.length)];
        }
        this.name = string;
        this.email = string + (!this.dummy_pax ? '@example.com' : '@dummypax.planafy');
        this.gender = (Math.floor(Math.random() * 2) === 0) ? 'male' : 'female';
        this.generatePassword();
      }
    );
  }

  notify() {
    this.ok_copied = true;
    setTimeout(() => {
      this.ok_copied = false;
    }, 1000);
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  selectGL(tg) {
    this.data.gl = tg;
  }

  createPax() {
    this.errTxt = null;
    if (!this.validateEmail(this.email)) {
      this.errTxt = 'Invalid email';
      return;
    }
    if (this.email.includes('@dummypax.planafy')) {
      const user = new User();
      user.name = this.name;
      user.gender = this.gender;
      this.data.user = user;
      this.close('ok');
    }
    this.loading = true;
    const data = {
      'name': this.name,
      'email': this.email,
      'password': this.password,
      'gender': this.gender,
    };
    // console.log(data);
    this.httpUser.createUser(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.user = res.results;
            this.close('ok');
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.snackSvc.openSnackBar('Error creating user');
        });
  }

  // Upload file
  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
    if (this.activeTabIndex === 0) {

    } else if (this.activeTabIndex === 1) {

    }
  }

  onFileDropped($event) {
    this.file = null;
    this.prepareFilesList($event);
  }

  prepareFilesList(file) {
    if (this.loadingPDF) {
      return;
    }
    if (!file[0].type.includes('pdf')) {
      this.snackSvc.openSnackBar('File must be a pdf');
      return;
    }
    this.file = file[0];
    this.uploadRoom();
  }

  uploadRoom() {
    this.loadingPDF = true;
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('user_id', '' + this.data.user.id);
    formData.append('tour_id', '' + this.data.activeTour.tour.id);
    // console.log(formData);
    this.httpTour.uploadPAX(formData)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.roomUploaded.emit(res);
            this.close('close');
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingPDF = false;
        },
        error => {
          this.loadingPDF = false;
          console.log(error);
          this.snackSvc.openSnackBar(error);
        });
  }

  close(reason) {
    if (reason === 'close' && this.snackbarRef) {
      this.snackbarRef.dismiss();
    }
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
