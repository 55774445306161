import {Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {HttpTourService} from '../../../../shared/services/http/http-tour.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {TourPax} from '../../../../shared/models/tour-pax.model';
import {environment} from '../../../../../environments/environment';
import {HttpSessionService} from '../../../../shared/services/http/http-session.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {Activetour} from '../../../../shared/models/activetour.model';
import {TourGroup, TourpaxGroup} from '../../../../shared/models/tour-group.model';

export interface DialogDataEditPax {
  tourpax: TourPax;
  req_id: number;
  cia_id: number;
  groups: TourGroup[];
  reason: string;
}

@Component({
  selector: 'app-edit-pax',
  templateUrl: './edit-pax.component.html',
  styleUrls: ['./edit-pax.component.scss']
})
export class EditPaxComponent implements OnInit, OnDestroy {
  activeTour: Activetour;

  loadingTourPax = false;
  et = false;
  tokenResetPwd: string;
  name: string;
  gender: string;
  room: string;
  admin: boolean;
  active: boolean;
  hidden: boolean;
  fake: boolean;
  busDriver: boolean;
  show_join_ago = true;
  show_join_ago_txt: string;
  show_last_ago = true;
  show_last_ago_txt: string;
  interval: any;
  colorOptions: string[] = [
    '#9999FF', // Ube
    '#FBDC70', // Butter
    '#F05E40', // Aperol
    '#8ADDCD', // Mojito
  ];

  private onDestroy$ = new Subject<boolean>();
  updateUser: EventEmitter<any> = new EventEmitter<any>();
  updateTourpax: EventEmitter<any> = new EventEmitter<any>();
  colorChanged: EventEmitter<any> = new EventEmitter<any>();
  deleteTourGroup: EventEmitter<any> = new EventEmitter<any>();
  deleteTP: EventEmitter<any> = new EventEmitter<any>();
  updateTPGroup: EventEmitter<any> = new EventEmitter<any>();
  add2TPGroup: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialogRef: MatDialogRef<EditPaxComponent>,
              private httpUser: HttpUserService,
              private httpTour: HttpTourService,
              private httpSession: HttpSessionService,
              private activeTourSvc: ActivetourService,
              private snackSvc: SnackbarService,
              @Inject(MAT_DIALOG_DATA) public data: DialogDataEditPax) {
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.et = tour?.tour.company_id === 3;
      });
    this.name = this.data.tourpax.user_id ? this.data.tourpax.user.name : this.data.tourpax.tp_name;
    this.gender = this.data.tourpax.user_id ? this.data.tourpax.user.gender : this.data.tourpax.tp_gender;
    this.room = this.data.tourpax.room;
    this.active = this.data.tourpax.active;
    this.hidden = this.data.tourpax.hidden;
    this.fake = !this.data.tourpax.user_id;
    this.admin = this.data.tourpax.user?.role === 2;
    this.busDriver = this.data.tourpax.user?.name === 'Bus Driver';
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Enter') {
      this.onTourPaxChange();
    }
  }

  calcTime() {
    let time_amount;
    let time_units;
    const startDate = new Date(this.data.tourpax.updated_at);
    const endDate = new Date();
    let seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 30)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24 * 30) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.floor(seconds / (60 * 60 * 24 * 30));
      time_units = 'month' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_last_ago_txt = time_amount + ' ' + time_units + ' ago';
    const startDate2 = new Date(this.data.tourpax.created_at);
    const endDate2 = new Date();
    seconds = (endDate2.getTime() - startDate2.getTime()) / 1000;
    if (seconds < 60) {
      time_amount = Math.floor(seconds);
      time_units = 'second' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= 60 && seconds < (60 * 60)) {
      time_amount = Math.floor(seconds / (60));
      time_units = 'minute' + (time_amount !== 1 ? 's' : '');
    } else if (seconds > (60 * 60) && seconds < (60 * 60 * 24)) {
      time_amount = Math.floor(seconds / (60 * 60));
      time_units = 'hour' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24) && seconds < (60 * 60 * 24 * 30)) {
      time_amount = Math.ceil(seconds / (60 * 60 * 24));
      time_units = 'day' + (time_amount !== 1 ? 's' : '');
    } else if (seconds >= (60 * 60 * 24 * 30) && seconds < (60 * 60 * 24 * 365)) {
      time_amount = Math.floor(seconds / (60 * 60 * 24 * 30));
      time_units = 'month' + (time_amount !== 1 ? 's' : '');
    } else {
      time_amount = Math.ceil(seconds / (60 * 60 * 24 * 365));
      time_units = 'year' + (time_amount !== 1 ? 's' : '');
    }
    this.show_join_ago_txt = time_amount + ' ' + time_units + ' ago';
  }

  ngOnInit(): void {
    this.calcTime();
    this.interval = setInterval(() => {
      this.calcTime();
    }, 1000);
  }

  recentOn(upd) {
    const min = 4; // 3min
    return new Date(upd).getTime() + min * 60 * 1000 >= new Date().getTime();
  }

  showJoinTime() {
    this.show_join_ago = false;
    setTimeout(() => {
      this.show_join_ago = true;
    }, 5000);
  }

  showLastTime() {
    this.show_last_ago = false;
    setTimeout(() => {
      this.show_last_ago = true;
    }, 5000);
  }

  onName() {
    this.onTourPaxChange();
  }

  onAdmin() {
    if (this.admin) {
      !this.busDriver ? this.admin = false : this.admin = true;
    } else {
      this.admin = true;
    }
    this.onTourPaxChange();
  }

  onGender(e) {
    this.onTourPaxChange();
  }

  onRoom(e) {
    this.onTourPaxChange();
  }

  onHide() {
    this.hidden = !this.hidden;
    this.onTourPaxChange();
  }

  onBlock() {
    this.active = !this.active;
    this.onTourPaxChange();
  }

  onDeletePax() {
    this.deleteTP.emit(this.data.tourpax);
    this.close('close');
  }

  onTourPaxChange(close?) {
    this.loadingTourPax = true;
    const data = {
      'req_id': this.data.req_id,
      'tp_name': this.name,
      'tp_gender': this.gender,
      'role': this.admin ? 2 : 1,
      'room': this.room,
      'hidden': this.hidden,
      'active': this.active,
    };
    // console.log(data);
    this.httpTour.updateTourPax(this.data.tourpax.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loadingTourPax = false;
          if (res.status < 400) {
            this.data.tourpax = res.results.tp;
            this.updateTourpax.emit(res.results.tp);
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.loadingTourPax = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating profile');
        });
  }

  async sendTokenAsync() {
    this.loadingTourPax = true;
    const res = await this.httpSession.requestPasswordToken({email: this.data.tourpax.user.email, url: environment.selfUrl});
    console.log(res);
    if (res.status < 400) {
      this.tokenResetPwd = res.results;
      this.snackSvc.openSnackBar('Email sent');
      this.loadingTourPax = false;
    } else {
      this.snackSvc.openSnackBar('Error');
      this.loadingTourPax = false;
    }
  }

  // Tour group

  selectGL(tg) {
    if (!this.data.tourpax.tourpax_group) {
      this.add2TPGroup.emit(tg);
      if (tg) {
        this.data.tourpax.tourpax_group = new TourpaxGroup();
        this.data.tourpax.tourpax_group.tour_group = tg;
        this.data.tourpax.tourpax_group.tour_group_id = tg.id;
      }
    } else {
      this.data.tourpax.tourpax_group.tour_group = tg;
      this.updateTPGroup.emit(this.data.tourpax);
      if (!tg) {
        this.data.tourpax.tourpax_group = null;
      }
    }
  }

  onToggleGL() {
    if (this.data.tourpax.gl_tg) {
      this.onDeleteTourgroup();
    } else {
      this.onCreateTourgroup();
    }
  }

  async onCreateTourgroup() {
    this.loadingTourPax = true;
    const getInitials = (name: string): string => {
      const matches = name.match(/\b\p{L}/gu) || [];
      return matches.join('').toUpperCase();
    };
    const data = {
      'tour_id': this.activeTour.tour.id,
      'name': this.data.tourpax.user?.avatar || getInitials(this.data.tourpax.tp_name),
      'gl_id': this.data.tourpax.id,
    };
    const res = await this.httpTour.createTourGroup(data);
    console.log(res);
    if (res.status < 400) {
      const groupExists = this.activeTour.groups.some(group => group.id === res.results.tour_group.id);
      if (!groupExists) {
        this.data.tourpax.gl_tg = res.results.tour_group;
        this.activeTour.groups.push(res.results.tour_group);
        this.activeTourSvc.setActiveTour(this.activeTour);
        delete res.results.tour_group.tour;
        delete res.results.tour_group.gl;
        this.data.tourpax.tourpax_group = res.results.tour_group.tourpax_group[0];
        delete res.results.tour_group.tourpax_group;
        this.data.tourpax.tourpax_group.tour_group = res.results.tour_group;
      }
      this.loadingTourPax = false;
    } else {
      this.loadingTourPax = false;
      this.snackSvc.resultsElse(res);
    }
  }

  onColorSelect(color: string): void {
    this.data.tourpax.gl_tg.color = this.data.tourpax.gl_tg.color === color ? null : color;
    const data = {
      color: this.data.tourpax.gl_tg.color
    };
    this.colorChanged.emit([this.data.tourpax.gl_tg, data.color]);
    this.onUpdateTourgroup(this.data.tourpax.gl_tg, data);
  }

  async onUpdateTourgroup(tg: TourGroup, data) {
    this.loadingTourPax = true;
    // console.log(data);
    const res = await this.httpTour.updateTourGroup(tg.id, data);
    console.log(res);
    if (res.status < 400) {
      this.data.tourpax.gl_tg = res.results.tour_group;
      this.loadingTourPax = false;
    } else {
      this.loadingTourPax = false;
      this.snackSvc.resultsElse(res);
    }
  }

  async onDeleteTourgroup() {
    this.loadingTourPax = true;
    const res = await this.httpTour.deleteTourGroup(this.data.tourpax.gl_tg.id);
    console.log(res);
    if (res.status < 400) {
      const groupExists = this.activeTour.groups.filter(group => group.id === this.data.tourpax.gl_tg.id);
      if (groupExists.length > 0) {
        this.activeTour.groups = this.activeTour.groups.filter(group => group.id !== this.data.tourpax.gl_tg.id);
        this.data.groups = this.activeTour.groups;
        this.activeTourSvc.setActiveTour(this.activeTour);
        this.deleteTourGroup.emit(this.data.tourpax.gl_tg);
        this.data.tourpax.gl_tg = null;
        this.data.tourpax.tourpax_group = null;
      }
      this.loadingTourPax = false;
    } else {
      this.loadingTourPax = false;
      this.snackSvc.resultsElse(res);
    }
  }

  onDeleteUser() {
    if (this.fake) {
      this.deleteTP.emit(this.data.tourpax);
      this.close('close');
      return;
    }
    this.loadingTourPax = true;
    this.httpUser.deleteUser(this.data.tourpax.user.id, this.data.req_id, 'true')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.close('delete');
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
          this.loadingTourPax = false;
        },
        error => {
          console.log(error);
          this.loadingTourPax = false;
          this.snackSvc.openSnackBar('Error deleting user');
        });
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
