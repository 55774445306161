import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Activetour} from '../../../../../../shared/models/activetour.model';
import {User} from '../../../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../../shared/services/session/authentication.service';
import {ActivetourService} from '../../../../../../shared/services/session/activetour.service';
import {NewtourService} from '../../services/newtour.service';
import {SnackbarService} from '../../../../../../shared/services/common/snackbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlagService} from '../../../../../../shared/services/common/flag.service';
import {HttpTourService} from '../../../../../../shared/services/http/http-tour.service';
import {takeUntil} from 'rxjs/operators';
import {TitleService} from '../../../../../../shared/services/common/title.service';

@Component({
  selector: 'app-select-pax',
  templateUrl: './select-pax.component.html',
  styleUrls: ['./select-pax.component.scss']
})
export class SelectPaxComponent implements OnInit, OnDestroy {
  // Active tour info
  activeTour: Activetour;
  currentUser: User;
  returnUrl: string;
  prod_id: string;

  loading = false;
  ub = false;
  et = false;
  gy = false;
  ad = false;
  ga = false;
  sa = false;
  plus = false;
  add_trs = false;
  add_qds = false;
  max: number;

  sliderFem: any = {max: 0, value: 0};
  addSdisable: boolean;
  subSdisable: boolean;
  addDisable: boolean;
  subDisable: boolean;
  addTDisable: boolean;
  subTDisable: boolean;
  addTrDisable: boolean;
  subTrDisable: boolean;
  addQDisable: boolean;
  subQDisable: boolean;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private newTourSvc: NewtourService,
    private snackSvc: SnackbarService,
    private titleSvc: TitleService,
    private route: ActivatedRoute,
    private flagService: FlagService,
    private router: Router,
    private httpTour: HttpTourService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.ub = this.activeTour.tour.company_id === 2;
        this.et = this.activeTour.tour.company_id === 3;
        this.ad = this.activeTour.tour.company_id === 4;
        this.gy = this.activeTour.tour.company_id === 5;
        this.ga = this.activeTour.tour.company_id === 6;
        this.sa = this.activeTour.tour.company_id === 7;
      });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    this.titleSvc.setTitle('Pax info');
  }

  ngOnInit(): void {
    this.prod_id = this.route.snapshot.params['tour-prodid'];

    if (this.prod_id && !this.activeTour.tour.id) {
      const tour = this.activeTourSvc.getTourUser(this.prod_id);
      if (tour) {
        this.activeTour.tour = tour;
        this.activeTourSvc.setActiveTour(this.activeTour);
      } else {
        this.router.navigate(['new']);
      }
    }
    this.setData();
  }

  setData() {
    this.activeTour.tour.npax = (this.activeTour.tour.npax) ? this.activeTour.tour.npax : 0;
    this.activeTour.tour.rms.sin = (this.activeTour.tour.rms.sin) ? this.activeTour.tour.rms.sin : 0;
    this.activeTour.tour.rms.dbl = (this.activeTour.tour.rms.dbl) ? this.activeTour.tour.rms.dbl : 0;
    this.activeTour.tour.rms.twn = (this.activeTour.tour.rms.twn) ? this.activeTour.tour.rms.twn : 0;
    this.activeTour.tour.rms.tri = (this.activeTour.tour.rms.tri) ? this.activeTour.tour.rms.tri : 0;
    this.activeTour.tour.rms.qad = (this.activeTour.tour.rms.qad) ? this.activeTour.tour.rms.qad : 0;
    this.activeTour.tour.rms.fem = (this.activeTour.tour.rms.fem) ? this.activeTour.tour.rms.fem : 0;
    this.activeTour.tour.rms.mal = (this.activeTour.tour.rms.mal) ? this.activeTour.tour.rms.mal : 0;
    this.sliderFem.max = this.activeTour.tour.npax - (this.activeTour.tour.rms.qad) * 4 - (this.activeTour.tour.rms.tri) * 3 -
      (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 - this.activeTour.tour.rms.sin;
    this.sliderFem.value = this.activeTour.tour.rms.fem;
    // Disable sub if min
    if (this.activeTour.tour.rms.twn === 0) {
      this.subTDisable = true;
    }
    if (this.activeTour.tour.rms.dbl === 0) {
      this.subDisable = true;
    }
    if (this.activeTour.tour.rms.sin === 0) {
      this.subSdisable = true;
    }
    if (this.activeTour.tour.rms.tri === 0) {
      this.subTrDisable = true;
    }
    if (this.activeTour.tour.rms.qad === 0) {
      this.subQDisable = true;
    }
    // Disable add qads if max
    if (this.activeTour.tour.rms.sin + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 +
      (this.activeTour.tour.rms.tri) * 3 + (this.activeTour.tour.rms.qad) * 4 >= this.activeTour.tour.npax - 3) {
      this.addQDisable = true;
    }
    // Disable add triples if max
    if (this.activeTour.tour.rms.sin + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 +
      (this.activeTour.tour.rms.tri) * 3 + (this.activeTour.tour.rms.qad) * 4 >= this.activeTour.tour.npax - 2) {
      this.addTrDisable = true;
    }
    // Disable add doubles if max
    if (this.activeTour.tour.rms.sin + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 +
      (this.activeTour.tour.rms.tri) * 3 + (this.activeTour.tour.rms.qad) * 4 >= this.activeTour.tour.npax - 1) {
      this.addDisable = true;
    }
    // Disable add singles if max
    if ((this.activeTour.tour.rms.sin + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 +
      (this.activeTour.tour.rms.tri) * 3 + (this.activeTour.tour.rms.qad) * 4) >= this.activeTour.tour.npax) {
      this.addSdisable = true;
    }
    this.add_trs = !!this.activeTour.tour.rms.tri || !!this.activeTour.tour.rms.qad;
    this.add_qds = !!this.activeTour.tour.rms.qad;
  }

  onOKPax() {
    this.onUpdate('itin');
  }

  onUpdate(where) {
    if (!this.activeTour.tour.prodid) {
      this.snackSvc.openSnackBar('Tour ID required');
      this.router.navigate(['new']);
      return;
    }
    if (!this.activeTour.tour.npax) {
      this.snackSvc.openSnackBar('PAX info required');
      return;
    }
    if ((this.activeTour.tour.rms.sin + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 < this.activeTour.tour.npax) &&
      !this.activeTour.tour.rms.fem && !this.activeTour.tour.rms.mal) {
      this.snackSvc.openSnackBar('Error. Gender distribution needed');
      return;
    }
    this.loading = true;
    const params = {
      'req_id': this.currentUser.id,
      'tour_starts': this.activeTour.tour.tour_starts,
      'npax': this.activeTour.tour.npax,
      'nsingles': this.activeTour.tour.rms.sin,
      'ndoubles': this.activeTour.tour.rms.dbl,
      'ntwins': this.activeTour.tour.rms.twn,
      'ntriples': this.activeTour.tour.rms.tri,
      'nquads': this.activeTour.tour.rms.qad,
      'nfemales': this.activeTour.tour.rms.fem,
      'nmales': this.activeTour.tour.rms.mal,
      'create_rooms': true,
      'change_npax': true,
    };
    // console.log(params);
    this.httpTour.updateTour(this.activeTour.tour.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(res => {
        console.log(res);
        if (res.status === 203) {
          this.activeTour.tour = res.results.tour;
          this.activeTourSvc.setActiveTour(this.activeTour);
          if (this.returnUrl && this.returnUrl !== 'undefined') {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate([where, this.activeTour.tour.prodid]);
          }
        } else {
          if (res.results) {
            this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
          } else {
            console.log(res.message.toString());
          }
        }
      }, err => {
        this.loading = false;
        this.snackSvc.openSnackBar('Error. Contact support');
        this.flagService.setFlag(this.currentUser.id, this.router.url, 'Error updateTour() paxinfo', JSON.stringify(err));
        console.log(err);
      });
  }

  onCalcSlider(tot) {
    this.sliderFem.max = this.activeTour.tour.npax - tot;
    this.sliderFem.value = Math.floor(this.sliderFem.max / 2);
    this.activeTour.tour.rms.fem = Math.ceil(this.sliderFem.max / 2);
    this.activeTour.tour.rms.mal = this.sliderFem.max - this.activeTour.tour.rms.fem;
  }

  onAddPax() {
    if (this.et && this.activeTour.tour.npax >= this.max) {
      this.max = this.max + 20;
    } else {
      this.onChangePax({value: this.activeTour.tour.npax + 1});
    }
  }

  onAddToggle(tot) {
    if (tot >= this.activeTour.tour.npax - 3) {
      this.addQDisable = true;
    }
    if (tot >= this.activeTour.tour.npax - 2) {
      this.addTrDisable = true;
    }
    if (tot >= this.activeTour.tour.npax - 1) {
      this.addDisable = true;
      this.addTDisable = true;
    }
    if (tot >= this.activeTour.tour.npax) {
      this.addSdisable = true;
    }
  }

  onSubToggle(tot) {
    if (tot <= 0) {
      this.subSdisable = true;
      this.subDisable = true;
      this.subTDisable = true;
      this.subTrDisable = true;
      this.subQDisable = true;
    }
    if (tot < this.activeTour.tour.npax) {
      this.addSdisable = false;
    }
    if (tot < this.activeTour.tour.npax - 1) {
      this.addDisable = false;
      this.addTDisable = false;
    }
    if (tot < this.activeTour.tour.npax - 2) {
      this.addTrDisable = false;
    }
    if (tot < this.activeTour.tour.npax - 3) {
      this.addQDisable = false;
    }
  }

  onChangePax(event: any): void {
    this.activeTour.tour.npax = event.value;
    // Changing PAX cut females/males in half
    this.activeTour.tour.rms.fem = Math.ceil((event.value - (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn)
      * 2 - this.activeTour.tour.rms.sin) / 2);
    this.activeTour.tour.rms.mal = this.sliderFem.max - this.activeTour.tour.rms.fem;
    let tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    // If PAX change to smaller than 4 * qads + 3 * tri + 2 * db/tw + sin, restart count cutting doubles in half
    if (event.value < tot) {
      // Adjust slider for males/females
      this.sliderFem.max = 0;
      this.sliderFem.value = 0;
      this.activeTour.tour.rms.dbl = 0;
      this.activeTour.tour.rms.twn = 0;
      this.activeTour.tour.rms.sin = 0;
      this.activeTour.tour.rms.tri = 0;
      this.activeTour.tour.rms.qad = 0;
      tot = 0;
      this.subQDisable = true;
      this.subTrDisable = true;
      this.subDisable = true;
      this.subTDisable = true;
      this.subSdisable = true;
      this.addQDisable = event.value <= tot;
      this.addTrDisable = event.value <= tot;
      this.addSdisable = event.value <= tot;
      this.addDisable = event.value <= tot;
      this.addTDisable = event.value <= tot;
    } else if (event.value === tot) {
      this.addQDisable = true;
      this.addTrDisable = true;
      this.addDisable = true;
      this.addTDisable = true;
      this.addSdisable = true;
    } else {
      this.addQDisable = false;
      this.addTrDisable = false;
      this.addDisable = false;
      this.addTDisable = false;
      this.addSdisable = false;
      if (event.value === 1 + tot) {
        this.addTDisable = true;
        this.addDisable = true;
        this.addTrDisable = true;
        this.addQDisable = true;
      } else if (event.value === 2 + tot) {
        this.addTrDisable = true;
        this.addQDisable = true;
      } else if (event.value === 3 + tot) {
        this.addQDisable = true;
      }
    }
    // Adjust slider for males/females
    this.sliderFem.max = event.value - tot;
    this.sliderFem.value = Math.ceil(this.sliderFem.max / 2);
    this.activeTour.tour.rms.fem = Math.ceil(this.sliderFem.max / 2);
    this.activeTour.tour.rms.mal = this.sliderFem.max - this.activeTour.tour.rms.fem;
    if (this.activeTour.tour.npax === 0) {
      this.addDisable = true;
      this.addTDisable = true;
      this.subDisable = true;
      this.subTDisable = true;
      this.addSdisable = true;
      this.subSdisable = true;
      this.addTrDisable = true;
      this.subTrDisable = true;
      this.addQDisable = true;
      this.subQDisable = true;
    }
  }

  onChangeDist(event): void {
    this.sliderFem.value = event.value;
    this.activeTour.tour.rms.fem = event.value;
    const tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    this.activeTour.tour.rms.mal = this.activeTour.tour.npax - tot - event.value;
  }

  addQds() {
    let tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    if (tot < this.activeTour.tour.npax) {
      this.activeTour.tour.rms.qad += 1;
      tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.activeTour.tour.rms.qad > 0) {
        this.subQDisable = false;
      }
    }
  }

  subQds() {
    if (this.activeTour.tour.rms.qad > 0) {
      this.activeTour.tour.rms.qad -= 1;
      const tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.activeTour.tour.rms.qad <= 0) {
        this.subQDisable = true;
      }
    }
  }

  addTrs() {
    let tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    if (tot < this.activeTour.tour.npax) {
      this.activeTour.tour.rms.tri += 1;
      tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.activeTour.tour.rms.tri > 0) {
        this.subTrDisable = false;
      }
    }
  }

  subTrs() {
    if (this.activeTour.tour.rms.tri > 0) {
      this.activeTour.tour.rms.tri -= 1;
      const tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.activeTour.tour.rms.tri <= 0) {
        this.subTrDisable = true;
      }
    }
  }

  addDbs() {
    let tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    if (tot < this.activeTour.tour.npax - 1) {
      this.activeTour.tour.rms.dbl += 1;
      tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.activeTour.tour.rms.dbl > 0) {
        this.subDisable = false;
      }
    }
  }

  subsDbs() {
    if (this.activeTour.tour.rms.dbl > 0) {
      this.activeTour.tour.rms.dbl -= 1;
      const tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.activeTour.tour.rms.dbl <= 0) {
        this.subDisable = true;
      }
    }
  }

  addTws() {
    let tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    if (tot < this.activeTour.tour.npax - 1) {
      this.activeTour.tour.rms.twn += 1;
      tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.activeTour.tour.rms.twn > 0) {
        this.subTDisable = false;
      }
    }
  }

  subsTws() {
    if (this.activeTour.tour.rms.twn > 0) {
      this.activeTour.tour.rms.twn -= 1;
      const tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.activeTour.tour.rms.twn <= 0) {
        this.subTDisable = true;
      }
    }
  }

  addSings() {
    let tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
      + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
    if (tot < this.activeTour.tour.npax) {
      this.activeTour.tour.rms.sin += 1;
      tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onAddToggle(tot);
      if (this.activeTour.tour.rms.sin > 0) {
        this.subSdisable = false;
      }
    }
  }

  subSings() {
    if (this.activeTour.tour.rms.sin > 0) {
      this.activeTour.tour.rms.sin -= 1;
      const tot = (this.activeTour.tour.rms.qad) * 4 + (this.activeTour.tour.rms.tri) * 3
        + (this.activeTour.tour.rms.dbl + this.activeTour.tour.rms.twn) * 2 + this.activeTour.tour.rms.sin;
      this.onCalcSlider(tot);
      this.onSubToggle(tot);
      if (this.activeTour.tour.rms.sin <= 0) {
        this.subSdisable = true;
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
