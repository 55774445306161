import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {User} from '../../../../shared/models/user.model';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Orders} from '../../../../shared/models/orders.model';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {FlagService} from '../../../../shared/services/common/flag.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {loadStripe} from '@stripe/stripe-js';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../shared/services/common/title.service';
import {Subject} from 'rxjs';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';

@Component({
  selector: 'app-adfree',
  templateUrl: './adfree.component.html',
  styleUrls: ['./adfree.component.scss']
})
export class AdfreeComponent implements OnInit, OnDestroy {
  currentUser: User;
  activeTour: Activetour;

  order: Orders;
  orders: Orders[] = [];

  baseUrlLogo: string;
  payment_intent: any;
  stripePromise: any = null;
  stripe: any = null;
  pushed_pay = false;
  loading = false;
  err: string;
  txt: string;
  amount: number;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private authSvc: AuthenticationService,
    private activeTourSvc: ActivetourService,
    private flagService: FlagService,
    private snackSvc: SnackbarService,
    private httpOrder: HttpOrdersService,
    private httpUser: HttpUserService,
    private router: Router,
    private route: ActivatedRoute,
    private titleSvc: TitleService
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    // Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(environment.stripePublishKey)
      .then(res => {
        this.stripe = res;
      });
    this.baseUrlLogo = environment.baseUrl;
    this.titleSvc.setTitle('Upgrade App');
  }

  ngOnInit(): void {
    if (this.currentUser.role > User.pax_admin) {
      this.snackSvc.openSnackBar('Access here as PAX, not TD');
      this.router.navigate(['tours']);
      return;
    }
    this.err = null;
    this.txt = null;
    this.getUser();
    this.amount = 2.99;
    const sessionId = this.route.snapshot.queryParams['session_id'] ? this.route.snapshot.queryParams['session_id'] : null;
    if (sessionId && sessionId !== 'error') {
      this.verifySession(sessionId);
    } else if (sessionId && sessionId === 'error') {
      this.err = 'Payment not completed';
    }
  }

  getUser() {
    this.loading = true;
    this.httpUser.getUser(this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.currentUser = res.results.user;
            if (+res.results.user.user_settings.show_ads === 0) {
              this.currentUser.user_settings.show_ads = false;
              this.txt = 'You are already enjoying an ad-free experience';
            } else {
              this.currentUser.user_settings.show_ads = true;
              this.txt = null;
            }
            this.authSvc.updateUser(this.currentUser);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  verifySession(sessionId) {
    const data = {
      'user_id': this.currentUser.id,
      'session_id': sessionId,
    };
    this.httpOrder.verifyStripeSession(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const verified = res.results.verified;
            if (verified) {
              this.onUserSettsChange();

            } else {
              this.err = 'Payment ok but not verified. Contact support';
              this.snackSvc.openSnackBar('ERROR. Verifying payment');
            }
          } else {
            this.err = 'Payment ok but not verified. Contact support';
            this.snackSvc.openSnackBar('ERROR. Verifying payment');
          }
        },
        error => {
          console.log(error);
          this.err = 'Payment ok but not verified. Contact support';
          this.snackSvc.openSnackBar('ERROR. Verifying payment');
        });
  }

  onUserSettsChange() {
    const params = {
      'show_ads': false,
    };
    // console.log(params);
    this.httpUser.updateUserSettings(this.currentUser.id, params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.currentUser.user_settings.show_ads = false;
            this.txt = 'You are already enjoying an ad-free experience';
            this.snackSvc.openSnackBar('Payment successful!');
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Updating settings');
        });
  }

  onGoHome() {
    this.router.navigate(['home']);
  }

  /*
  /* STRIPE
  *******************/

  createUpgradeCheckout() {
    this.err = null;
    this.pushed_pay = true;
    const data = {
      'user_id': this.currentUser.id,
      'amount': this.amount * 100,
      'currency_code': 'EUR',
      'success_url': window.location.origin + '/upgrade/',
      'cancel_url': window.location.origin + '/upgrade/',
      'returnUrl': null,
    };
    // console.log(data);
    this.httpOrder.setStripeEnhanceExpCheckout(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            window.open(res.results.checkout_session.url, '_self');
          } else {
            this.pushed_pay = false;
            this.snackSvc.openSnackBar('Error(1). Contact your Tour Director');
          }
        },
        error => {
          this.pushed_pay = false;
          console.log(error);
          this.flagService.setFlag(this.currentUser.id, this.router.url, '' + error, '');
          this.snackSvc.openSnackBar('Error(2). Contact your Tour Director');
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
