import {Injectable} from '@angular/core';
import {ConvRate} from '../../models/conv-rate.model';
import {TourOptional} from '../../models/tour-optional.model';

@Injectable({
  providedIn: 'root'
})
export class OptionalService {

  convRates: ConvRate[] = [];

  constructor() {
  }

  public replaceSymbols(txt): string {
    if (!txt) {
      return;
    }
    txt = txt.replace(/(?:euros?|eur)/gi, '€');
    txt = txt.replace(/(?:us dollars?|dollars?|usd)/gi, '$');
    txt = txt.replace(/(?:pounds?|gbp)/gi, '£');
    txt = txt.replace(/(?:new zealand dollars|nz dollars|nzd|\$nzd|nzd\$|nz\$|\$nz)/gi, 'NZ$');
    txt = txt.replace(/(?:australian dollars|aus dollars|aud|\$au|au\$)/gi, 'AU$');
    return txt;
  }

  public getConvRates() {
    return this.convRates;
  }

  public setConvRates(rates) {
    this.convRates = rates;
  }

  public detectCurrency(price): string {
    if (!price) {
      console.log('No price given');
      return null;
    }
    let from;
    if (/\b(gbp|pounds?)\b/i.test(price) || price.includes('£')) {
      from = 'GBP';
    } else if (/\b(au|aud|australian\s*dollars?)\b/i.test(price) || price.includes('A$')) {
      from = 'AUD';
    } else if (/\b(nz|nzd|new\s*zealand\s*dollars?)\b/i.test(price) || price.includes('NZ$') || price.includes('N$')) {
      from = 'NZD';
    } else if (/\b(usd|us\s*dollars?)\b/i.test(price) || (price.includes('$'))) {
      from = 'USD';
    } else if (/\b(eur|euro|euros?)\b/i.test(price) || price.includes('€')) {
      from = 'EUR';
    } else {
      from = null;
    }
    return from;
  }

  public convertCurrency(from: string, to: string, amount: number) {
    if (!from || !to) {
      return amount;
    }
    if (!amount) {
      return null;
    }
    if (from === to) {
      return amount;
    }
    if (from !== 'EUR') {
      const fromRate = this.convRates.find(cr => cr.currency_to === from)?.rate;
      if (!fromRate) {
        return null;
      }
      amount /= fromRate;
    }
    if (to !== 'EUR') {
      const toRate = this.convRates.find(cr => cr.currency_to === to)?.rate;
      if (!toRate) {
        return null;
      }
      amount *= toRate;
    }
    return +(Math.round(Number(amount + 'e+2')) + 'e-2');
  }

  public convertPrice(currentUser, topt: TourOptional) {
    if (topt.actual_price) {
      const from = this.detectCurrency(topt.actual_price);
      if (!topt.currency || !currentUser.user_settings.currency.includes(topt.currency)) {
        const possible_currs = currentUser.user_settings.currency.split(',');
        topt.currency = possible_currs[0];
      }
      const to = topt.currency;
      const amount = +topt.actual_price.toUpperCase().replace(/[^,.\d]*/gm, '');
      topt.actual_cost = this.convertCurrency(from, to, amount);
      topt.actual_price = topt.currency + ' ' + topt.actual_cost;
    }
  }
}
