import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {User} from '../../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {TourOptional} from '../../../../../shared/models/tour-optional.model';
import {TourOptPax} from '../../../../../shared/models/tour-opt-pax.model';
import {HttpComponentService} from '../../../../../shared/services/http/http-component.service';
import {HttpTourOptionalService} from '../../../../../shared/services/http/http-tour-optional.service';
import {DatePipe} from '@angular/common';
import {TourPax} from '../../../../../shared/models/tour-pax.model';
import {HttpItineraryService} from '../../../../../shared/services/http/http-itinerary.service';
import {ConfirmationDialogComponent} from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FlagService} from '../../../../../shared/services/common/flag.service';
import {TextTransformService} from '../../../../../shared/helpers/texttransform.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatMenuTrigger} from '@angular/material/menu';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {OptionalService} from '../../../../../shared/services/common/optional.service';

@Component({
  selector: 'app-tour-optionals',
  templateUrl: './tour-optionals.component.html',
  styleUrls: ['./tour-optionals.component.scss']
})
export class TourOptionalsComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  tourPax: TourPax[] = [];
  tourPaxFilter: TourPax[] = [];
  tourOptional: TourOptional = null;
  tourOptPaxs: TourOptPax[] = [];

  possible_currs: any[] = [];
  showPayList: any[] = [];
  namesTOP: string;

  returnUrl: string;
  pay_link: string;

  sortBy = 'name';
  sortNameAsc = true;
  sortDateAsc = true;

  loadingTops = false;
  edit_lines = false;
  setMaxPax = false;
  setDeadline = false;
  show_internal_info: boolean;
  show_private_info: boolean;
  show_pax_list = false;
  ok_copied1 = false;
  ok_copied2 = false;

  private onDestroy$ = new Subject<boolean>();

  private static twoChars(inputString: string, charList: string[]): boolean {
    const regexPattern = `[${charList.join('')}]`;
    const regex = new RegExp(regexPattern, 'g');
    const matches = inputString.match(regex);
    return matches !== null && matches.length >= 2;
  }

  constructor(
    private authSvc: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private titleSvc: TitleService,
    private versioning: VersioningService,
    private clipboard: Clipboard,
    private flagService: FlagService,
    private httpTour: HttpTourService,
    private textTransform: TextTransformService,
    private httpTOSvc: HttpTourOptionalService,
    private httpComp: HttpComponentService,
    private httpItin: HttpItineraryService,
    private activeTourSvc: ActivetourService,
    private optService: OptionalService,
    private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
      });
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (event.key === 'Escape') {
      this.showPayList = [];
      this.edit_lines = false;
    }
  }

  ngOnInit() {
    const prod_id = this.route.snapshot.params['tour-prodid'];
    const tour_opt = this.route.snapshot.params['to_id'];
    if (this.activeTour.tour.prodid + '' !== prod_id) {
      this.snackSvc.openSnackBar('Error. Wrong tour');
      this.router.navigate(['tours']);
      return;
    }
    if (!this.activeTourSvc.belongsToUser(this.currentUser)) {
      this.snackSvc.openSnackBar('Error. Not your tour');
      this.router.navigate(['tours']);
      return;
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ? this.route.snapshot.queryParams['returnUrl'] : null;
    this.getTourOptional(tour_opt);
  }

  selectCurr(curr) {
    if (this.tourOptional.currency === curr) {
      return;
    }
    this.tourOptional.actual_price = '' + this.optService.convertCurrency(this.tourOptional.currency, curr, this.tourOptional.actual_cost);
    this.tourOptional.currency = curr;
    this.updateTourOptPrices(this.tourOptional);
    this.updTourOpt(this.tourOptional);
  }

  openExternalUrl(url) {
    window.open(url, '_blank');
  }

  getTourOptional(to_id) {
    this.loadingTops = true;
    this.httpTOSvc.getTourOptional(to_id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.showPayList = [];
            this.edit_lines = false;
            this.versioning.check(res.results.version);
            this.tourOptional = res.results.tour_optional;
            this.tourOptional.actual_cost = res.results.tour_optional.actual_cost / 100;
            this.optService.setConvRates(res.results.conv_rates);
            this.pay_link = environment.selfUrl + 'optional_activity/to/' + this.tourOptional.id;
            this.possible_currs = res.results.currency.split(',');
            this.tourOptional.pay_online = res.results.tour_optional.pay_online !== 0;
            this.currentUser.user_settings.currency = res.results.currency;
            this.authSvc.updateUser(this.currentUser);
            this.setMaxPax = !!this.tourOptional.max_pax;
            this.setDeadline = !!this.tourOptional.deadline;
            if (!this.tourOptional.actual_price) {
              const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                autoFocus: true,
                data: {
                  disabled: false,
                  confirmMessage: 'Please double check the latest price on TD Optionals doc.'
                }
              });
            }
            this.tourOptPaxs = res.results.to_pax.sort((a, b) =>
              a.tour_pax.user['name'].toLowerCase() > b.tour_pax.user['name'].toLowerCase() ? 1 :
                a.tour_pax.user['name'].toLowerCase() === b.tour_pax.user['name'].toLowerCase() ? 0 : -1);
            this.namesTOP = this.tourOptPaxs.map(tp => tp.tour_pax.user.name).join('\r\n');
            this.tourPax = res.results.tour_pax;
            const tp_ids = this.tourOptPaxs.map(function (el) {
              return el.tour_pax_id;
            });
            this.tourPaxFilter = this.tourPax.filter(it => !tp_ids.includes(it.id));
            let title = '';
            this.tourOptional.optional.supplier ?
              title = this.tourOptional.optional.name + ' ' + this.tourOptional.optional.supplier : title = this.tourOptional.optional.name;
            const idx = this.activeTour.optionals.findIndex(o => +o.id === +this.tourOptional.id);
            if (idx < 0) {
              this.activeTour.optionals.push(this.tourOptional);
              this.activeTourSvc.setActiveTour(this.activeTour);
            }
            this.titleSvc.setTitle(title);
          } else {
            this.snackSvc.resultsElse(res);
            this.goTOBack();
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  onRefreshTO() {
    const tour_opt = this.route.snapshot.params['to_id'] || null;
    this.getTourOptional(tour_opt);
  }

  goTOBack() {
    if (this.returnUrl && this.returnUrl === window.location.pathname) {
      this.router.navigate(['tour', this.activeTour.tour.prodid, 'optionals'], {queryParams: {returnUrl: window.location.pathname}});
    } else if (this.returnUrl && this.returnUrl !== 'undefined') {
      this.router.navigate([this.returnUrl]);
    } else {
      this.activeTourSvc.setActiveTour(this.activeTour);
      this.router.navigate(['tour', this.activeTour.tour.prodid]);
    }
  }

  onGoToOpt(to_id: number) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'optionals', to_id], {queryParams: {returnUrl: window.location.pathname}});
    this.getTourOptional(to_id);
  }

  onGoOptionals() {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'optionals'], {queryParams: {returnUrl: window.location.pathname}});
  }

  onGoComponent(tour_opt: TourOptional) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'day', tour_opt.day_id, 'component', tour_opt.component.id]);
  }

  updateTourOptPrices(topt: TourOptional) {
    if (topt.actual_price) {
      topt.actual_price = this.optService.replaceSymbols(topt.actual_price);
      topt.actual_cost = +topt.actual_price.toUpperCase().replace(/[^,.\d]*/gm, '');
    } else {
      topt.actual_price = null;
      topt.actual_cost = null;
      topt.currency = null;
      return;
    }
    if (topt.pay_online) {
      if (!topt.currency || !this.currentUser.user_settings.currency.includes(topt.currency)) {
        this.possible_currs = this.currentUser.user_settings.currency.split(',');
        topt.currency = this.possible_currs[0];
      }
    } else {
      topt.currency = null;
    }
  }

  onTogglePay() {
    if (!this.currentUser.user_settings.sales_enabled && !this.tourOptional.pay_online) {
      const snackbarRef = this.snackSvc.openSnackBar('Connect to Stripe payments', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          // ACTION = OK
          if (reason.dismissedByAction) {
            this.router.navigate(['user/info']);
          }
        });
      return;
    }
    this.tourOptional.pay_online = !this.tourOptional.pay_online;
    if (this.tourOptional.pay_online) {
      if (!this.tourOptional.actual_price) {
        this.tourOptional.pay_online = false;
        this.snackSvc.openSnackBar('Set price first');
        return;
      }
      this.optService.convertPrice(this.currentUser, this.tourOptional);
    } else {
      this.tourOptional.actual_price = this.tourOptional.currency + ' ' + this.tourOptional.actual_cost;
      this.tourOptional.actual_price = this.optService.replaceSymbols(this.tourOptional.actual_price);
      this.tourOptional.actual_cost = null;
      this.tourOptional.currency = null;
    }
    this.updTourOpt(this.tourOptional);
  }

  onChangePrice(topt) {
    if (topt.pay_online) {
      topt.actual_price = topt.currency + ' ' + topt.actual_cost;
      if (!topt.actual_price) {
        this.snackSvc.openSnackBar('Payment enabled: price needed');
        return;
      }
    } else {
      if (topt.actual_price) {
        const has_curr = /[^\d.,]/.test(topt.actual_price);
        if (!has_curr) {
          this.possible_currs = this.currentUser.user_settings.currency.split(',');
          topt.currency = this.possible_currs[0];
          topt.actual_cost = topt.actual_price.toUpperCase().replace(/[^,.\d]*/gm, '');
          topt.actual_price = topt.currency + ' ' + topt.actual_cost;
        }
      } else {
        topt.actual_price = null;
        topt.actual_cost = null;
        topt.currency = null;
      }
    }
    this.updateTourOptPrices(topt);
    this.updTourOpt(topt);
  }

  onFlagChanges(topt: TourOptional, field?) {
    if (this.currentUser.user_settings.edit_optionals) {
      const city_slug = this.textTransform.urlize(topt.component.city.name);
      this.router.navigate(['optionals', city_slug]);
    } else {
      const prob = 'Optional [' + topt.optional.id + '] "' + topt.optional.name + '" in ' + topt.component.city.name + ' [' + topt.component.city.id + '] ' + 'has been flagged';
      this.flagService.setFlag(this.currentUser.id, this.router.url, prob, field ? field : '');
      this.snackSvc.openSnackBar('Thank you for feedback');
    }
  }

  notify(txt?) {
    if (txt === '1') {
      this.ok_copied1 = true;
      setTimeout(() => {
        this.ok_copied1 = false;
      }, 2000);
    } else {
      this.ok_copied2 = true;
      setTimeout(() => {
        this.ok_copied2 = false;
      }, 2000);
    }
  }

  updateComponent(comp, txt?) {
    this.loadingTops = true;
    let data = {};
    if (!txt) {
      data = {
        'description': comp.description,
      };
    } else {
      data[txt] = comp[txt];
    }
    this.httpComp.updateComponent(comp.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          this.loadingTops = false;
          if (res.status < 400) {
            this.edit_lines = !this.edit_lines;
            const comIdx = this.activeTour.components.findIndex(c => +c.id === +comp.id);
            if (comIdx >= 0) {
              if (!txt) {
                this.activeTour.components[comIdx] = res.results.component;
              } else {
                this.activeTour.components[comIdx][txt] = res.results.component[txt];
              }
              this.activeTourSvc.setActiveTour(this.activeTour);
            }
          } else {
            console.log('Error: component not updated');
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.loadingTops = false;
          console.log(error);
        });
  }

  usePrice() {
    if (this.tourOptional.optional.price) {
      this.tourOptional.actual_price = this.tourOptional.optional.price;
    }
    if (this.tourOptional.pay_online) {
      this.optService.convertPrice(this.currentUser, this.tourOptional);
    }
    this.updateTourOptPrices(this.tourOptional);
    this.updTourOpt(this.tourOptional);
  }

  updTourOpt(topt: TourOptional, txt?) {
    // If has two currencies
    if (topt.actual_price && TourOptionalsComponent.twoChars(topt.actual_price, ['€', '$', '£'])) {
      this.snackSvc.openSnackBar('Use only one currency');
      return;
    }
    this.loadingTops = true;
    let data = {};
    if (!txt) {
      data = {
        'actual_price': topt.actual_price,
        'actual_cost': topt.actual_cost * 100,
        'currency': topt.currency,
        'extra_costs': topt.extra_costs,
        'total_earns': topt.total_earns,
        'pay_online': topt.pay_online,
        'status': topt.status,
      };
    } else {
      data[txt] = topt[txt];
    }
    if (txt === 'deadline' && topt[txt]) {
      data[txt] = this.datePipe.transform(new Date(topt[txt]), 'yyyy-MM-dd', 'es-ES');
    }
    // console.log(data);
    this.httpTOSvc.updateTourOptional(topt.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            topt.actual_price = res.results.tour_optional.actual_price;
            topt.currency = res.results.tour_optional.currency;
            topt.actual_cost = res.results.tour_optional.actual_cost / 100;
            topt.extra_costs = res.results.tour_optional.extra_costs;
            topt.total_earns = res.results.tour_optional.total_earns;
            topt.deadline = res.results.tour_optional.deadline;
            topt.max_pax = res.results.tour_optional.max_pax;
            topt.pay_online = res.results.tour_optional.pay_online !== 0;
            const idx = this.activeTour.optionals.findIndex(o => +o.id === +topt.id);
            if (idx >= 0) {
              this.activeTour.optionals[idx] = topt;
              this.activeTourSvc.setActiveTour(this.activeTour);
            }
            this.setMaxPax = !!topt.max_pax;
            this.setDeadline = !!topt.deadline;
            if (topt.pay_online) {
              topt.component.events[0].visible = true;
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  sameNums(n1, n2) {
    if (n1 && n2) {
      return '' + n1.replace(/[^0-9.]/g, '') === '' + n2.replace(/[^0-9.]/g, '');
    } else {
      return false;
    }
  }

  showAmount(to_pax) {
    if (to_pax.notes?.includes('card')) {
      this.snackSvc.openSnackBar('Can\'t edit, paid by card');
      return;
    }
    if (!this.showPayList.includes(to_pax.id)) {
      this.showPayList.push(to_pax.id);
    }
  }

  markAsPaid(to_pax: TourOptPax) {
    if (this.tourOptional.actual_price) {
      if (this.tourOptional.pay_online) {
        const curr = this.optService.replaceSymbols(this.tourOptional.currency);
        to_pax.amount_paid = this.tourOptional.actual_price + ' ' + curr;
      } else {
        to_pax.amount_paid = this.tourOptional.actual_price;
      }
      this.updateTourOptionalPax(to_pax, 'paid_at');
    } else {
      this.showAmount(to_pax);
    }
  }

  copyPayLink() {
    this.clipboard.copy(this.pay_link);
    this.notify('1');
  }

  sendPayLink(to_pax: TourOptPax) {
    if (to_pax.paid_at) {
      this.snackSvc.openSnackBar('PAX already paid');
      return;
    }
    if (!to_pax.tour_pax.user.email) {
      this.snackSvc.openSnackBar('Fake pax, no email');
      return;
    }
    if (to_pax.notes === 'pay_link_sent') {
      // Show snackbar to undo sen
      const snackbarRef = this.snackSvc.openSnackBar('Send email again?', 'OK');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          // ACTION = OK
          if (reason.dismissedByAction) {
            const params = {
              'top_id': to_pax.id,
              'url': environment.selfUrl,
            };
            // console.log(params);
            this.httpTOSvc.sendTOPaymentLink(params)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                res => {
                  console.log(res);
                  if (res.status < 400) {
                    to_pax.notes = res.results.notes;
                    this.snackSvc.openSnackBar('Email with payment link sent');
                  } else {
                    this.snackSvc.resultsElse(res);
                  }
                },
                error => {
                  console.log(error);
                  this.snackSvc.openSnackBar('ERROR. Sending payment link');
                });
          }
        });
    } else {
      const params = {
        'top_id': to_pax.id,
        'url': environment.selfUrl,
      };
      // console.log(params);
      this.httpTOSvc.sendTOPaymentLink(params)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          res => {
            console.log(res);
            if (res.status < 400) {
              to_pax.notes = res.results.notes;
              this.snackSvc.openSnackBar('Email with payment link sent');
            } else {
              this.snackSvc.resultsElse(res);
            }
          },
          error => {
            console.log(error);
            this.snackSvc.openSnackBar('ERROR. Sending payment link');
          });
    }
  }

  setNoPaid(to_pax) {
    // Show snackbar to undo remove payment
    const snackbarRef = this.snackSvc.openSnackBar('Remove payment?', 'OK');
    snackbarRef.afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(reason => {
        // ACTION = OK
        if (reason.dismissedByAction) {
          this.updateTourOptionalPax(to_pax, '!paid_at');
        }
      });
  }

  onChangeAmount(to_pax) {
    this.updateTourOptionalPax(to_pax, 'paid_at');
  }

  onAddPAX(tpax: TourPax) {
    this.loadingTops = true;
    const params = {
      'tour_pax_id': tpax.id,
      'tour_optional_id': this.tourOptional.id,
      'url': environment.selfUrl,
    };
    // console.log(params);
    this.httpTOSvc.createTourOptPax(params)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const topIdx = this.tourOptPaxs.findIndex(it => '' + it.id === '' + res.results.top.id);
            if (topIdx >= 0) {
              this.tourOptPaxs[topIdx] = res.results.top;
            } else {
              this.tourOptPaxs.push(res.results.top);
            }
            this.tourOptPaxs.sort((a, b) =>
              a.tour_pax.user['name'].toLowerCase() > b.tour_pax.user['name'].toLowerCase() ? 1 :
                a.tour_pax.user['name'].toLowerCase() === b.tour_pax.user['name'].toLowerCase() ? 0 : -1);
            this.namesTOP = this.tourOptPaxs.map(tp => tp.tour_pax.user.name).join('\r\n');
            const tp_ids = this.tourOptPaxs.map(function (el) {
              return el.tour_pax_id;
            });
            this.tourPaxFilter = this.tourPax.filter(it => !tp_ids.includes(it.id));
          } else {
            if (res.status === 405) {
              this.snackSvc.openSnackBar(res.message);
            } else {
              this.snackSvc.resultsElse(res);
            }
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  toggleSortPax(txt) {
    if (txt === 'name') {
      this.sortBy = txt;
      this.sortNameAsc = !this.sortNameAsc;
      this.tourOptPaxs.sort((l, r) => {
        return l.tour_pax.user.name.toLowerCase() > r.tour_pax.user.name.toLowerCase() ? (this.sortNameAsc ? 1 : -1) :
          l.tour_pax.user.name.toLowerCase() < r.tour_pax.user.name.toLowerCase() ? (this.sortNameAsc ? -1 : 1) : 0;
      });
    } else if (txt === 'joined') {
      this.sortBy = txt;
      this.sortNameAsc = false;
      this.sortDateAsc = !this.sortDateAsc;
      this.tourOptPaxs.sort((l, r) => {
        return l.created_at > r.created_at ? (this.sortDateAsc ? 1 : -1) : l.created_at < r.created_at ? (this.sortDateAsc ? -1 : 1) : 0;
      });
    }
  }

  removeNote(tourOptPax: TourOptPax, txt: string) {
    tourOptPax.notes = tourOptPax.notes.replace(txt, '');
    this.updateTourOptionalPax(tourOptPax, 'notes');
  }

  refundCharge(tourOptPax: TourOptPax) {
    this.loadingTops = true;
    const m_rfs = 3;
    const rfs = this.tourOptional.extra_costs ? this.tourOptional.extra_costs : 0;
    let lft = ' left for this Optional';
    if (m_rfs - rfs <= 0) {
      lft = '0 refunds' + lft;
    } else {
      lft = (m_rfs - rfs) + ' refund' + (m_rfs - rfs !== 1 ? 's' : '') + lft;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: true,
      data: {disabled: rfs >= m_rfs, confirmMessage: (rfs < m_rfs ? 'I confirm I have credit. ' : 'Refund cash. ') + lft}
    });
    dialogRef.afterClosed().subscribe(reason => {
      if (reason) {
        const data = {
          'top_id': tourOptPax.id
        };
        // console.log(data);
        this.httpTOSvc.refundTOPpay(data)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                tourOptPax = res.results.top;
                const idx2 = this.tourOptPaxs.findIndex(top => '' + top.id === '' + tourOptPax.id);
                if (idx2 >= 0) {
                  this.tourOptPaxs[idx2] = res.results.top;
                }
                this.tourOptional.extra_costs = this.tourOptional.extra_costs + 1;
              } else {
                this.snackSvc.resultsElse(res);
              }
              this.loadingTops = false;
            },
            error => {
              console.log(error);
              this.loadingTops = false;
            });
      } else {
        this.loadingTops = false;
      }
    });
  }

  updateTourOptionalPax(tourOptPax: TourOptPax, txt?) {
    this.loadingTops = true;
    let data = {};
    if (txt === 'paid_at') {
      if (!tourOptPax.amount_paid || /^\s*$/.test(tourOptPax.amount_paid)) {
        data = {
          'amount_paid': null,
          'paid_at': null,
          'notes': null,
        };
      } else {
        data = {
          'amount_paid': tourOptPax.amount_paid,
          'notes': 'cash',
          'paid_at': this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', 'es-ES'),
        };
      }
    } else if (txt === '!paid_at') {
      data = {
        'amount_paid': null,
        'paid_at': null,
        'notes': null,
        'delete': true,
      };
    } else if (txt === 'diff') {
      data = {
        'diff': tourOptPax.diff,
      };
    } else if (txt === 'notes') {
      data = {
        'notes': tourOptPax.notes,
      };
    }
    // console.log(data);
    this.httpTOSvc.updateTourOptionalPax(tourOptPax.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const topIdx = this.tourOptPaxs.findIndex(it => '' + it.id === '' + tourOptPax.id);
            this.tourOptPaxs[topIdx] = res.results.top;
            const idx = this.showPayList.findIndex(i => '' + i.id === '' + tourOptPax.id);
            this.showPayList.splice(idx, 1);
            this.namesTOP = this.tourOptPaxs.map(tp => tp.tour_pax.user.name).join('\r\n');
          } else {
            this.snackSvc.resultsElse(res);
            if (res.status !== 401) {
              this.goTOBack();
            }
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  deleteTourOptPax(tourOptPax: TourOptPax) {
    this.httpTOSvc.deleteTourOptPax(tourOptPax.tour_pax_id, tourOptPax.tour_optional_id, 0)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const topIdx = this.tourOptPaxs.findIndex(it => '' + it.id === '' + tourOptPax.id);
            this.tourOptPaxs.splice(topIdx, 1);
            const tp_ids = this.tourOptPaxs.map(function (el) {
              return el.tour_pax_id;
            });
            this.namesTOP = this.tourOptPaxs.map(tp => tp.tour_pax.user.name).join('\r\n');
            this.tourPaxFilter = this.tourPax.filter(it => !tp_ids.includes(it.id));
          } else {
            this.snackSvc.resultsElse(res);
            this.goTOBack();
          }
          this.loadingTops = false;
        },
        error => {
          console.log(error);
          this.loadingTops = false;
        });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
