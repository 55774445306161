import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-gl-chip',
  templateUrl: './gl-chip.component.html',
  styleUrls: ['./gl-chip.component.scss']
})
export class GlChipComponent {

  @Input() public list: any[];
  @Input() public fil: string;
  @Input() public tit: string;
  @Output() selectChip = new EventEmitter();

  constructor() {
  }

  filterChip(res: any) {
    this.selectChip.emit(res);
  }

}
