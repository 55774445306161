import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {HttpFlightService} from '../../../../shared/services/http/http-flight.service';
import {TourFlight} from '../../../../shared/models/tour-pax-flight.model';

export interface DialogDataAddFlight {
  tour_id: number;
  city_id: number;
  date: string;
  direction: string;
  tour_flight: TourFlight;
  reason: string;
}

@Component({
  selector: 'app-add-flight',
  templateUrl: './add-flight.component.html',
  styleUrls: ['./add-flight.component.scss']
})
export class AddFlightComponent implements OnInit, OnDestroy {
  loading = false;
  flight_airline: string;
  flight_number: string;
  flight_date: string;
  flight_time: string;
  flight_fromto: string;
  errors: any = {};

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private snackSvc: SnackbarService,
    private httpFlight: HttpFlightService,
    public dialogRef: MatDialogRef<AddFlightComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataAddFlight) {
  }

  ngOnInit(): void {
    this.flight_date = this.data.date;
  }

  validateInput(): string | null {
    this.errors = {};
    if (!this.flight_airline) {
      this.errors.flight_airline = 'Error. Airline needed';
    }
    if (!this.flight_number) {
      this.errors.flight_number = 'Error. Number needed';
    }
    if (!this.flight_fromto) {
      this.errors.flight_fromto = 'Error. Airport needed';
    }
    if (!this.flight_date) {
      this.errors.flight_date = 'Error. Date needed';
    }
    if (!this.flight_time) {
      this.errors.flight_time = 'Error. Time needed';
    }
    const firstError = Object.values(this.errors)[0];
    return typeof firstError === 'string' ? firstError : null;
  }

  submit(): void {
    const errorMessage = this.validateInput();
    if (errorMessage) {
      this.snackSvc.openSnackBar(errorMessage);
      return;
    }
    this.createTourFlight();
  }

  createTourFlight() {
    this.loading = true;
    const data = {
      'tour_id': this.data.tour_id,
      'city_id': this.data.city_id,
      'flight_airline': this.flight_airline.toUpperCase(),
      'flight_number': this.flight_number,
      'flight_date': this.flight_date,
      'flight_fromto': this.flight_fromto.toUpperCase(),
      'flight_time': this.flight_time,
      'direction': this.data.direction,
    };
    // console.log(data);
    this.httpFlight.addTourFlight(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.tour_flight = res.results.tour_flight;
            this.data.tour_flight.locked = true;
            if (this.data.direction === 'arrivals') {
              this.data.tour_flight.estimated = this.data.tour_flight.flight_track.arrival.estimated;
              this.data.tour_flight.estimated_short = new Date(this.data.tour_flight.estimated).toTimeString().slice(0, 5);
            } else {
              this.data.tour_flight.estimated = this.data.tour_flight.flight_track.departure.estimated;
              this.data.tour_flight.estimated_short = new Date(this.data.tour_flight.estimated).toTimeString().slice(0, 5);
            }
            this.close('ok');
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  changeTime(event) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.substring(0, 5);
    inputValue = inputValue.replace(/[^\d:]/g, '');
    if (inputValue === ':') {
      inputValue = null;
    }
    if (inputValue) {
      let [hours, minutes] = inputValue.split(':').map(Number);
      hours = isNaN(hours) ? 0 : hours;
      minutes = isNaN(minutes) ? 0 : minutes;
      const time = new Date();
      time.setHours(hours, minutes);
      inputValue = time.toLocaleTimeString(['es-ES'], {hour: '2-digit', minute: '2-digit'});
    }
    this.flight_time = inputValue;
  }

  inputTime(event) {
    let inputValue: string = event.target.value;
    if (event.data === ' ' && inputValue.length === 2) {
      inputValue = '0' + event.target.value[0] + ':';
    }
    if (inputValue.length === 2 && inputValue.indexOf(':') === -1) {
      inputValue = inputValue.slice(0, 2) + ':' + inputValue.slice(2);
    }
    this.flight_time = inputValue.substring(0, 5);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
