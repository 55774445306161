import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpFlightService {
  private endpoints: {
    allFlightCalls: string,
    crudFlightCalls: string,
    crudTourFlight: string,
    crudFlightTrack: string,
    crudFlightPoint: string,
    addTourFlight: string,
    addPaxFlight: string,
    allTourFlight: string,
    allTPFlight: string,
    crudTPFlight: string,
    tpFlightApi: string
    uploadFlights: string
  };

  constructor(private http: HttpClient) {
    this.endpoints = {
      allFlightCalls: environment.apiUrl + 'flight_call/',
      crudFlightCalls: environment.apiUrl + 'flight_calls/',
      crudTourFlight: environment.apiUrl + 'tour_flight/',
      crudFlightTrack: environment.apiUrl + 'flight_track/',
      crudFlightPoint: environment.apiUrl + 'flight_point/',
      addTourFlight: environment.apiUrl + 'add_tour_flight/',
      addPaxFlight: environment.apiUrl + 'add_pax_to_flight/',
      allTourFlight: environment.apiUrl + 'tour_flights/',
      crudTPFlight: environment.apiUrl + 'tourpax_flight/',
      allTPFlight: environment.apiUrl + 'tourpax_flights/',
      tpFlightApi: environment.apiUrl + 'tourpax_flight_api/',
      uploadFlights: environment.apiUrl + 'upload_flights_pdf/',
    };
  }

  // Upload flights doc
  uploadFlights(data): Observable<any> {
    return this.http.post<any>(this.endpoints.uploadFlights, data);
  }

  /* Gets all flights from tour */
  getFlightsTour(tour_id: number): Observable<any> {
    return this.http.get<any>(this.endpoints.crudTourFlight + tour_id);
  }

  /* Adds a tour flight */
  addTourFlight(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.addTourFlight, data);
  }

  /* Adds a tour flight */
  addPaxToFlight(data: any): Observable<any> {
    return this.http.post<any>(this.endpoints.addPaxFlight, data);
  }

  /* Update a tour flight */
  async updateTourFlight(tf_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudTourFlight + tf_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Update a flight point */
  async updateFlightPoint(fp_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudFlightPoint + fp_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Update a flight track */
  async updateFlightTrack(ft_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudFlightTrack + ft_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Delete a tour flight */
  async deleteTourFlight(tf_id: number) {
    const params = new HttpParams()
      .set('tour_flight_id', tf_id.toString());
    return await this.http.delete<any>(this.endpoints.crudTourFlight, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Updates a tour_pax flight */
  async updateTourPaxFlight(tpf_id: number, data: any) {
    return await this.http.put<any>(this.endpoints.crudTPFlight + tpf_id, data).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  /* Delete a tour pax flight */
  async deleteTourPaxFlight(tpf_id: number) {
    const params = new HttpParams()
      .set('tourpax_flight_id', tpf_id.toString());
    return await this.http.delete<any>(this.endpoints.crudTPFlight, {params: params}).toPromise()
      .then(res => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
}
