import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {User} from '../../../../shared/models/user.model';
import {Subject} from 'rxjs';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {HttpLocatorService} from '../../../../shared/services/http/http-locator.service';
import {TitleService} from '../../../../shared/services/common/title.service';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {AddCityComponent} from '../../../components/add-city/add-city.component';
import {VersioningService} from '../../../../shared/services/common/versioning.service';

@Component({
  selector: 'app-locator',
  templateUrl: './locator.component.html',
  styleUrls: ['./locator.component.scss']
})
export class LocatorComponent implements OnInit, OnDestroy {
  currentUser: User;

  cities: any[] = [];
  city_id: number;
  date: string;
  city_name: string;

  future = false;
  locating = false;
  finder: { 'id': number, 'user_id': string, 'city_id': string, results: { 'name': string, 'email': string, 'phone': string }[] } = null;

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private httplocatorService: HttpLocatorService,
    private httpCitySvc: HttpCityService,
    private titleSvc: TitleService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private versioning: VersioningService,
    private textTransformService: TextTransformService,
    private authSvc: AuthenticationService,
    private snackSvc: SnackbarService,
  ) {
    this.titleSvc.setTitle('TD Crosspaths');
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  ngOnInit() {
    this.onClear();
    this.date = this.route.snapshot.queryParams['date'] || null;
    if (this.date) {
      this.onSelectionDate({target: {value: this.date}});
    } else {
      this.date = '';
    }
    this.city_name = this.route.snapshot.queryParams['city'] || null;
    if (this.date && this.city_name) {
      this.locating = true;
    }
    this.getAllCities();
  }

  printVars() {
    console.log(this.date);
    console.log(this.city_id);
  }

  getAllCities() {
    this.httpCitySvc.getCitiesBasic()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 200) {
            this.versioning.check(res.results.version);
            // Insert all cities
            localStorage.setItem('cities', JSON.stringify(res.results.cities));
            this.cities = res.results.cities;
            if (this.city_name) {
              this.city_name = this.textTransformService.dehyphenize(this.city_name);
              this.city_id = this.cities.find(c => c.name === this.city_name).id;
              this.onSelectionCity({target: {value: this.city_id}});
              if (this.date) {
                this.searchLocator();
              }
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              console.log(res.message.toString());
            }
          }
        },
        error => {
          console.log(error);
          this.snackSvc.openSnackBar('Error getting all cities');
        });
  }

  searchLocator() {
    if (this.currentUser.role < 10 && (!this.city_id || this.city_id < 1)) {
      this.snackSvc.openSnackBar('Error. City required');
      return;
    }
    if (this.currentUser.role < 10 && !this.date) {
      this.snackSvc.openSnackBar('Error. Date required');
      return;
    }
    if (this.date && !this.textTransformService.isValidDate(this.date)) {
      this.snackSvc.openSnackBar('Error. Date format YYYY-MM-DD');
      return;
    }
    if (!this.currentUser.user_settings.traceable) {
      const snackbarRef = this.snackSvc.openSnackBar('User not localizable', 'Settings');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.router.navigate(['user', 'options'], {queryParams: {returnUrl: 'crosspaths'}});
          }
        });
      return;
    }
    this.locating = true;
    const data = {
      'user_id': this.currentUser.id,
      'future': this.future,
    };
    if (this.date) {
      data['date'] = this.date;
    }
    if (this.city_id >= 0) {
      data['city_id'] = this.city_id;
    }
    // console.log(data);
    this.httplocatorService.createLocatorSearch(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.locating = false;
          console.log(res);
          if (res.status < 400) {
            this.finder = res.results.locator;
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              // If special visibility message bring the change settings snackbar
              if (res.message.toString() === 'User invisible. Settings:') {// DON'T CHANGE THIS TEXT
                const snackbarRef = this.snackSvc.openSnackBar('User not localizable', 'Settings');
                snackbarRef.afterDismissed()
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe(reason => {
                    if (reason.dismissedByAction) {
                      this.router.navigate(['user', 'options'], {queryParams: {returnUrl: 'crosspaths'}});
                    }
                  });
              } else {
                this.snackSvc.openSnackBar(res.message.toString());
              }
            }
          }
        },
        error => {
          this.locating = false;
          console.log(error);
        });
  }

  onSelectionDate(event) {
    this.date = event.target.value;
    this.router.navigate([], {
      queryParams: {
        date: this.date,
      },
      queryParamsHandling: 'merge',
    });
  }

  onSelectionCity(event) {
    if ('' + event.target.value === '' + 0) {
      this.onAddCity();
    }
    this.city_id = Number(event.target.value);
    const city_name = this.cities.find(c => +c.id === +this.city_id).name;
    this.router.navigate([], {
      queryParams: {
        city: this.textTransformService.urlize(city_name),
      },
      queryParamsHandling: 'merge',
    });
  }

  onClear() {
    this.date = '';
    this.city_id = -1;
    this.finder = null;
    this.router.navigate([], {
      queryParams: {
        city: null,
        date: null
      },
      queryParamsHandling: 'merge',
    });
  }

  onAddCity() {
    const dialogRef = this.dialog.open(AddCityComponent, {
      autoFocus: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const params = {
          'user_id': this.currentUser.id,
          'confirmed': true,
          'name': this.textTransformService.titleCase(result['name']),
          'country': this.textTransformService.titleCase(result['country']),
        };
        this.httpCitySvc.saveCity(params)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(
            res => {
              console.log(res);
              if (res.status < 400) {
                const city = res.results.city;
                const CityIdx = this.cities.findIndex(x => x.name === city.name && x.country === city.country);
                if (CityIdx < 0) {
                  this.cities.push(city);
                  localStorage.setItem('cities', JSON.stringify(this.cities));
                } else {
                  this.snackSvc.openSnackBar('City already exists');
                }
                this.city_id = res.results.city.id;
                this.cities.sort(function (a, b) {
                  return a.name[0].localeCompare(b.name[0]);
                });
              } else {
                this.snackSvc.resultsElse(res);
              }
            },
            error => {
              console.log(error);
            });
      } else {
        this.city_id = null;
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
