import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {User} from '../../../../../shared/models/user.model';
import {Components} from '../../../../../shared/models/components.model';
import {Activetour} from '../../../../../shared/models/activetour.model';
import {ActivatedRoute, Router} from '@angular/router';
import {TitleService} from '../../../../../shared/services/common/title.service';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../../../../shared/services/session/authentication.service';
import {Subject} from 'rxjs';
import {DatePipe, Location} from '@angular/common';
import {HttpTourService} from '../../../../../shared/services/http/http-tour.service';
import {ActivetourService} from '../../../../../shared/services/session/activetour.service';
import {SnackbarService} from '../../../../../shared/services/common/snackbar.service';
import {HttpItineraryService} from '../../../../../shared/services/http/http-itinerary.service';
import {AddOptionalComponent} from '../../../../components/tour/add-optional/add-optional.component';
import {Comptype} from '../../../../../shared/models/comptype.model';
import {Day} from '../../../../../shared/models/day.model';
import {HttpIncidentService} from '../../../../../shared/services/http/http-incident.service';
import {environment} from '../../../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {CreateOptionalComponent} from '../../../../components/tour/create-optional/create-optional.component';
import {TextTransformService} from '../../../../../shared/helpers/texttransform.service';
import {Tour} from '../../../../../shared/models/tour.model';
import {VersioningService} from '../../../../../shared/services/common/versioning.service';
import {TourService} from '../../../../../shared/services/common/tour.service';
import {Forecast} from '../../../../../shared/models/forecast.model';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss']
})
export class DayViewComponent implements OnInit, OnDestroy {
  activeTour: Activetour;
  currentUser: User;
  compTypes: Comptype[];
  toursUser: Tour[];
  dayFull: Day;
  days: Day[] = [];
  cities: any[] = [];

  viewAs: number;
  req_id: number;

  loading: boolean;
  loadingWeather: boolean;
  appUrl: string;
  city_name: string;
  copyText: string;
  old_name: string;
  show_ids: boolean;

  dayIdx: number;
  isSafari: boolean;
  isMobile: boolean;
  showWeather: boolean;

  components: Components[];
  suggestedComps: any[];
  suggOpts: any[] = [];

  editName = false;
  ok_copied = false;

  private onDestroy$ = new Subject<boolean>();
  @ViewChild('swipediv', {read: ElementRef, static: true}) swipeDiv: ElementRef;
  @ViewChild('top') searchElementTop: ElementRef;

  constructor(
    private authSvc: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private datePipe: DatePipe,
    private titleSvc: TitleService,
    public renderer: Renderer2,
    public dialog: MatDialog,
    private tourSvc: TourService,
    private versioning: VersioningService,
    private textTransform: TextTransformService,
    private httpTour: HttpTourService,
    private httpItin: HttpItineraryService,
    private httpIncidentService: HttpIncidentService,
    private activeTourSvc: ActivetourService,
    private snackSvc: SnackbarService) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
        this.viewAs = this.route.snapshot.queryParams.viewAs || this.currentUser && this.currentUser.view_as || null;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
        this.titleSvc.setTitle(this.activeTour.tour.code + '#' + this.activeTour.tour.prodid);
      });
  }

  @HostListener('window:keyup', ['$event'])
  showPinned(event: KeyboardEvent) {
    event.preventDefault();
    if (this.editName) {
      return;
    }
    if (event.key === 'ArrowLeft') {
      this.prevDay();
    } else if (event.key === 'ArrowRight') {
      this.nextDay();
    }
  }

  ngOnInit() {
    this.cities = JSON.parse(localStorage.getItem('cities'));
    // SECURITY CHECKS
    // If user has no tours or user's tours none is the prodid
    this.toursUser = this.activeTourSvc.getToursUser();
    this.appUrl = environment.appUrl;
    const tour_prodid = this.route.snapshot.params['tour-prodid'];
    if (!this.toursUser || !this.toursUser.find(x => '' + x.prodid === '' + tour_prodid)) {
      this.snackSvc.openSnackBar('Error. Invalid tour');
      this.router.navigate(['tours']);
      return;
    }
    // Check if tour is the active one
    if (this.activeTour.tour.prodid !== tour_prodid) {
      this.snackSvc.openSnackBar('Error. Not active tour');
      this.router.navigate(['tours']);
      return;
    }
    // If day not in active tour
    const day_id = +this.route.snapshot.params['day_id'];
    this.dayIdx = this.activeTour.days.findIndex(x => +x.id === +day_id);
    if (this.dayIdx < 0) {
      this.snackSvc.openSnackBar('Error. Invalid day');
      this.router.navigate(['tour', tour_prodid]);
      return;
    }
    this.isSafari = /apple/i.test(navigator.vendor);
    this.isMobile = this.authSvc.isMobileDevice();
    this.show_ids = JSON.parse(localStorage.getItem('show_ids'));
    this.compTypes = JSON.parse(localStorage.getItem('compTypes'));
    this.dayFull = new Day();
    this.dayFull.forecast = this.activeTour.days[this.dayIdx].forecast;
    this.dayFull.forecasts = this.activeTour.days[this.dayIdx].forecasts;
    if (!this.dayFull.forecasts || this.dayFull.forecasts?.length <= 0) {
      this.showWeather = false;
    } else {
      this.showWeather = JSON.parse(localStorage.getItem('showWeather') ?? 'true');
    }
    this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(val => {
        this.getDay(val['day_id']);
        setTimeout(() => {
          this.searchElementTop.nativeElement.scrollIntoView({behavior: 'auto', block: 'start'});
        }, 1);
      });
  }

  toggleWeather() {
    this.showWeather = !this.showWeather;
    localStorage.setItem('showWeather', this.showWeather.toString());
  }

  showIncidentInfo(inc) {
    console.log(inc);
  }

  showDayTour() {
    console.log(this.dayFull);
  }

  notify() {
    this.ok_copied = true;
    setTimeout(() => {
      this.ok_copied = false;
    }, 2000);
  }

  // DATA

  getPreview() {
    const payments = this.activeTour.orders.filter(o => o.status === 'Complete');
    if (payments.length <= 0) {
      const snackbarRef = this.snackSvc.openSnackBar('Purchase to preview tour', 'GO');
      snackbarRef.afterDismissed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(reason => {
          if (reason.dismissedByAction) {
            this.router.navigate(['summary', this.activeTour.tour.prodid], {queryParams: {returnUrl: window.location.pathname}});
          }
        });
    } else {
      window.open(this.appUrl + 'itinerary/' + btoa('' + this.activeTour.tour.id).replace(/=/g, '') + '?dayId=' + this.dayFull.id, '_blank');
    }
  }

  getDay(dayid) {
    this.loading = true;
    this.req_id = this.viewAs && this.currentUser.view_as ? this.viewAs : this.currentUser.id;
    this.httpItin.getDayFull(this.req_id, dayid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          const idx = this.activeTour.days.findIndex(it => '' + it.id === '' + res.results.day.id);
          if (res.status < 400) {
            this.editName = false;
            this.versioning.check(res.results.version);
            this.dayFull = res.results.day;
            this.city_name = this.cities.find(c => '' + c.id === '' + this.dayFull.itinerary.city_id).name;
            this.dayFull.forecast = this.activeTour.days[idx].forecast;
            this.dayFull.forecasts = this.activeTour.days[idx].forecasts;
            this.days = res.results.days;
            this.old_name = this.dayFull.name;
            this.getDayWeatherData(this.dayFull.id, idx);
            // Set ratios (for background color)
            this.dayFull.incidents.map(it => it.ratio = it.n_actives / (it.n_actives + it.n_inactives));
            // Update in memory the hide_alert state according to localStorage
            let list_hidden = JSON.parse(localStorage.getItem('hiddenIncidents'));
            if (list_hidden === null) {
              list_hidden = [];
            }
            this.dayFull.incidents.map(it => it.hide_alert = list_hidden.indexOf(it.id) >= 0);
            this.dayFull.events.sort(function (a: any, b: any) {
              return +(a.start_time > b.start_time) || -(a.start_time < b.start_time);
            });
            this.copyText = this.appUrl + 'itinerary/' + btoa('' + this.activeTour.tour.id).replace(/=/g, '') + '?dayId=' + this.dayFull.id;
            this.fillSuggestedComps();
            // update in memory
            this.activeTour.days[idx] = this.dayFull;
            this.activeTourSvc.setActiveTour(this.activeTour);
            // Get new messages notification
            const tour_messages_dates = [];
            if (this.activeTour.message_list && Array.isArray(this.activeTour.message_list.n_new_convos)) {
              for (const conversationId of this.activeTour.message_list.n_new_convos) {
                const component = this.activeTour.components.find(c => '' + c.conversation_id === '' + conversationId);
                if (component) {
                  const event = this.activeTour.events.filter(e => '' + e.component_id === '' + component.id).reduce(function (prev, curr) {
                    return prev.day_id < curr.day_id ? prev : curr;
                  });
                  const day = this.activeTour.days.find(d => d.id === event.day_id);
                  tour_messages_dates.push({'conv': conversationId, 'componentId': component.id, 'dayId': day.id});
                } else {
                  const orphan = this.activeTour.message_list?.orphan_convos.find(it => '' + it.convo === '' + conversationId);
                  if (orphan) {
                    tour_messages_dates.push({'conv': orphan.convo, 'componentId': orphan.comp_id, 'dayId': orphan.day_id});
                  }
                }
              }
            }
            this.dayFull.events.map(ev => {
              const comps = tour_messages_dates.filter(tmd => {
                return '' + ev.component_id === '' + tmd.componentId && '' + ev.day_id === '' + tmd.dayId;
              });
              ev.new_messages = comps.length;
            });
          } else {
            this.snackSvc.resultsElse(res);
            this.router.navigate(['tours']);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  isToday(date) {
    const day = new Date(date + 'T11:59:59.000Z').toDateString();
    const today = new Date().toDateString();
    return day === today;
  }

  onOKEditName(name) {
    this.loading = true;
    const data = {
      'name': name,
      'req_id': this.currentUser.id
    };
    this.httpItin.updateDay(this.dayFull.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status === 203) {
            this.dayFull.name = res.results.day.name;
            this.old_name = this.dayFull.name;
            const idxDay1 = this.days.findIndex(day => '' + day.id === '' + this.dayFull.id);
            this.days[idxDay1].name = name;
            const idxDay2 = this.activeTour.days.findIndex(day => '' + day.id === '' + this.dayFull.id);
            this.activeTour.days[idxDay2].name = name;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.editName = !this.editName;
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error editing day name');
        });
  }

  voteIncident(vote, inc) {
    this.loading = true;
    const data = {
      'user_id': this.currentUser.id,
      'n_actives': vote === 'yes' ? inc.n_actives + 1 : inc.n_actives,
      'n_inactives': vote === 'no' ? inc.n_inactives + 1 : inc.n_inactives,
    };
    this.httpIncidentService.updateIncident(inc.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            const idx = this.dayFull.incidents.findIndex(item => '' + item.id === '' + inc.id);
            this.dayFull.incidents[idx] = res.results.incident;
            this.dayFull.incidents.map(it => it.ratio = it.n_actives / (it.n_actives + it.n_inactives));
            this.dayFull.incidents[idx].hide_alert = true;
            // If incident not in hidden elements, push it
            let list_hidden = JSON.parse(localStorage.getItem('hiddenIncidents'));
            if (list_hidden === null) {
              list_hidden = [];
            }
            if (list_hidden.findIndex(it => '' + it === '' + inc.id) < 0) {
              list_hidden.push(inc.id);
              localStorage.setItem('hiddenIncidents', JSON.stringify(list_hidden));
            }
          } else {
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
          inc.openVote = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  fillSuggestedComps() {
    this.suggOpts = [];
    this.suggestedComps = [];
    // ARRIVALS
    if (this.activeTour.days[this.dayIdx].nday === 2) {
      // Taxi arrivals
      this.suggestedComps.push({label: 'Taxi Arrivals', icon: this.activeTour.icons.ico_taxi, class: 'ico_trfr', type_id: 11, arrdep: 'arrivals'});
      // Bus arrivals
      this.suggestedComps.push({label: 'Bus Arrivals', icon: this.activeTour.icons.ico_bus, class: 'ico_trfr', type_id: 16, arrdep: 'arrivals'});
      // Checklist arrivals
      this.suggestedComps.push({
        label: 'Arrivals Check-list',
        icon: this.activeTour.icons.ico_priva,
        class: 'ico_priva',
        type_id: 19,
        arrdep: 'arrivals'
      });
    }
    // DEPARTURES
    if (this.activeTour.days[this.dayIdx].nday === this.activeTour.tour.ndays) {
      // Bus Departures
      this.suggestedComps.push({label: 'Bus Departures', icon: this.activeTour.icons.ico_bus, class: 'ico_trfr', type_id: 17, arrdep: 'departures'});
      // Taxi Departures
      this.suggestedComps.push({
        label: 'Taxi Departures', icon: this.activeTour.icons.ico_taxi, class: 'ico_trfr', type_id: 11, arrdep: 'departures'
      });
    }
    // TD OPTS
    this.dayFull.optionals.sort(function (a, b) {
      return a.companies.localeCompare(b.companies) || a.name.localeCompare(b.name);
    });
    this.dayFull.optionals.map(o => {
      o.company_ids = o.companies.replace(/^,*/, '').replace(/,*$/, '').split(',').map(i => +i);
      o.cia = '';
      if (o.company_ids.includes(1)) {
        o.cia += 'PL,';
      }
      if (o.company_ids.includes(2)) {
        o.cia += 'UB,';
      }
      if (o.company_ids.includes(3)) {
        o.cia += 'ET,';
      }
      if (o.company_ids.includes(4)) {
        o.cia += 'ADV,';
      }
      if (o.company_ids.includes(5)) {
        o.cia += 'GY,';
      }
      if (o.company_ids.includes(6)) {
        o.cia += 'GAT,';
      }
      if (o.company_ids.includes(7)) {
        o.cia += 'SA,';
      }
      o.cia = o.cia.replace(/^,*/, '').replace(/,*$/, '');
      if (o.cia === '') {
        o.cia = null;
      }
    });
    if (this.currentUser.role > User.ef) {
      for (const i of this.dayFull.optionals) {
        const idx = this.suggOpts.findIndex(o => +o.id === +i.id);
        if (idx < 0) {
          i['city_name'] = this.city_name;
          this.suggOpts.push(i);
        }
      }
    }
    // PLUS DINNER
    if ((this.activeTour.tour.code.substring(0, 1) === 'X') && this.dayFull.nday > 2 && this.dayFull.nday < this.activeTour.tour.ndays) {
      // Group dinner, except in welcome farewell
      this.suggestedComps.push({
        label: 'Included dinner', name: 'Included dinner', icon: this.activeTour.icons.ico_rest, class: 'ico_rest', type_id: 5
      });
    }
  }

  onNewCompMenuSelect(index: number) {
    if (!this.suggestedComps) {
      return;
    }
    let type;
    let name;
    let arrdep;
    let supplier;
    let email;
    if (index === -1) {
      type = 21;
      name = '';
    } else {
      type = this.suggestedComps[index].type_id;
      arrdep = this.suggestedComps[index].arrdep;
      name = this.suggestedComps[index].label;
      supplier = this.suggestedComps[index].supplier;
      email = this.suggestedComps[index].email;
    }
    this.router.navigate(['component', 'new'],
      {queryParams: {'type_id': type, 'arrdep': arrdep, 'name': name, 'supplier': supplier, 'email': email}, relativeTo: this.route});
  }

  // EVENT
  reorderEvts() {
    setTimeout(() => {
      this.dayFull.events.sort(function (a: any, b: any) {
        return +(a.start_time > b.start_time) || -(a.start_time < b.start_time);
      });
    }, 10);
  }

  addDupeEvent(result) {
    if (result.events[0]?.day_id === this.dayFull.id) {
      const orig_idx = this.dayFull.events.findIndex(ev => +ev.id === result.events[0].id);
      this.dayFull.events.splice(orig_idx + 1, 0, result.events[0]);
    }
  }

  onDelComp(comp_id) {
    this.dayFull.events = this.dayFull.events.filter(ev => +ev.component_id !== +comp_id);
  }

  onAddOptional() {
    const dialogRef = this.dialog.open(AddOptionalComponent, {
      minWidth: '80vw',
      maxWidth: '90vw',
      autoFocus: true,
      data: {
        'req_id': this.currentUser.id,
        'city_name': this.city_name,
        'tour': this.activeTour.tour,
        'city_id': this.dayFull.itinerary.city_id,
        'suggOpts': this.suggOpts,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] === 'new') {
        this.onCreateNewOpt();
      } else if (result && result['reason'] === 'ok') {
        const opt = result['optional'];
        this.router.navigate(['component', 'new'],
          {
            queryParams: {'type_id': 9, 'name': opt.name, 'supplier': opt.supplier, 'email': opt.email, 'city_id': opt.city_id},
            relativeTo: this.route
          });
      } else {
        // console.log('Didnt add/create opt');
      }
    });
  }

  onCreateNewOpt() {
    const dialogRef = this.dialog.open(CreateOptionalComponent, {
      minWidth: '80vw',
      maxWidth: '90vw',
      autoFocus: true,
      data: {
        'req_id': this.currentUser.id,
        'company_id': this.activeTour.tour.company_id,
        'city_id': this.dayFull.itinerary.city_id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result['reason'] !== 'close') {
        const opt = result['optional'];
        this.router.navigate(['component', 'new'],
          {queryParams: {'type_id': 9, 'name': opt.name, 'supplier': opt.supplier, 'email': opt.email}, relativeTo: this.route});
      } else {
        // console.log('Didnt add opt');
      }
    });
  }

  // STATUS

  showDayIncidentsAgain() {
    let hidden = JSON.parse(localStorage.getItem('hiddenIncidents'));
    if (hidden === null) {
      return;
    }
    this.dayFull.incidents.map(it => it.hide_alert = false);
    hidden = hidden.filter(d => this.dayFull.incidents.findIndex(it => '' + it.id === '' + d) < 0);
    localStorage.setItem('hiddenIncidents', JSON.stringify(hidden));
    console.log(localStorage.getItem('hiddenIncidents'));
  }

  // NAVIGATION

  goToDay(day) {
    this.router.navigate(['tour', this.activeTour.tour.prodid, 'day', day.id]);
    this.getDay(day.id);
  }

  onPanS(evt) {
    this.editName = false;
    if (evt.changedPointers.length > 0 && evt.changedPointers[0].movementX !== 0 && evt.changedPointers[0].movementY < 5) {
      this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(${evt.deltaX}px)`);
    }
  }

  onPanE() {
    this.editName = false;
    this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(0px)`);
  }

  onSwipeDay(evt) {
    this.editName = false;
    this.renderer.setStyle(this.swipeDiv.nativeElement, 'transition', '0.4s ease-out');
    const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
    const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
    if (x === 'right') {
      this.prevDay();
    } else if (x === 'left') {
      this.nextDay();
    } else {
      this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(0px)`);
    }
  }

  prevDay() {
    if (this.dayFull.nday > 1) {
      const itemIndex = this.activeTour.days.findIndex(d => d.nday === this.dayFull.nday - 1);
      this.dayIdx = itemIndex;
      if (itemIndex >= 0) {
        const prev_day_id = '' + this.activeTour.days[itemIndex].id;
        this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(2000px)`);
        this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + prev_day_id]);
        this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(0px)`);
        return;
      }
    } else {
      this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(0px)`);
    }
  }

  nextDay() {
    if (this.dayFull.nday < this.activeTour.tour.ndays) {
      const itemIndex = this.activeTour.days.findIndex(d => d.nday === this.dayFull.nday + 1);
      this.dayIdx = itemIndex;
      if (itemIndex >= 0) {
        const next_day_id = '' + this.activeTour.days[itemIndex].id;
        this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(-2000px)`);
        this.router.navigate(['tour/' + this.activeTour.tour.prodid + '/day/' + next_day_id]);
        this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(0px)`);
        return;
      }
    } else {
      this.renderer.setStyle(this.swipeDiv.nativeElement, 'transform', `translateX(0px)`);
    }
  }

  goTourview() {
    this.router.navigate(['tour', this.activeTour.tour.prodid]);
  }


  // WEATHER

  insertSuntimesInWeather() {
    const sunriseForecast: any = {
      id: 0,
      dt_txt: this.dayFull.sunrise,
      weather_icon: 'sunrise',
      weather_main: 'Sunrise',
    };
    const more: any = {
      id: 0,
      dt_txt: 'Forecast updates within 5 days',
    };
    const sunsetForecast: any = {
      id: 0,
      dt_txt: this.dayFull.sunset,
      weather_icon: 'sunset',
      weather_main: 'Sunset',
    };
    if (this.dayFull.forecasts.length > 0) {
      this.dayFull.forecasts.push(sunriseForecast, sunsetForecast);
    } else {
      this.dayFull.forecasts.push(sunriseForecast, more, sunsetForecast);
    }
    this.dayFull.forecasts.sort((a, b) => {
      const aTime = new Date(`1970-01-01T${a.dt_txt}:00Z`);
      const bTime = new Date(`1970-01-01T${b.dt_txt}:00Z`);
      return aTime.getTime() - bTime.getTime();
    });
  }

  async getDayWeatherData(id, idx) {
    this.loadingWeather = true;
    const res = await this.httpTour.getDayWeatherData(id);
    console.log(res);
    if (res.status < 400) {
      this.loadingWeather = false;
      this.dayFull.forecast = res.results.summary;
      this.dayFull.forecasts = res.results.forecasts.map(f => ({
        ...f,
        dt_txt: f.dt_txt.substring(11, 16),
        temp: Math.ceil(f.temp)
      }));
      this.insertSuntimesInWeather();
      this.activeTour.days[idx].forecast = this.dayFull.forecast;
      this.activeTour.days[idx].forecasts = this.dayFull.forecasts;
      this.activeTourSvc.setActiveTour(this.activeTour);
    } else {
      this.loadingWeather = false;
      this.snackSvc.resultsElse(res);
    }
  }

  // INCIDENTS

  getFirstIncident() {
    if (this.dayFull.incidents.length > 0) {
      const max_ratio = Math.max.apply(Math, this.dayFull.incidents.map(function (it) {
        return it.ratio = it.n_actives / (it.n_actives + it.n_inactives);
      }));
      const maxidx = this.dayFull.incidents.findIndex(it => max_ratio === (it.n_actives / (it.n_actives + it.n_inactives)));
      return this.dayFull.incidents[maxidx];
    } else {
      return null;
    }
  }

  getColorForPercentage(inc) {
    if (!inc) {
      return;
    }
    const pct = 1 - inc.ratio;
    const percentColors: any = [
      {pct: 0.0, color: {r: 0xff, g: 0x00, b: 0}},
      {pct: 0.5, color: {r: 0xff, g: 0xff, b: 0}},
      {pct: 1.0, color: {r: 0x00, g: 0xff, b: 0}}];
    let i;
    for (i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    const lower = percentColors[i - 1];
    const upper = percentColors[i];
    const range = upper.pct - lower.pct;
    const rangePct = (pct - lower.pct) / range;
    const pctLower = 1 - rangePct;
    const pctUpper = rangePct;
    const color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
