import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Orders} from '../../../../shared/models/orders.model';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpUserService} from '../../../../shared/services/http/http-user.service';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {HttpOrdersService} from '../../../../shared/services/http/http-orders.service';
import {Voucher} from '../../../../shared/models/voucher.model';
import {MatDialog} from '@angular/material/dialog';
import {EditOrdersComponent} from '../../../components/admin/edit-orders/edit-orders.component';
import {EditVouchersComponent} from '../../../components/admin/edit-vouchers/edit-vouchers.component';
import {FilterOrdersComponent} from '../../../components/admin/filter-orders/filter-orders.component';
import {TextTransformService} from '../../../../shared/helpers/texttransform.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-adm-orders',
  templateUrl: './adm-orders.component.html',
  styleUrls: ['./adm-orders.component.scss']
})
export class AdmOrdersComponent implements OnInit, OnDestroy {
  currentUser: User;
  orders: Orders[] = [];
  ordersFiltered: Orders[] = [];
  vouchers: Voucher[] = [];
  loading: boolean;
  searchText: string;
  searching = false;
  showSearch = false;
  selectedFilter = 'lastX';
  lastXNumber = 7;
  selectedTimeUnit = 'days';
  // firstDate = '2021-12-11T00:00:00.000000+00:00';
  firstDate = '';
  secondDate = '';

  showOrders = true;
  showVouchers = false;

  filter: string;
  tour_id: number;

  net_tours: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_tours: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_conn: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_tdo: { EUR: number, GBP: number, USD: number, TOT: number };
  net_tdo: { EUR: number, GBP: number, USD: number, TOT: number };
  net_tip: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_tip: { EUR: number, GBP: number, USD: number, TOT: number };
  net_dons: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_dons: { EUR: number, GBP: number, USD: number, TOT: number };
  net_upgs: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_upgs: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_ref: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_str_fee: { EUR: number, GBP: number, USD: number, TOT: number };
  tot_pln_fee: { EUR: number, GBP: number, USD: number, TOT: number };
  earn_tdo: { EUR: number, GBP: number, USD: number, TOT: number };
  earn_tip: { EUR: number, GBP: number, USD: number, TOT: number };

  earn: { EUR: number, GBP: number, USD: number, TOT: number };
  net: { EUR: number, GBP: number, USD: number, TOT: number };
  gross: { EUR: number, GBP: number, USD: number, TOT: number };

  private onDestroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private http: HttpClient,
    private httpUser: HttpUserService,
    private httpOrdSvc: HttpOrdersService,
    private authSvc: AuthenticationService,
    private textTransform: TextTransformService,
    private snackSvc: SnackbarService,
  ) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.filter = null;
    this.tour_id = null;
  }

  ngOnInit(): void {
    this.getAllOrders();
    this.restoreValues();
  }

  restoreValues() {
    this.gross = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.earn = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.net = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.net_tours = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_tours = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_conn = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_tdo = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.net_tdo = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.net_tip = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_tip = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.net_dons = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_dons = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.net_upgs = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_upgs = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_str_fee = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_pln_fee = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.tot_ref = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.earn_tdo = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    this.earn_tip = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
  }

  roundToTwo(num) {
    return +(Math.round(Number(num + 'e+2')) + 'e-2');
  }

  getAllOrders() {
    this.loading = true;
    this.httpOrdSvc.getAllOrders(this.currentUser.id)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.orders = res.results.orders || [];
            this.vouchers = res.results.vouchers || [];
            this.vouchers.map(v => {
              if (v.price_pday === 0) {
                v.price_pday = '0';
              }
              if (v.tour_cost === 0) {
                v.tour_cost = '0';
              }
            });
            this.ordersFiltered = this.orders.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
            this.ordersFiltered.map(o => {
              if (o.voucher) {
                o.vouch = this.vouchers.find(v => '' + v.id === '' + o.voucher.split('#')[1]);
              }
            });
            this.filter = null;
            this.tour_id = null;
            this.getNet();
            const ord = JSON.parse(localStorage.getItem('edit_ord'));
            if (ord) {
              this.onClickOrder(ord);
            }
          } else {
            if (res.results) {
              this.snackSvc.openSnackBar(res.results[Object.keys(res.results)[0]].toString());
            } else {
              if (!res.message.error_description) {
                this.snackSvc.openSnackBar(res.message.message.toString());
              } else {
                this.snackSvc.openSnackBar(res.message.error_description.toString());
              }
            }
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  filterBy(role?) {
    if (role === 'complete') {
      this.ordersFiltered = this.orders.filter(h => h.status === 'Complete');
      this.filter = role;
    } else if (role === 'incomplete') {
      this.ordersFiltered = this.orders.filter(h => h.status === 'incomplete');
      this.filter = role;
    } else if (role === 'tour') {
      this.ordersFiltered = this.orders.filter(h => h.reference.substring(0, 1) === 'T');
      this.filter = role;
    } else if (role === 'td_opt') {
      this.ordersFiltered = this.orders.filter(h => h.reference.substring(0, 1) === 'O');
      this.filter = role;
    } else if (role === 'tip') {
      this.ordersFiltered = this.orders.filter(h => h.reference.substring(0, 1) === 'X');
      this.filter = role;
    } else if (role === 'don') {
      this.ordersFiltered = this.orders.filter(h => h.reference.substring(0, 1) === 'D');
      this.filter = role;
    } else if (role === 'upg') {
      this.ordersFiltered = this.orders.filter(h => h.reference.substring(0, 1) === 'E');
      this.filter = role;
    } else {
      this.filter = null;
      this.tour_id = null;
      this.ordersFiltered = this.orders;
    }
    this.getNet();
  }

  // Open dialog
  onOpenFilterDate() {
    const dialogRef = this.dialog.open(FilterOrdersComponent, {
      data: {
        'lastXNumber': this.lastXNumber,
        'selectedTimeUnit': this.selectedTimeUnit,
        'firstDate': this.firstDate,
        'secondDate': this.secondDate,
        'selectedFilter': this.selectedFilter
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['selectedFilter'] !== 'close') {
        this.filter = 'date';
        this.selectedFilter = result['selectedFilter'];
        this.firstDate = result['firstDate'];
        this.secondDate = result['secondDate'];
        const today = this.textTransform.addDays(new Date(), 0);
        const firstDate = this.textTransform.addDays(new Date(this.firstDate), 0);
        if (this.selectedFilter === 'lastX') {
          this.lastXNumber = result['lastXNumber'];
          this.selectedTimeUnit = result['selectedTimeUnit'];
          if (this.selectedTimeUnit === 'days') {
            this.ordersFiltered = this.orders.filter(o => o.created_at >= this.textTransform.addDays(new Date(today), -this.lastXNumber));
          } else if (this.selectedTimeUnit === 'months') {
            this.ordersFiltered = this.orders.filter(o => o.created_at >= this.textTransform.addDays(new Date(today), -(this.lastXNumber * 30)));
          } else {
            this.ordersFiltered = this.orders;
            this.snackSvc.openSnackBar('Error unknown time unit');
          }
        } else if (this.selectedFilter === 'afterDate') {
          this.ordersFiltered = this.orders.filter(o => this.textTransform.addDays(new Date(o.created_at), 0) >= firstDate);
        } else if (this.selectedFilter === 'betweenDate') {
          const secondDate = this.textTransform.addDays(new Date(this.secondDate), 0);
          this.ordersFiltered = this.orders.filter(o => (this.textTransform.addDays(new Date(o.created_at), 0) >= firstDate)
            && (this.textTransform.addDays(new Date(o.created_at), 0) <= secondDate));
        } else if (this.selectedFilter === 'beforeDate') {
          this.ordersFiltered = this.orders.filter(o => this.textTransform.addDays(new Date(o.created_at), 0) <= firstDate);
        } else {
          this.snackSvc.openSnackBar('Unknown filter');
          this.filter = null;
        }
      } else if (result && result['selectedFilter'] === 'close') {
        this.ordersFiltered = this.orders;
        this.filter = null;
      } else {
        // this.filter = null;
      }
      this.getNet();
    });
  }

  getNet() {
    this.restoreValues();
    const tmp = {EUR: 0, GBP: 0, USD: 0, TOT: 0};
    for (const ord of this.ordersFiltered) {
      if (!ord.deleted_at) {
        if (ord.status === 'Complete') {
          if (ord.reference.substring(0, 1) === 'T') {
            this.net_tours[ord.currency_code] += (ord.amount - ord.str_fee) / 100;
            this.tot_tours[ord.currency_code] += ord.amount / 100;
            this.net_tours['TOT'] += this.net_tours[ord.currency_code];
            this.tot_tours['TOT'] += this.tot_tours[ord.currency_code];
          } else if (ord.reference.substring(0, 1) === 'O') {
            this.earn_tdo[ord.currency_code] += ord.pln_fee / 100;
            this.net_tdo[ord.currency_code] += (ord.amount - ord.pln_fee - ord.str_fee) / 100;
            this.tot_tdo[ord.currency_code] += ord.amount / 100;
            this.earn_tdo['TOT'] += this.earn_tdo[ord.currency_code];
            this.net_tdo['TOT'] += this.net_tdo[ord.currency_code];
            this.tot_tdo['TOT'] += this.tot_tdo[ord.currency_code];
          } else if (ord.reference.substring(0, 1) === 'X') {
            this.earn_tip[ord.currency_code] += ord.pln_fee / 100;
            this.net_tip[ord.currency_code] += (ord.amount - ord.pln_fee - ord.str_fee) / 100;
            this.tot_tip[ord.currency_code] += ord.amount / 100;
            this.earn_tip['TOT'] += this.earn_tip[ord.currency_code];
            this.net_tip['TOT'] += this.net_tip[ord.currency_code];
            this.tot_tip['TOT'] += this.tot_tip[ord.currency_code];
          } else if (ord.reference.substring(0, 1) === 'D') {
            this.net_dons[ord.currency_code] += (ord.amount - ord.str_fee) / 100;
            this.tot_dons[ord.currency_code] += ord.amount / 100;
            this.net_dons['TOT'] += this.net_dons[ord.currency_code];
            this.tot_dons['TOT'] += this.tot_dons[ord.currency_code];
          } else if (ord.reference.substring(0, 1) === 'E') {
            this.net_upgs[ord.currency_code] += (ord.amount - ord.str_fee) / 100;
            this.tot_upgs[ord.currency_code] += ord.amount / 100;
            this.net_upgs['TOT'] += this.net_upgs[ord.currency_code];
            this.tot_upgs['TOT'] += this.tot_upgs[ord.currency_code];
          } else {
            tmp[ord.currency_code] += ord.pln_fee / 100;
            tmp['TOT'] += tmp[ord.currency_code];
          }
          this.tot_pln_fee[ord.currency_code] += ord.pln_fee / 100;
          this.tot_str_fee[ord.currency_code] += ord.str_fee / 100;
          this.tot_pln_fee['TOT'] += this.tot_pln_fee[ord.currency_code];
          this.tot_str_fee['TOT'] += this.tot_str_fee[ord.currency_code];
          const jan1st = '2023-01-01T00:00:00.000000Z';
          if ((ord.reference.substring(0, 1) === 'O' || ord.reference.substring(0, 1) === 'X') && Date.parse(ord.created_at) > Date.parse(jan1st)) {
            this.tot_conn[ord.currency_code] += (ord.amount - ord.pln_fee - ord.str_fee) / 100;
            this.tot_conn['TOT'] += this.tot_conn[ord.currency_code];
          }
        }
        // else if (ord.status === 'Refunded') {
        //   this.tot_pln_fee -= ord.pln_fee / 100;
        //   this.tot_str_fee -= ord.str_fee / 100;
        //   this.tot_ref += ord.amount / 100;
        // if (ord.reference.substring(0, 1) === 'T') {
        // } else if (ord.reference.substring(0, 1) === 'O') {
        // } else if (ord.reference.substring(0, 1) === 'X') {
        // } else if (ord.reference.substring(0, 1) === 'D') {
        // }
      }
    }
    for (const curr of ['EUR', 'USD', 'GBP', 'TOT']) {
      this.net_tours[curr] = this.roundToTwo(this.net_tours[curr]);
      this.tot_tours[curr] = this.roundToTwo(this.tot_tours[curr]);
      this.tot_conn[curr] = this.roundToTwo(this.tot_conn[curr]);
      this.tot_tdo[curr] = this.roundToTwo(this.tot_tdo[curr]);
      this.net_tdo[curr] = this.roundToTwo(this.net_tdo[curr]);
      this.earn_tdo[curr] = this.roundToTwo(this.earn_tdo[curr]);
      this.net_tip[curr] = this.roundToTwo(this.net_tip[curr]);
      this.tot_tip[curr] = this.roundToTwo(this.tot_tip[curr]);
      this.earn_tip[curr] = this.roundToTwo(this.earn_tip[curr]);
      this.net_dons[curr] = this.roundToTwo(this.net_dons[curr]);
      this.tot_dons[curr] = this.roundToTwo(this.tot_dons[curr]);
      this.net_upgs[curr] = this.roundToTwo(this.net_upgs[curr]);
      this.tot_upgs[curr] = this.roundToTwo(this.tot_upgs[curr]);
      this.tot_str_fee[curr] = this.roundToTwo(this.tot_str_fee[curr]);
      this.tot_pln_fee[curr] = this.roundToTwo(this.tot_pln_fee[curr]);
      this.tot_ref[curr] = this.roundToTwo(this.tot_ref[curr]);
      this.earn[curr] = this.roundToTwo(this.net_tours[curr] + this.earn_tdo[curr]
        + this.earn_tip[curr] + this.net_dons[curr] + this.net_upgs[curr]);
      this.net[curr] = this.roundToTwo(this.net_tours[curr] + this.net_tdo[curr]
        + this.net_tip[curr] + this.net_dons[curr] + this.net_upgs[curr] + tmp[curr]);
      this.gross[curr] = this.roundToTwo(this.tot_tours[curr] + this.tot_tdo[curr]
        + this.tot_tip[curr] + this.tot_dons[curr] + this.tot_upgs[curr]);
    }
  }

  onNewOrd() {
    const ord = {
      'id': 0,
      'user_id': null,
      'tour_id': null,
      'reference': null,
      't_name': '',
      't_idcode': null,
      't_logo': null,
      't_plan': null,
      'amount': 0,
      'currency_code': 'EUR',
      'voucher': null,
      'str_fee': 0,
      'pln_fee': 0,
      'status': 'Complete',
      'notes': null,
      'created_at': null,
      'deleted_at': null,
      'paypal_id': null,
      'stripe_id': null,
    };
    this.ordersFiltered.unshift(ord);
    this.onClickOrder(ord);
  }

  onClickOrder(order) {
    this.onEditOrder(order);
  }

  onEditOrder(order: Orders) {
    const dialogRef = this.dialog.open(EditOrdersComponent, {
      maxWidth: '90vw',
      // height: '768px',
      width: '1024px',
      data: {'orders': order}
    });
    dialogRef.componentInstance.newOrder.subscribe(ord => {
      // Remove in case empty order
      const i2 = this.ordersFiltered.findIndex(o => o.id === 0);
      if (i2 >= 0) {
        this.ordersFiltered.splice(i2, 1);
      }
      this.ordersFiltered.unshift(ord);
      this.getNet();
    });
    dialogRef.componentInstance.deleteOrder.subscribe(ord_id => {
      const idx = this.ordersFiltered.findIndex(o => '' + o.id === '' + ord_id);
      this.ordersFiltered.splice(idx, 1);
      this.getNet();
    });
    dialogRef.componentInstance.updateOrder.subscribe(ord => {
      const idx = this.ordersFiltered.findIndex(o => '' + o.id === '' + ord.id);
      this.ordersFiltered[idx] = ord;
      this.getNet();
    });
    dialogRef.componentInstance.deleteVoucher.subscribe(v_id => {
      const idx = this.vouchers.findIndex(v => '' + v.id === '' + v_id);
      this.vouchers.splice(idx, 1);
      const idx2 = this.ordersFiltered.findIndex(o => '' + o.vouch.id === '' + v_id);
      this.ordersFiltered[idx2].voucher = null;
    });
    dialogRef.componentInstance.updateVoucher.subscribe(v => {
      const idx = this.vouchers.findIndex(o => '' + o.id === '' + v.id);
      this.vouchers[idx] = v;
      const idx2 = this.ordersFiltered.findIndex(o => '' + o.vouch.id === '' + v.id);
      this.ordersFiltered[idx2].voucher = v;
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        // this.snackSvc.openSnackBar('Order updated');
      } else {
        // console.log('Didn\'t update order');
      }
      // Remove in case empty order
      const i2 = this.ordersFiltered.findIndex(o => o.id === 0);
      if (i2 >= 0) {
        this.ordersFiltered.splice(i2, 1);
      }
      localStorage.removeItem('edit_ord');
    });
  }

  onEditVoucher(voucher: Voucher) {
    const dialogRef = this.dialog.open(EditVouchersComponent, {
      maxWidth: '90vw',
      // height: '768px',
      width: '1024px',
      data: {'voucher': voucher}
    });
    dialogRef.componentInstance.deleteVoucher.subscribe(v_id => {
      const idx = this.vouchers.findIndex(o => '' + o.id === '' + v_id);
      this.vouchers.splice(idx, 1);
    });
    dialogRef.componentInstance.updateVoucher.subscribe(v => {
      const idx = this.vouchers.findIndex(o => '' + o.id === '' + v.id);
      this.vouchers[idx] = v;
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result && result['reason'] !== 'close') {
        // this.snackSvc.openSnackBar('Voucher updated');
      } else {
        // console.log('Didn\'t update voucher');
      }
    });
  }

  // SEARCH BARS
  searchRes(event: any): void {
    this.searching = event.target.value.length >= 1;
    this.getNet();
  }

  keyEscape() {
    if (this.searchText) {
      this.searchText = null;
    } else {
      this.showSearch = false;
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
