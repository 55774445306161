import {City} from './city.model';
import {User} from './user.model';
import {Components} from './components.model';
import {TourOptional} from './tour-optional.model';

export class Optional {
  id: number;
  user_id: number;
  city_id: number;
  city_name?: string;
  company_id: number;
  companies: string;
  name: string;
  general_info: string;
  image: string;
  location: string;
  map: string;
  selling_line: string;
  min_pax: string;
  price: string;
  cost: string;
  driver_share: string;
  duration: string;
  supplier: string;
  contact: string;
  email: string;
  phone: string;
  website: string;
  safety_category: string;
  safety_details: string;
  updated_at: string;
  edited_by: string;
  access: string;
  city?: City;
  user?: User;
  component?: Components;
  tour_optional?: TourOptional[];
  show_safety?: boolean;
  show_contact?: boolean;
  show_info?: boolean;
  show_price?: boolean;
  edit_fields?: string;
  show_tops?: boolean;
  cia?: string;
  company_ids?: number[];

  constructor() {
  }
}
